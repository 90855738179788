import axios from "axios";
import { useEffect } from "react";
import { store } from "../../../../store/app-store";
import { getCurrentSession } from "../../../auth";
import { axiosClient } from "../../axios-client";
import { setError } from "../store/error.slice";

/* component props */
type AxiosWrapperProps = {
  children: React.ReactNode;
};
export const AxiosWrapper = (props: AxiosWrapperProps): JSX.Element => {
  /* useEffect hooks */
  useEffect(() => {
    if (store) {
      axiosClient.interceptors.request.use(
        function (config) {
          const session: any = getCurrentSession() || null;
          config.params.access_token = session?.accessToken;
          return config;
        },
        async function (error) {
          return await Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        async function (error) {
  
          store.dispatch(
            setError({
              message: error.response.data.message,
              code: error.message,
              response: error.response,
            })
          );
          return await Promise.reject(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [{ store }]);

  return <>{props?.children}</>;
};
