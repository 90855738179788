import { Box, Button, ButtonProps } from "@mui/material";
import { PropsWithChildren } from "react";

type ButtonToolbarActionProps = Pick<ButtonProps, "variant" | "onClick" | "startIcon">;

export const ButtonToolbarAction = (
  props: PropsWithChildren<ButtonToolbarActionProps>,
): JSX.Element => {
  return (
    <Box>
      <Button
        size="large"
        sx={{
          height: "100%",
          minHeight: 56,
          display: "flex",
        }}
        onClick={props.onClick}
        variant={props.variant}
        startIcon={props.startIcon}
      >
        {props.children}
      </Button>
    </Box>
  );
};
