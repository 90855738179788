import React from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  MenuItem,
  FormHelperText,
  Grid,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DefaultDialog } from "../core/dialogs";
import { T } from "../localisations/T";
import * as yup from "yup";
import { useFormik } from "formik";

import { FileInput } from "../core/ui/file-input/utils/file-input";
import { useCreateUsersMutation, useGetRoleQuery } from "../distribution-center/api/DistributionCentersQuery";

interface AddUserProps {
  open: boolean;
  setModalOpen: (x: boolean) => void;
  user?: User;
  centerId: string;
}

interface Role {
  id: string;
  name: string;
}

interface User {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: boolean;
  photo: string;
  sex: string;
  userRole:{
    id: string;
    name: string;
  }
  padProviderId: string;
  userType: "CENTRAL_ADMIN" | "DISTRIBUTION" | "PROVIDER";
}

type UserFormValues = Omit<User, "id" | "userRole"> & { role: string };

const AddEditUser: React.FC<AddUserProps> = ({
  open,
  setModalOpen,
  user,
  centerId,
}) => {
  const { data, error, isLoading } = useGetRoleQuery();
  const [createUser, { isLoading: isCreating }] = useCreateUsersMutation();

  // Validation schema
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: yup
      .string()
      .matches(/^\d+$/, "Phone number must be numeric")
      .required("Phone number is required"),
    role: yup.string().required("Role is required"),
    padProviderId: yup
      .string()
      .required("Distribution Center is required"),
    userType: yup.string().required("User type is required"),
    status: yup.boolean().required("Active status is required"),
    sex: yup
      .string()
      .oneOf(["Male", "Female"], "Select a valid sex")
      .required("Sex is required"),
  });

  // Initial values
  const initialValues: UserFormValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    photo: user?.photo || "",
    sex: user?.sex || "Female",
    role: user?.userRole.name || "",
    padProviderId: centerId,
    userType: user?.userType || "PROVIDER",
    status: user?.status || true,
  };

  // Formik setup
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: UserFormValues) => {
      if (user) {
        console.log("Editing user:", values);
      } else {
        createUser({ body: values });
        console.log("Creating user:", values);
      }
      setModalOpen(false);
    },
  });

  return (
    <Box>
      <DefaultDialog
        title={user ? T("edit-user") : T("add-user")}
        open={open}
        minWidth={"50%"}
        onClose={() => setModalOpen(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box flexDirection={"column"} display={"flex"} gap={2}>
            <TextField
              fullWidth
              label="First Name"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField
              fullWidth
              label="Last Name"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <TextField
              fullWidth
              select
              label="Sex"
              id="sex"
              name="sex"
              value={formik.values.sex}
              onChange={formik.handleChange}
              error={formik.touched.sex && Boolean(formik.errors.sex)}
              helperText={formik.touched.sex && formik.errors.sex}
            >
              {["Male", "Female"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}{" "}
                  {/* Capitalize */}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              label="Email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              label="Phone Number"
              id="phoneNumber"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
            <Autocomplete
              id="role"
              options={data?.data || []}
              getOptionLabel={(option: Role) => option.name}
              value={
                data?.data?.find(
                  (role: any) => role.id === formik.values.role
                ) || null
              }
              onChange={(event, value) =>
                formik.setFieldValue("role", value?.id || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                />
              )}
            />

            <FormControlLabel
              control={
                <Checkbox
                  id="isActive"
                  name="isActive"
                  checked={formik.values.status}
                  onChange={formik.handleChange}
                />
              }
              label="Active Status"
            />
            <Box>
              <>
                <Grid item xs={2} sx={{ py: 1, pb: -1, width: "100%" }}>
                  <FileInput
                    message={"PNG, JPG or PDF (max. 3MB)"}
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                      "application/pdf": [".pdf"],
                    }}
                    maxFiles={1}
                    onChange={(val) => {
                      formik.setFieldValue("photo", val[0]);
                    }}
                    maxSize={{ measurement: "MB", size: 3 }}
                  />
                  <FormHelperText
                    error={
                      (formik.touched.photo && Boolean(formik.errors.photo)) ||
                      (!formik.values.photo && formik.submitCount > 0)
                    }
                  >
                    {formik.touched.photo && formik.errors.photo
                      ? formik.errors.photo
                      : !formik.values.photo && formik.submitCount > 0
                      ? T("add-image")
                      : null}
                  </FormHelperText>
                </Grid>
              </>
            </Box>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
              disabled={isCreating}
            >
              {T(user ? "update" : "submit")}
            </Button>
          </Box>
        </form>
      </DefaultDialog>
    </Box>
  );
};

export default AddEditUser;
