import React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { DefaultDialog } from "../core/dialogs";
import { T } from "../localisations/T";
import * as yup from "yup";
import { useFormik } from "formik";

interface ReceivingHistory {
  batch: string;
  receivedDate: string; // ISO string or date type, adjust as needed
  quantity: number;
}

interface AddReceivingHistoryProps {
  open: boolean;
  setModalOpen: (x: boolean) => void;
  receivingHistory?: ReceivingHistory; // Optional, for editing case
}

const AddEditReceivingHistory: React.FC<AddReceivingHistoryProps> = ({
  open,
  setModalOpen,
  receivingHistory,
}) => {
  // Validation schema for the form
  const validationSchema = yup.object().shape({
    batch: yup.string().required("Batch is required"),
    receivedDate: yup.date().required("Received date is required"),
    quantity: yup
      .number()
      .required("Quantity is required")
      .positive("Quantity must be positive")
      .integer("Quantity must be an integer"),
  });

  // Initial form values
  const initialValues: ReceivingHistory = {
    batch: receivingHistory?.batch || "",
    receivedDate: receivingHistory?.receivedDate || "",
    quantity: receivingHistory?.quantity || 0,
  };

  // Formik setup
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: ReceivingHistory) => {
      if (receivingHistory) {
        // Handle edit logic
        console.log("Editing receiving history:", values);
      } else {
        // Handle create logic
        console.log("Creating receiving history:", values);
      }
    },
  });

  return (
    <Box>
      <DefaultDialog
        title={receivingHistory ? T("edit-receiving-history") : T("add-receiving-history")}
        open={open}
        minWidth={"50%"}
        onClose={() => setModalOpen(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box flexDirection={"column"} display={"flex"} gap={2}>
            <Box>
              <Typography variant="subtitle1">Batch</Typography>
              <TextField
                fullWidth
                id="batch"
                name="batch"
                label="Batch"
                value={formik.values.batch}
                onChange={formik.handleChange}
                error={formik.touched.batch && Boolean(formik.errors.batch)}
                helperText={formik.touched.batch && formik.errors.batch}
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Received Date</Typography>
              <TextField
                fullWidth
                id="receivedDate"
                name="receivedDate"
                label="Received Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formik.values.receivedDate}
                onChange={formik.handleChange}
                error={formik.touched.receivedDate && Boolean(formik.errors.receivedDate)}
                helperText={formik.touched.receivedDate && formik.errors.receivedDate}
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Quantity</Typography>
              <TextField
                fullWidth
                id="quantity"
                name="quantity"
                label="Quantity"
                type="number"
                value={formik.values.quantity}
                onChange={formik.handleChange}
                error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                helperText={formik.touched.quantity && formik.errors.quantity}
              />
            </Box>

            <Box padding={2}>
              <Button fullWidth color="primary" variant="contained" type="submit">
                {T(receivingHistory ? "update" : "submit")}
              </Button>
            </Box>
          </Box>
        </form>
      </DefaultDialog>
    </Box>
  );
};

export default AddEditReceivingHistory;
