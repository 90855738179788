import { appApi } from "../../../store/app.api";
import { appEndpoint } from "../../../store/app.endpoint";
import { CommandRequest } from "../../core/utilities/command-request";


// Define the API endpoints
const ReceiverApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    // Fetch all distribution centers
    getAllReceiver: build.query<any, void>({
      query: () => ({
        url: appEndpoint.getAllReceiver,
        method: "get",
      }),
      providesTags: ["Receiver"], // Tag for cache management
    }),

    getReceiverById: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint. getReceiverById}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Receiver"], // Tag for cache management
    }),
    getPadsByReceiverId: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint. getPadByReceivers}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Pad"], // Tag for cache management
    }),
  }),

  overrideExisting: false, // Ensures this endpoint doesn't override others
});

// Export hooks for querying
export const { 
  useGetAllReceiverQuery,
  useGetReceiverByIdQuery,
  useGetPadsByReceiverIdQuery
} = ReceiverApi;
