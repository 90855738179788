import { appApi } from "../../../store/app.api";
import { appEndpoint } from "../../../store/app.endpoint";
import { CommandRequest } from "../../core/utilities/command-request";


// Define the API endpoints
const DonorApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    // Fetch all distribution centers
    getAllDonor: build.query<any, void>({
      query: () => ({
        url: appEndpoint.getAllDonor,
        method: "get",
      }),
      providesTags: ["Donor"], // Tag for cache management
    }),
    getDonorDetailById: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getDonorDetailById}/${id}`,
        method: "get",
      }),
      providesTags: ["Donor"], // Tag for cache management
    }),

  }),

  overrideExisting: false, // Ensures this endpoint doesn't override others
});

// Export hooks for querying
export const {
  useGetAllDonorQuery,
  useGetDonorDetailByIdQuery,
} = DonorApi;
