import { LoadingButton } from "@mui/lab";
import { DialogContentText, FormHelperText, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { ReactNode, useCallback, useMemo } from "react";
import * as yup from "yup";
import { DefaultDialog, DefaultDialogProps } from ".";
import { T } from "../../localisations/T";
import { YupSchema } from "../utilities/validation/yup-schema";
import { FileInput } from "../ui/file-input/utils/file-input";


export interface ActionDialogFormShape {
  Note: string;
  Attachments: string[];
}

export type ActionDialogProps = DefaultDialogProps & {
  message: string;
  attachmentRequired?: boolean;
  onExecuteAction: (data: ActionDialogFormShape) => void;
  isLoading?: boolean;
  onClose: () => void;
  Note?: string;
  closeOnSubmit?: boolean;
  validator?: (file: any) => { code: string; message: string } | null;
  maxSize?: { size: number; measurement: "KB" | "MB" };
  maxFiles?: number;
  otherContent?: ReactNode;
  hideAttachment?: boolean;
  noteLabel?: string;
  removeNote?: boolean;
};

export const ActionDialog = (props: ActionDialogProps): JSX.Element => {
  const mustEnterNote = T("must-enter-note");
  const mustAttachFile = T("must-attach-file");
  const validationSchema = useMemo(() => {
    return yup.object({
     
      Attachments: props.attachmentRequired
        ? yup.array().of(yup.string()).min(1, mustAttachFile)
        : yup.array().of(yup.string()),
    });
  }, [props.attachmentRequired]);
  const formState = useFormik<ActionDialogFormShape>({
    initialValues: {
      Note: props.Note ? props.Note : "",
      Attachments: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      props.onExecuteAction(values);
      if (props.closeOnSubmit === false) {
        return;
      }
      props.onClose();
    },
  });

  const handleFileInputChange = useCallback(
    (fileIds: string[]) => {
      void formState.setFieldValue("Attachments", [...fileIds]);
    },
    [formState],
  );
  const renderActions = (): JSX.Element => {
    return (
      <>
        <LoadingButton
          color="error"
          fullWidth
          variant="outlined"
          form="approve-form"
          loading={formState.isSubmitting || props.isLoading}
          onClick={() => {
            props.onClose({}, "escapeKeyDown");
            formState.resetForm();
          }}
        >
          {T("cancel")}
        </LoadingButton>
        <LoadingButton
          color="success"
          fullWidth
          variant="contained"
          type="submit"
          form="approve-form"
          loading={formState.isSubmitting || props.isLoading}
        >
          {T("submit")}
        </LoadingButton>
      </>
    );
  };

  return (
    <DefaultDialog hideClose {...props} actions={renderActions()}>
      <form onSubmit={formState.handleSubmit} id="approve-form">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <DialogContentText>{props.message}</DialogContentText>
          </Grid>
          
          {/* <Grid item>
            <TextField
              fullWidth
              size="medium"
              id="Note"
              name="Note"
              label={props.noteLabel ? props.noteLabel : T("note")}
              multiline
              rows={4}
              value={formState.values.Note}
              onChange={formState.handleChange}
              error={formState.touched.Note && Boolean(formState.errors.Note)}
              helperText={formState.touched.Note && formState.errors.Note}
              autoFocus
            />
          </Grid> */}
          {!props.hideAttachment && (
            <>
              <Grid item>
                <FileInput
                  message={T("file-type")}
                  accept={{
                    "image/*": [".png", ".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  onChange={handleFileInputChange}
                  validator={props.validator}
                  maxFiles={props.maxFiles}
                />
                <FormHelperText
                  error={formState.touched.Attachments && Boolean(formState.errors.Attachments)}
                >
                  {formState.touched.Attachments && formState.errors.Attachments}
                </FormHelperText>
              </Grid>
            </>
          )}
          {props.otherContent && <Grid item>{props.otherContent}</Grid>}
        </Grid>
      </form>
    </DefaultDialog>
  );
};

ActionDialog.defaultProps = {
  attachmentRequired: true,
};
