import { SettingsSuggestOutlined } from "@mui/icons-material";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

interface SettingListItemProps {
  name: string;
  active: boolean;
  handleClick: () => void;
}

function SettingListItem({ name, active, handleClick }: SettingListItemProps): JSX.Element {
  return (
    <ListItem
      disablePadding
      onClick={handleClick}
      sx={active ? { backgroundColor: "primary.main", color: "white" } : {}}
    >
      <ListItemButton>
        <ListItemIcon>
          <SettingsSuggestOutlined sx={active ? { color: "white" } : {}} />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
}

export default SettingListItem;
