import React, { useState } from "react";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { Box, Chip, Typography } from "@mui/material";
import { DataTable } from "../core/components/data-table";
import { T } from "../localisations/T";
import { useGetAllPaymentsQuery } from "./api/paymentApi";


export interface Payment {
    orderId: string;
    order:any;
    paymentInfo: string;
    amount: string;
    quantity: number;
    donor:any
    paymentStatus: string;
    paymentMethod: string;
}

const AllPaymentTable: React.FC= () => {
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    // Fetch data using a query (replace with your actual API hook)
    const { data, error, isLoading } = useGetAllPaymentsQuery();

    // Define table columns
    const columns: Array<MRT_ColumnDef<Payment>> = [
        {
            header: T("Order ID"),
            accessorKey: "orderId",
            size: 100,
        },
        {
            header: T("donar"),
            accessorKey: "donor.firstName",
            size: 150,
        },
      
        {
            header: T("Amount"),
            accessorKey: "amount",
            size: 100,
            Cell: ({ row: { original } }) => (
                <Typography>${parseFloat(original.amount).toFixed(2)}</Typography>
            ),
        },
        {
            header: T("Quantity"),
            accessorKey: "order.quantity",
            size: 100,
            // Cell: ({ row: { original } }) => (
            //     <Typography>{original.quantity}</Typography>
            // ),
        },
        {
            header: T("paymentMethod"),
            accessorKey: "paymentMethod",
            size: 150,
        },
        {
            header: T("status"),
            accessorKey: "paymentStatus",
            size: 100,
            
        },
    ];

    return (
        <Box>
            <DataTable<Payment>
                toolbar={
                    <Box display="flex" alignItems="center" width="100%">
                        <Box display="flex" gap={3} alignItems="center" flex="1">
                            <Typography>{T("Payments")}</Typography>
                            <Chip
                                color="primary"
                                variant="outlined"
                                label={`${data?.meta.totalCount??""} ${T("Payments")}`}
                            />
                        </Box>
                    </Box>
                }
                list={data?.data ?? []} // Replace with actual data
                count={data?.meta.totalCount ?? 0}
                columns={columns}
                getRowId={(row) => row.orderId}
                isLoading={isLoading}
                isSuccess={!!data}
                isError={!!error}
                isFetching={false}
                pagination={pagination}
                onPaginationChange={setPagination}
            />
        </Box>
    );
};

export default AllPaymentTable;
