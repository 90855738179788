import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";

interface SettingsTitleProps {
  onClick: () => void;
  title: string;
  actionTitle: string;
  children: React.ReactNode;
  prm: string;
  otherActions?: React.ReactNode;
}

const SettingsTitle = (props: SettingsTitleProps): JSX.Element => {
  return (
    <Box>
      <Box
        display={"flex"}
        px={5}
        pt={3}
        pb={2}
        justifyItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h4">{props.title}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {props.prm === "" ? (
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={props.onClick}
              disableElevation
              size="medium"
            >
              {props.actionTitle}
            </Button>
          ) : (
            
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={props.onClick}
                disableElevation
                size="medium"
              >
                {props.actionTitle}
              </Button>
            
          )}
          <Box sx={{ marginRight: props.otherActions ? "-1rem" : 0 }}>{props.otherActions}</Box>
        </Box>
      </Box>
      <Divider />
      <Box p={3}>{props.children}</Box>
    </Box>
  );
};

export default SettingsTitle;
