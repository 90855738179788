import { appApi } from "../../../store/app.api";
import { appEndpoint } from "../../../store/app.endpoint";
import { CommandRequest } from "../../core/utilities/command-request";
import { DistributionCenter } from "../distribution-center-list";

// Define the API endpoints
const DistributionCentersApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    // Fetch all distribution centers
    getAllDistributionCentersApi: build.query<any, void>({
      query: () => ({
        url: appEndpoint.getDistributionCenters,
        method: "get",
      }),
      providesTags: ["DistributionCenters"], // Tag for cache management
    }),

    // Fetch a specific distribution center by ID
    getDistributionCenterById: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getDistributionCenterById}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["DistributionCenters"], // Tag for cache management
    }),

    getReceiverByDistributionCenter: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getReceiverByDistributionCenters}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Receiver"], // Tag for cache management
    }),
    getRole: build.query<any, void>({
      query: () => ({
        url: `${appEndpoint.roles}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["DistributionCenters"], // Tag for cache management
    }),
    getUsers: build.query<any, void>({
      query: () => ({
        url: `${appEndpoint.getUser}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["DistributionCenters"], // Tag for cache management
    }),
    getUsersByDistributionCenterId: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getUsersByDistributionCenterId}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["user"], // Tag for cache management
    }),
    // Create a new distribution center
    createDistributionCenters: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.getDistributionCenters,
        method: "post",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["DistributionCenters"] : []),
    }),

    createUsers: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.addUsers,
        method: "post",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["user"] : []),
    }),

    removeCenter: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.removeCenter, // Append the ID to the URL
        method: "delete", // Delete
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["DistributionCenters"] : []),
    }),

    updateCenter: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.updateCenter,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["DistributionCenters"] : []),
    }),
    deactivateCenter: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.deactivateCenter,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["DistributionCenters"] : []),
    }),
    removeUser: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.removeUser, // Append the ID to the URL
        method: "delete", // Delete
      }),
      invalidatesTags: (result) => (result ? ["user"] : []),
    }),

    updateUser: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.updateUser,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["user"] : []),
    }),
    removeReceiver: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.removeCenter, // Append the ID to the URL
        method: "delete", // Delete
      }),
      invalidatesTags: (result) => (result ? ["Receiver"] : []),
    }),

    updateReceiver: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.updateReceiver,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Receiver"] : []),
    }),

    getAllBatches: build.query<any, void>({
      query: () => ({
        url: `${appEndpoint.getAllBatches}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Batches"], // Tag for cache management
    }),
    getBatchDetail: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getAdminBatchDetail}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["user"], // Tag for cache management
    }),
    createReceiver: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.addReceiver,
        method: "post",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Receiver"] : []),
      
    }),

    getCentersById: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getCentersByProviderId}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["user"], // Tag for cache management
    }),


  }),

  overrideExisting: false, // Ensures this endpoint doesn't override others
});

// Export hooks for querying
export const {
  useGetAllDistributionCentersApiQuery,
  useLazyGetDistributionCenterByIdQuery,
  useGetDistributionCenterByIdQuery,
  useGetBatchDetailQuery,
  useCreateDistributionCentersMutation,
  useGetRoleQuery,
  useCreateUsersMutation,
  useCreateReceiverMutation,
  useGetUsersByDistributionCenterIdQuery,
  useGetReceiverByDistributionCenterQuery,
  useRemoveCenterMutation,
  useUpdateCenterMutation,
  useRemoveUserMutation,
  useUpdateUserMutation,
  useRemoveReceiverMutation,
  useUpdateReceiverMutation,
  useDeactivateCenterMutation,
useGetAllBatchesQuery,
useGetCentersByIdQuery
} = DistributionCentersApi;
