import { appApi } from "../../../../store/app.api";
import { appEndpoint } from "../../../../store/app.endpoint";
import { CommandRequest } from "../../utilities/command-request";

// Define the API endpoints
const DistributionCentersApi = appApi.injectEndpoints({
  endpoints: (build) => (
    
    {
    

    getCenterSummery: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.centerSummery}/${id}`, 
        method: "get",
      }),
      providesTags: ["PadProviders"], // Tag for cache management
    }),
 
    getBatchByCenter: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.batchesByCenter}/${id}`, 
        
        method: "get",
      }),
      providesTags: ["Batches"], // Tag for cache management
    }),
    getCenterBatchDetail: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.batchDetailByCenter}/${id}`, 
        
        method: "get",
      }),
      providesTags: ["Batches"], // Tag for cache management
    }),
  
    getBatchToBeRecivedByCenter: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.shipedbatchesByCenter}/${id}`, 
        
        method: "get",
      }),
      providesTags: ["Batches"], // Tag for cache management
    }),

    deactivatePadProviders: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.deactivateProvider,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["PadProviders"] : []),
    }),
    givePadProviders: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.givePad,
        method: "post",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["PadProviders","Receiver"] : []),
    }),
    receiveBatches: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.receiveBatches,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Batches"] : []),
    }),

    
  }),

  overrideExisting: false, // Ensures this endpoint doesn't override others
});

// Export hooks for querying
export const {
 useGetCenterSummeryQuery,
 useGetBatchByCenterQuery,
 useGivePadProvidersMutation,
 useReceiveBatchesMutation,
 useGetCenterBatchDetailQuery,
 useGetBatchToBeRecivedByCenterQuery

} = DistributionCentersApi;
