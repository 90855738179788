import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import { DefaultDialog } from "../core/dialogs";
import StatusButton from "../core/ui/status/status";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddEditReceiver from "./add-reciver";
import {
  useGetReceiverByDistributionCenterQuery,
  useRemoveReceiverMutation,
} from "./api/DistributionCentersQuery";
import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";

export interface Receiver {
  id: string;
  firstName: string;
  lastName: string;
  region: string;
  zone: string;
  city: string;
  subCity: string;
  woreda: string;
  sex: string;
  dateOfBirth: string | null;
  phoneNumber: string;
  // email: string;
  schoolName: string;
  grade: string;
  supportDocument: File | null;
  identificationCard: File | null;
  age: number;
  status?: boolean;
  photo:File|null ;
  distributionCenterId: string;
}
interface ReceiverListProps {
  id: string; 
  disabled?: boolean;// Optional id prop for filtering or targeting a specific receiver
}

const ReceiverList: React.FC<ReceiverListProps> = ({ id , disabled}) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, error, isLoading } = useGetReceiverByDistributionCenterQuery({
    id: id ?? "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [receiver, setReceiver] = useState<Receiver | null>(null);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [removeReceiver, { isLoading: isLoading1 }] =
    useRemoveReceiverMutation();

  useEffect(() => {}, []);

  const columns: Array<MRT_ColumnDef<Receiver>> = [
    {
      header: T("name"),
      accessorKey: "firstName",
      Cell: ({ row: { original } }) => (
        <Typography>
          {original.firstName} {original.lastName},
        </Typography>
      ),
      size: 50,
    },
    {
      header: T("phone"),
      accessorKey: "phoneNumber",
      size: 50,
    },
    // {
    //   header: T("email"),
    //   accessorKey: "email",
    //   size: 50,
    // },
    {
      header: T("location"),
      accessorKey: "city",
      size: 50,
      Cell: ({ row: { original } }) => (
        <Typography>
          {original.city}, {original.region}, Woreda {original.woreda},
        </Typography>
      ),
    },
    {
      header: T("school"),
      accessorKey: "schoolName",
      size: 50,
    },
    {
      header: T("status"),
      accessorKey: "status",
      size: 50,
      Cell({ row: { original } }): JSX.Element {
        return (
          <StatusButton
            status={original.status ? "active" : "inactive"}
            text={original.status ? "Active" : "Inactive"}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <DataTable<Receiver>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("receivers")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`${data?.data.padReceivers.length ?? 0} ${T(
                  "receivers"
                )}`}
              />
            </Box>
            <Button
              onClick={() => setOpenDialog(true)}
              sx={{ minWidth: "200px" }}
              variant="contained"
            >
              {T("add-new-receiver")}
            </Button>
          </Box>
        }
        list={data?.data.padReceivers ?? []}
        count={data?.data.padReceivers.length ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        renderRowActions={(row) => (
          <Box display={"flex"} gap={1}>
            <IconButton>
              <Delete
                onClick={() => {
                  setReceiver(row);
                  setOpenDialog1(true);
                }}
              />
            </IconButton>
            <IconButton disabled={!row.status}
              onClick={() => {
                setReceiver(row);
                setOpenEditDialog(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton>
              <RemoveRedEye
                onClick={() => {
                  navigate(`/receiver-detail/${row.id}`);
                }}
              />
            </IconButton>
          </Box>
        )}
        // onRowClick={(row) => navigate(`/receiver-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
      <AddEditReceiver
        centerId={id ?? ""}
        open={openDialog}
        setModalOpen={setOpenDialog}
      />

      {receiver && (
        <>
          <ConfirmModal
            type="warning"
            title="Are you sure you want to delete this receiver?"
            message="Are you sure you want to delete this receiver?"
            modalOpen={openDialog1}
            setModalOpen={setOpenDialog1}
            onOk={() => {
              removeReceiver({
                body: {
                  id: receiver?.id ?? "",
                },
              });
            }}
          ></ConfirmModal>
          <AddEditReceiver
            receiver={receiver}
            centerId={id ?? ""}
            open={openEditDialog}
            setModalOpen={setOpenEditDialog}
          />
        </>
      )}
    </Box>
  );
};

export default ReceiverList;
