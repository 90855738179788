import React, { useState } from "react";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { T } from "../localisations/T";
import { LoadingIndicator } from "../core";
import { useParams } from "react-router-dom";

import { MRT_ColumnDef } from "material-react-table";
import { DataTable } from "../core/components/data-table";
import { useGetBatchDetailQuery } from "../distribution-center/api/DistributionCentersQuery";

import { ActionDialog } from "../core/dialogs";
import {
  useFinalizePaymentMutation,
  useGetPendingPaymentDetailQuery,
} from "./api/ProviderQuery";
export interface Order {
  paymentStatus: string;
  padProvider: any;
  quantity: number;
  type: string;
  date: string;
  trackingNumber: string;
  id: string;
  totalPad: number;
  name: string;
  status: string;
  maximumBatchCapacity: number;
}

const PaymentDetail: React.FC = ({}) => {
  const { id } = useParams();
  const [openDialog1, setOpenDialog1] = useState(false);
  const { data, error, isLoading } = useGetPendingPaymentDetailQuery({
    id: id ?? "",
  });

  const [pay, { isLoading: isLoading1 }] = useFinalizePaymentMutation();

  const columns: Array<MRT_ColumnDef<Order>> = [
    {
      header: T("name"),
      accessorKey: "name",
      size: 15,
    },
    {
      header: T("pad-type"),
      accessorKey: "type",
      size: 15,
    },
    {
      header: T("status"),
      accessorKey: "status",
      size: 15,
    },
    {
      header: T("payment-status"),
      accessorKey: "paymentStatus",
      size: 15,
    },
  ];

  return (
    <>
      {isLoading ? (
     <Box
     sx={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       height: '100vh',
       width: '100%',
     }}
   >
     <LoadingIndicator/>
   </Box>
      ) : data ? (
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Paper sx={{ padding: 2 }}>
            <Box display={"flex"} justifyContent={"space-between"}>
              {" "}
              <Typography variant="h6">
                {data?.data?.batchPayment?.paymentReferenceNumber ?? ""}
              </Typography>
              <Box display={"flex"} gap={2}>
                {!data.data.distributionCenter && (
                  <Button
                    onClick={() => {
                      setOpenDialog1(true);
                    }}
                    variant="outlined"
                    disabled={!data?.data?.distributionCenter?.paymentProof}
                  >
                    {T("finalize-payment")}
                  </Button>
                )}
              </Box>
            </Box>
            <Box my={2} display={"flex"} gap={2}>
              <Stack>
                <Typography>{T("paymentStatus")}</Typography>
                <Typography>
                  {data?.data?.batchPayment?.paymentStatus ?? ""}
                </Typography>
              </Stack>
              <Divider />
              <Box my={2} display={"flex"} gap={3}></Box>{" "}
            </Box>
          </Paper>
          <Paper>
            <Box sx={{ pb: 5, px: 1 }}>
              <DataTable<Order>
                list={data?.data?.batches ?? []}
                count={data?.data?.batches?.length ?? 0}
                columns={columns}
                getRowId={(row) => row.id}
                isLoading={isLoading}
                isSuccess={!!data}
                isError={!!error}
                isFetching={false}
              />
            </Box>

            <ActionDialog
              message=""
              attachmentRequired={true}
              onClose={() => setOpenDialog1(false)}
              onExecuteAction={(val) => {
                pay({
                  body: {
                    id: id ?? "",
                    paymentProof: val.Attachments[0],
                  },
                });
              }}
              open={openDialog1}
              title=""
            />
          </Paper>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaymentDetail;
