import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { ReactNode, useState, useEffect } from "react";

export interface TabContent {
  label: ReactNode;
  icon?: JSX.Element;
  body: ReactNode;
}

export interface TabViewProps {
  tabs: TabContent[];
  header?: ReactNode;
  selectedIndex?: number;
  onIndexChange?: (index: number) => void;
  isDisabled?: (index: number) => boolean;
}

export const TabView = ({
  tabs,
  header,
  selectedIndex = 0,
  onIndexChange,
  isDisabled = () => false
}: TabViewProps): JSX.Element => {
  const [value, setValue] = useState(selectedIndex.toString());

  useEffect(() => {
    setValue(selectedIndex.toString());
  }, [selectedIndex]);

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    if (onIndexChange) {
      onIndexChange(parseInt(newValue));
    }
    setValue(newValue);
  };

  return (
    <TabContext  value={value}>
      <Box sx={{ width:"max-content", mt: 0 ,background: "#F8F9FD" }}>
        <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto">
          {tabs.map((tab, index) => (
            <Tab
              key={`tab-${index}`}
              value={index.toString()}
              icon={tab.icon}
              iconPosition="start"
              label={tab.label}
              disabled={isDisabled(index)}
              sx={{
                padding: "8px 16px",
                margin: "4px",
                borderRadius: "8px",
                color: value === index.toString() ? "white" : "primary.main",
                backgroundColor: value === index.toString() ? "primary.main" : "transparent",
                "&:hover": {
                  backgroundColor: value === index.toString() ? "primary.dark" : "grey.100",
                },
                "&.Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white",
                },
              }}
            />
          ))}
        </TabList>
      </Box>
      {header && <Box sx={{ my: 2 }}>{header}</Box>}
      <div>
        {tabs.map((tab, index) => (
          <TabPanel key={`tab-panel-${index}`} value={index.toString()}>
            {tab.body}
          </TabPanel>
        ))}
      </div>
    </TabContext>
  );
};
