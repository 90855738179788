import React, { useEffect } from "react";
import { Routing } from "./routing/routing";
import { EtLocalizationProvider } from "mui-ethiopian-datepicker";
import { useAppSelector } from "./store/hooks/app-store.hook";
import { DateType } from "mui-ethiopian-datepicker/dist/util/EthiopianDateUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./libs/auth";
import { T } from "./libs/localisations/T";
import AlertDialogSlide from "./libs/alert/components/error-dialog";

const App: React.FC = () => {
  const { error } = useAppSelector((state: any) => state.globalError);
  const navigate = useNavigate();
  const { logOut } = useAuth();

  const keys = [
    "መስከረም",
    "ጥቅምት",
    "ህዳር",
    "ታህሳስ",
    "ጥር",
    "የካቲት",
    "መጋቢት",
    "ሚያዚያ",
    "ግንቦት",
    "ሰኔ",
    "ሐምሌ",
    "ነሀሴ",
  ];

  const local = localStorage.getItem("local");
  const EtLocal: DateType =
    local === "en" || local === null
      ? "EN"
      : local === "am"
      ? "AMH"
      : local === "or"
      ? "AO"
      : "CUSTOM";

  useEffect(() => {
    if (
      error?.response?.data?.exceptions?.[0]?.message
        ?.toString()
        .startsWith("Access token")
    ) {
      logOut();
      navigate("/login");
      window.location.reload();
    }
  }, [error, logOut, navigate]);

  const location = useLocation();

  useEffect(() => {
    if (location?.pathname)
      sessionStorage.setItem("lastPath", location?.pathname);
  }, [location?.pathname]);

  return (
    <div className="App" style={{ position: "relative" }}>
      <EtLocalizationProvider
        locale={EtLocal}
        getLocalMonthName={(month: number) => T(keys[month - 1])}
      >
        <Routing />
        {error?.message && <AlertDialogSlide error={error} />}
      </EtLocalizationProvider>
    </div>
  );
};

export default App;
