import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import StatusButton from "../core/ui/status/status";
import AddEditOrder from './add-order';
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import OrderStatusButton from "../core/ui/status/order-status";
import { Donation } from "../donation/donotionList";
import { useGetAllDonationQuery, useGetOrderByProviderIdQuery } from "../donation/api/donotionApi";
import { useAuth } from "../auth";


export interface Order {
  id: string;
  orderNumber: string;
  product: string;
  orderDate: string;
  orderStatus: string;
}
interface UserProps {
  centerId: string;
}

const OrderList: React.FC<UserProps> = ({ centerId }) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const { session } = useAuth();
  const { data, error, isLoading } = useGetOrderByProviderIdQuery({
    id: session?.userInfo.centerId??"",
  });

  const columns: Array<MRT_ColumnDef<Donation>> = [
    {
      header: T("Pad Serial Number"),
      accessorKey: "padSerialNumber",
      size: 50,
    },
    {
      header: T("Order Quantity"),
      accessorKey: "orderQuantity",
      size: 50,
      Cell: ({ row: { original } }) => (
        <Typography>{original.orderQuantity}</Typography>
      ),
    },
    {
      header: T("Donor Name"),
      accessorKey: "donorFirstName",
      size: 50,
      Cell: ({ row: { original } }) => (
        <Typography>
          {original.donorFirstName} {original.donorLastName}
        </Typography>
      ),
    },
    {
      header: T("Order Type"),
      accessorKey: "orderType",
      size: 50,
    },
    {
        header: T("status"),
        accessorKey: "padStatus",
        size: 50,
        Cell({ row: { original } }): JSX.Element {
          return (
            <OrderStatusButton
              status={original.padStatus ? "active" : "inactive"}
              text={original.padStatus }
            />
          );
        },
      },
    // {
    //   header: "",
    //   accessorKey: "padId",
    //   size: 50,
    //   Cell({ row: { original } }): JSX.Element {
    //     return (
    //       <Box display={"flex"} gap={1}>
    //         <IconButton>
    //           <Delete />
    //         </IconButton>
    //         <IconButton onClick={() => setOpenEditDialog(true)}>
    //           <Edit />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box>
      <DataTable<Donation>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("Donations")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`${data?.data?.length ?? 0} ${T("Donations")}`}
              />
            </Box>
            {/* <Button
              onClick={() => setOpenDialog(true)}
              sx={{ minWidth: "200px" }}
              variant="contained"
            >
              {T("Add New Donation")}
            </Button> */}
          </Box>
        }
        list={data?.data ?? []}
        count={data?.data?.length ?? 0}
        columns={columns}
        getRowId={(row) => row.padId}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        onRowClick={(row) => navigate(`/donation/donation-detail/${row.padId}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </Box>
  );

};

export default OrderList;
