import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";

const MobileMenu = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleNavigation = (newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/"); // Navigate to Donations
        break;
      case 1:
        navigate("/orders"); // Navigate to Payment
        break;
      case 2:
        navigate("/profile"); // Navigate to Profile (Example route)
        break;
      default:
        break;
    }
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      }}
      elevation={3}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => handleNavigation(newValue)}
        showLabels
      >
        <BottomNavigationAction label="" icon={
          <HomeIcon />
         } />
        <BottomNavigationAction label="" icon={ <AttachFileIcon />} />
        <BottomNavigationAction label="" icon={<PersonIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default MobileMenu;
