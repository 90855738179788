import axios from "axios";
export const axiosClient = axios.create();
// export const downloadFile = async (
//   url: string,
//   func?: () => void,
//   dispatch?: any,
// ): Promise<void> => {
//   const response = await axios.get(url, {
//     responseType: "blob",
//     headers: {
//       "Accept-Language": localStorage.getItem("local"),
//     },
//   });

//   const contentDisposition = response.headers["content-disposition"];
//   let fileName = "";

//   if (contentDisposition) {
//     // Extract the filename from the Content-Disposition header
//     const match = contentDisposition.match(/filename="(.+)"/);
//     if (match && match.length > 1) {
//       fileName = match[1];
//     }
//   }

//   if (!fileName) {
//     // If the filename could not be extracted from the headers, use the last part of the URL as the filename
//     fileName = url.split("/").pop() ?? "";
//   }

//   const contentType = response.headers["content-type"];

//   if (contentType === "application/pdf") {
//     // Create the backdrop
//     const backdrop = document.createElement("div");
//     backdrop.style.position = "fixed";
//     backdrop.style.top = "0";
//     backdrop.style.left = "0";
//     backdrop.style.width = "100%";
//     backdrop.style.height = "100%";
//     backdrop.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
//     backdrop.style.zIndex = "9998";

//     // Create the modal container
//     const modalContainer = document.createElement("div");
//     modalContainer.style.position = "fixed";
//     modalContainer.style.top = "50%";
//     modalContainer.style.left = "50%";
//     modalContainer.style.width = "80%";
//     modalContainer.style.height = "80%";
//     modalContainer.style.transform = "translate(-50%, -50%)";
//     modalContainer.style.backgroundColor = "white";
//     modalContainer.style.display = "flex";
//     modalContainer.style.justifyContent = "center";
//     modalContainer.style.alignItems = "center";
//     modalContainer.style.zIndex = "9999";
//     modalContainer.style.padding = "35px";
//     modalContainer.style.borderRadius = "10px";

//     // Create the iframe
//     const pdfBlob = new Blob([response.data], { type: "application/pdf" });
//     const blobUrl = URL.createObjectURL(pdfBlob);
//     const iframe = document.createElement("iframe");
//     iframe.src = blobUrl;
//     iframe.style.width = "100%";
//     iframe.style.height = "100%";
//     iframe.style.border = "none";

//     // Create the close button
//     const closeButton = document.createElement("div");
//     closeButton.style.position = "absolute";
//     closeButton.style.top = "7px";
//     closeButton.style.right = "7px";
//     closeButton.style.cursor = "pointer";

//     closeButton.addEventListener("click", () => {
//       document.body.removeChild(backdrop);
//       document.body.removeChild(modalContainer);
//       func?.();
//     });

//     // Create the close icon
//     const closeIcon = document.createElement("span");
//     closeIcon.style.fontSize = "20px";
//     closeIcon.innerText = "x";

//     closeButton.addEventListener("mouseover", () => {
//       closeButton.style.backgroundColor = "rgba(0, 0, 0, 0.1)"; // Apply hover background color
//     });
//     closeButton.addEventListener("mouseout", () => {
//       closeButton.style.backgroundColor = "transparent"; // Reset to default background color on mouseout
//     });

//     // Apply circular background effect
//     closeButton.style.width = "32px";
//     closeButton.style.height = "32px";
//     closeButton.style.borderRadius = "50%";
//     closeButton.style.display = "flex";
//     closeButton.style.justifyContent = "center";
//     closeButton.style.alignItems = "center";
//     closeButton.style.transition = "background-color 0.2s ease";

//     // Append the close icon to the close button
//     closeButton.appendChild(closeIcon);
//     modalContainer.appendChild(iframe);
//     modalContainer.appendChild(closeButton);

//     document.body.appendChild(backdrop);
//     document.body.appendChild(modalContainer);
//   } else {
//     // If the response is not a PDF, download it with the extracted filename
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement("a");
//     link.href = url;
//     link.setAttribute("download", String(fileName));
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     window.URL.revokeObjectURL(url);
//     // dispatch(setIsLoading(false));
//   }
// };
