import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { useGetUsersByProviderIdQuery } from "../../provider/api/ProviderQuery";
import { useGetUsersByDistributionCenterIdQuery, useRemoveUserMutation } from "../../distribution-center/api/DistributionCentersQuery";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import { T } from "../../localisations/T";
import { Delete, Edit } from "@mui/icons-material";
import { ConfirmModal } from "../ui/confirm-modal/confirm-modal";

import { DataTable } from "../components/data-table";
import StatusButton from "../ui/status/status";
import AddEditUser from "./add-user";


export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: boolean;
  photo: string;
  sex: string;
  role: string;
  userRole: {
    id: string;
    name: string;
  };
  padProviderId: string;
  userType: "CENTRAL_ADMIN" | "DISTRIBUTION" | "PROVIDER";
}

const CenterUserList: React.FC = () => {

 
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const { session } = useAuth();

  const { data, error, isLoading } = useGetUsersByDistributionCenterIdQuery({
    id: session?.userInfo.centerId ?? "",
  });

  const [removeUser, { isLoading: isLoading1 }] = useRemoveUserMutation();

  useEffect(() => {}, []);

  const columns: Array<MRT_ColumnDef<User>> = [
    {
      header: T("username"),
      accessorKey: "firstName",
      size: 50,
      Cell: ({ row: { original } }) => (
        <Typography>
          {original.firstName} {original.lastName},
        </Typography>
      ),
    },
    // {
    //   header: T("email"),
    //   accessorKey: "email",
    //   size: 50,
    // },
    // {
    //   header: T("role"),
    //   accessorKey: "userRole.name",
    //   size: 50,

    // },
    {
      header: T("status"),
      accessorKey: "status",
      size: 50,
      Cell({ row: { original } }): JSX.Element {
        return (
          <StatusButton
            status={original.status ? "active" : "inactive"}
            text={original.status ? "Active" : "Inactive"}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <DataTable<User>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("users")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`${data?.data.users.length ?? 0} ${T("users")}`}
              />
            </Box>
            <Button
              onClick={() => setOpenDialog(true)}
              sx={{ minWidth: "200px" }}
              variant="contained"
            >
              {T("add-new-user")}
            </Button>
          </Box>
        }
        list={data?.data.users ?? []}
        count={data?.data.users.length ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        renderRowActions={(row) => (
          <Box display={"flex"} gap={1}>
            <IconButton>
              <Delete
                onClick={() => {
                  setUser(row);
                  setOpenDialog1(true);
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setUser(row);
                setOpenEditDialog(true);
              }}
            >
              <Edit />
            </IconButton>
          </Box>
        )}
        // onRowClick={(row) => navigate(`/user/user-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      {user && (
        <>
          <ConfirmModal
            type="warning"
            title="Are you sure you want to delete this user"
            message="Are you sure you want to delete this user?"
            modalOpen={openDialog1}
            setModalOpen={setOpenDialog1}
            onOk={() => {
              removeUser({
                body: {
                  id: user?.id ?? "",
                },
              });
            }}
          ></ConfirmModal>

          <AddEditUser
            centerId={session?.userInfo.centerId ?? ""}
            open={openEditDialog}
            // user={user}
            setModalOpen={setOpenEditDialog}
          />
        </>
      )}

      <AddEditUser
        centerId={session?.userInfo.centerId ?? ""}
        open={openDialog}
        setModalOpen={setOpenDialog}
      />
    </Box>
  );
};

export default CenterUserList;
