import React, { useState } from "react";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { T } from "../localisations/T";
import { LoadingIndicator, TabView } from "../core";
import UserList from "./users";
import ProviderList from "./provider-list";
import StockList from "./stock-list";
import OrderList from "./order-list";
import {
  useDeactivatePadProvidersMutation,
  useGetAllPadProviderApiQuery,
  useGetPadProvidersDetailQuery,
  useLazyGetPadProvidersDetailQuery,
} from "./api/ProviderQuery";
import { useParams } from "react-router-dom";
import PaymentTable from "./payment";
import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";
import AddEditProvider from "./add-provieder";
import PendingPaymentTable from "./PendingPayment";
import Avatar from "../core/components/avatar";
const ProviderDetail: React.FC = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useGetPadProvidersDetailQuery({
    id: id ?? "",
  });
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deacrivate, { isLoading: isLoading1 }] =
    useDeactivatePadProvidersMutation();
  return (
    <>
      {isLoading ? (
     <Box
     sx={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       height: '100vh',
       width: '100%',
     }}
   >
     <LoadingIndicator/>
   </Box>
      ) : data ? (
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Paper sx={{ padding: 2 }}>
            <Box width={"100%"} display={"flex"} gap={5}>
              <Avatar
                imageId={data?.data?.image}
                alt={"img"}
                editMode={false}
                sx={{
                  width: 200,
                  height: 200,
                  alignSelf: {
                    xs: "center",
                    sm: "flex-start",
                  },
                }}
                variant="rounded"
              />

              <Box width={"100%"}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography variant="h6">{data.data.name}</Typography>
                  <Box display={"flex"} gap={2}>
                    <Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={2}>
                          <Box>
                            <Button
                              onClick={() => {
                                setOpenDialog1(true);
                              }}
                              variant="outlined"
                            >
                              {data?.data?.status
                                ? T("deactivate")
                                : T("activate")}
                            </Button>
                          </Box>

                          <Box>
                            <Button disabled={!data?.data?.status}
                              variant="contained"
                              onClick={() => {
                                setOpenDialog(true);
                              }}
                            >
                              {T("edit")}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box my={2} display={"flex"} gap={2}>
                  <Stack>
                    <Typography>{T("phone-number")}</Typography>
                    <Typography>{data.data.phoneNumber}</Typography>
                  </Stack>
                  <Stack>
                    <Typography>{T("email")}</Typography>
                    <Typography>{data.data.email}</Typography>
                  </Stack>
                </Box>
                <Divider />
                <Box my={2} display={"flex"} gap={3}>
                  <Stack>
                    <Typography>{T("region")}</Typography>
                    <Typography>{data.data.region}</Typography>
                  </Stack>
                  <Stack>
                    <Typography>{T("zone")}</Typography>
                    <Typography>{data.data.zone}</Typography>
                  </Stack>
                  <Stack>
                    <Typography>{T("city")}</Typography>
                    <Typography>{data.data.city}</Typography>
                  </Stack>
                  <Stack>
                    <Typography>{T("sub-city")}</Typography>
                    <Typography>{data.data.subCity}</Typography>
                  </Stack>
                  <Stack>
                    <Typography>{T("woreda")}</Typography>
                    <Typography>{data.data.woreda}</Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Paper>

          <Paper>
            <TabView
              tabs={[
                {
                  body: <UserList centerId={id ?? ""} disabled={data.data.status} />,
                  label: T("users"),
                },
                {
                  body: <OrderList centerId={id ?? ""} />,
                  label: T("order"),
                },
                {
                  body: (
                    <>
                      <PendingPaymentTable centerId={id ?? ""} />
                    </>
                  ),
                  label: T("pending-payment"),
                },
                {
                  body: (
                    <>
                      <PaymentTable centerId={id ?? ""} />
                    </>
                  ),
                  label: T("payment"),
                },
              ]}
            />
          </Paper>
          <AddEditProvider
            open={openDialog}
            provider={data?.data}
            setModalOpen={setOpenDialog}
          />
        </Box>
      ) : (
        <></>
      )}

      <ConfirmModal
        type="warning"
        title={`Are you sure you want to ${
          data?.data?.status ? "deactivate" : "activate"
        } this pad provider`}
        message={`Are you sure you want to ${
          data?.data?.status ? "deactivate" : "activate"
        } this pad provider`}
        modalOpen={openDialog1}
        setModalOpen={setOpenDialog1}
        onOk={() => {
          deacrivate({
            body: {
              id: id ?? "",
              status: !data?.data?.status,
            },
          });
        }}
      ></ConfirmModal>
    </>
  );
};

export default ProviderDetail;
