import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import StatusButton from "../core/ui/status/status";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddEditProvider from "./add-provieder";
import {
  useGetAllPadProviderApiQuery,
  useRemovePadProvidersMutation,
  useUpdatePadProvidersMutation,
} from "./api/ProviderQuery";
import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";

export interface Provider {
  id: string;
  name: string;
  status: boolean;
  phoneNumber: string;
  email: string;
  image: string;
  city: string;
  subCity: string;
  woreda: string;
  region: string;
  houseNumber: string;
}

const ProviderList: React.FC = () => {
  // Fetch data using the API hook
  const { data, error, isLoading } = useGetAllPadProviderApiQuery();
console.log(data?.data , "provider")

  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [provider, setProvider] = useState<Provider | null>(null);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenEditDialog = () => setOpenEditDialog(true);
  const handleCloseEditDialog = () => setOpenEditDialog(false);
  const [openDialog1, setOpenDialog1] = useState(false);

  const [remove, { isLoading: isLoading1 }] = useRemovePadProvidersMutation();

  const columns: Array<MRT_ColumnDef<Provider>> = [
    { header: T("name"), accessorKey: "name", size: 50 },
    { header: T("phone"), accessorKey: "city", size: 50 },
    { header: T("email"), accessorKey: "email", size: 50 },
    {
      header: T("address"),
      accessorKey: "city",
      size: 50,
      Cell: ({ row: { original } }) => (
        <Typography>
          {original.city}, {original.region}, Woreda {original.woreda}, House{" "}
          {original.houseNumber}
        </Typography>
      ),
    },
    {
      header: T("status"),
      accessorKey: "status",
      size: 50,
      Cell: ({ row: { original } }) => (
        <StatusButton
          status={original.status ? "active" : "inactive"}
          text={original.status ? "Active" : "Inactive"}
        />
      ),
    },
  ];

  return (
    <Box>
      <DataTable<Provider>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("providers")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`${data?.meta?.totalCount ?? ""}
                ${T("providers")}`}
              />
            </Box>
            <Button
              onClick={handleOpenDialog}
              sx={{ minWidth: "200px" }}
              variant="contained"
            >
              {T("add-new-provider")}
            </Button>
          </Box>
        }
        list={data?.data ?? []}
        count={data?.meta.totalCount ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
      
        renderRowActions={(row) => (
          <Box display={"flex"} gap={1}>
            <IconButton>
              <Delete
                onClick={() => {
                  setProvider(row);
                  setOpenDialog1(true);
                }}
              />
            </IconButton>
            <IconButton disabled={!row.status}
              onClick={() => {
                setProvider(row);
                setOpenEditDialog(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton>
              <RemoveRedEye
                onClick={() => {
                  navigate(`/provider-detail/${row.id}`);
                }}
              />
            </IconButton>
          </Box>
        )}
        isFetching={false}
        // onRowClick={(row) => navigate(`/provider-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
      <AddEditProvider open={openDialog} setModalOpen={handleCloseDialog} />
      {provider && (
        <>
          <AddEditProvider
            open={openEditDialog}
            provider={provider}
            setModalOpen={handleCloseEditDialog}
          />
          <ConfirmModal
            type="warning"
            title="Are you sure you want to delete this pad provider"
            message="Are you sure you want to delete this provider?"
            modalOpen={openDialog1}
            setModalOpen={setOpenDialog1}
            onOk={() => {
              remove({
                body: {
                  id: provider.id ?? "",
                },
              });
            }}
          ></ConfirmModal>
        </>
      )}
    </Box>
  );
};

export default ProviderList;
