import React, { useState, useMemo } from "react";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { Box, Chip, Typography } from "@mui/material";
import { DataTable } from "../core/components/data-table";
import { T } from "../localisations/T";
import { useGetPadsByReceiverIdQuery } from "./api/ReciverQuery";

export interface Pad {
    id: string;
    receivedDate: string;
    PadStatus: string;
    padType: string;
    quantity: number;
    serialNumber: string;
}

interface PadTableProps {
    receiverId: string;
}

const PadTable: React.FC<PadTableProps> = ({ receiverId: centerId }) => {
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    // Fetch data using a query (replace with your actual API hook)
    const { data, error, isLoading } =   useGetPadsByReceiverIdQuery({ id: centerId });

    // Define table columns
    const columns: Array<MRT_ColumnDef<Pad>> = useMemo(
        () => [
            {
                header: T("Pad ID"),
                accessorKey: "id",
                size: 100,
            },
            {
                header: T("Received Date"),
                accessorKey: "receivedDate",
                size: 150,
                Cell: ({ row: { original } }) => (
                    <Typography>
                        {new Date(original.receivedDate).toLocaleDateString()}
                    </Typography>
                ),
            },
            {
                header: T("Pad Status"),
                accessorKey: "PadStatus",
                size: 120,
                Cell: ({ row: { original } }) => (
                    <Chip
                        label={T(original.PadStatus)}
                        color={original.PadStatus === "Active" ? "success" : "default"}
                    />
                ),
            },
            {
                header: T("Pad Type"),
                accessorKey: "padType",
                size: 120,
            },
            {
                header: T("Quantity"),
                accessorKey: "quantity",
                size: 100,
                Cell: ({ row: { original } }) => (
                    <Typography>{original.quantity}</Typography>
                ),
            },
            {
                header: T("Serial Number"),
                accessorKey: "serialNumber",
                size: 200,
            },
        ],
        []
    );

    return (
        <Box>
            <DataTable<Pad>
                toolbar={
                    <Box display="flex" alignItems="center" width="100%">
                        <Box display="flex" gap={3} alignItems="center" flex="1">
                            <Typography  variant="h6" >{T("Pad-received")}</Typography>
                            <Chip
                                color="primary"
                                variant="outlined"
                                label={`${data?.length ?? 0} ${T("Pads")}`}
                            />
                        </Box>
                    </Box>
                }
                list={data ?? []} // Replace with actual data
                count={data?.length ?? 0}
                columns={columns}
                getRowId={(row) => row.id}
                isLoading={isLoading}
                isSuccess={!!data}
                isError={!!error}
                isFetching={false}
                pagination={pagination}
                onPaginationChange={setPagination}
            />
        </Box>
    );
};

export default PadTable;
