import React from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  FormHelperText,
} from "@mui/material";
import { DefaultDialog } from "../core/dialogs";
import { T } from "../localisations/T";
import * as yup from "yup";
import { useFormik } from "formik";
import { Provider } from "./provider-list";
import { FileInput } from "../core/ui/file-input/utils/file-input";
import {
  useCreatePadProvidersMutation,
  useUpdatePadProvidersMutation,
} from "./api/ProviderQuery";

interface AddProviderProps {
  open: boolean;
  setModalOpen: (x: boolean) => void;
  provider?: Provider; // Optional, for editing case
}

type ProviderFormValues = Omit<Provider, "id">;

const AddEditProvider: React.FC<AddProviderProps> = ({
  open,
  setModalOpen,
  provider,
}) => {
  const [createProvider, { isLoading }] = useCreatePadProvidersMutation();
  const [updateProvider, { isLoading: isUpdating }] =
    useUpdatePadProvidersMutation();
  // Validation schema for the form
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    status: yup.boolean().required("Status is required"),
    // Address validation
    phoneNumber: yup
      .string()
      .matches(/^\d+$/, "Phone number must be numeric")
      .required("Phone is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),

    // Address validation
    city: yup.string().required("City is required"),
    // subcity: yup.string().required("Subcity is required"),
    woreda: yup.string().required("Woreda is required"),
    houseNumber: yup.string().required("House number is required"),
  });

  // Initial form values
  const initialValues: ProviderFormValues = {
    name: provider?.name || "",
    image: provider?.image ?? "",
    status: provider?.status || true,
    phoneNumber: provider?.phoneNumber || "",
    email: provider?.email || "",
    city: provider?.city || "",
    subCity: provider?.subCity || "",
    woreda: provider?.woreda || "",
    houseNumber: provider?.houseNumber || "",
    region: provider?.region || "",
  };

  // Formik setup
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (provider) {
        // Handle the edit logic (e.g., update the provider)
        const payload = {
          id: provider?.id ?? "",
          ...values,
        };
        await updateProvider({ body: payload  }).unwrap();
        // console.log("Editing provider:", values);
      } else {
       try {
         await createProvider({ body: values }).unwrap();
       } catch (error) {
        throw new Error
       }

        console.log("Creating provider:", values);
      }
      setModalOpen(false);
    },
  });

  return (
    <Box>
      <DefaultDialog
        title={provider ? T("edit-provider") : T("add-provider")}
        open={open}
        minWidth={"60%"}
        onClose={() => setModalOpen(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box flexDirection={"column"} display={"flex"} gap={2}>
            <Box>
              <Typography variant="subtitle1">Name</Typography>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Phone</Typography>
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label="phoneNumber"
                type="text"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Email</Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Region</Typography>
              <TextField
                fullWidth
                id="region"
                name="region"
                label="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                error={formik.touched.region && Boolean(formik.errors.region)}
                helperText={formik.touched.region && formik.errors.region}
                margin="normal"
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">City</Typography>
              <TextField
                fullWidth
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Subcity</Typography>
              <TextField
                fullWidth
                id="subCity"
                name="subCity"
                label="SubCity"
                value={formik.values.subCity}
                onChange={formik.handleChange}
                error={formik.touched.subCity && Boolean(formik.errors.subCity)}
                helperText={formik.touched.subCity && formik.errors.subCity}
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Woreda</Typography>
              <TextField
                fullWidth
                id="woreda"
                name="woreda"
                label="Woreda"
                value={formik.values.woreda}
                onChange={formik.handleChange}
                error={formik.touched.woreda && Boolean(formik.errors.woreda)}
                helperText={formik.touched.woreda && formik.errors.woreda}
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">House Number</Typography>
              <TextField
                fullWidth
                id="houseNumber"
                name="houseNumber"
                label="House Number"
                value={formik.values.houseNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.houseNumber &&
                  Boolean(formik.errors.houseNumber)
                }
                helperText={
                  formik.touched.houseNumber && formik.errors.houseNumber
                }
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Active Status</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    id="status"
                    name="status"
                    checked={formik.values.status}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Active Status"
              />
            </Box>
            <Box>
              <>
                <Grid item xs={2} sx={{ py: 1, pb: -1, width: "100%" }}>
                  <FileInput
                    message={"PNG, JPG or PDF (max. 3MB)"}
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                      "application/pdf": [".pdf"],
                    }}
                    maxFiles={1}
                    onChange={(val) => {
                      formik.setFieldValue("image", val[0]);
                    }}
                    maxSize={{ measurement: "MB", size: 3 }}
                  />
                  <FormHelperText
                    error={
                      (formik.touched.image && Boolean(formik.errors.image)) ||
                      (!formik.values.image && formik.submitCount > 0)
                    }
                  >
                    {formik.touched.image && formik.errors.image
                      ? formik.errors.image
                      : !formik.values.image && formik.submitCount > 0
                      ? T("add-image")
                      : null}
                  </FormHelperText>
                </Grid>
              </>
            </Box>

            <Box padding={2}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
              >
                {T(provider ? "update" : "submit")}
              </Button>
            </Box>
          </Box>
        </form>
      </DefaultDialog>
    </Box>
  );
};

export default AddEditProvider;
