import { Payment } from './../../provider/payment';
import { appApi } from "../../../store/app.api";
import { appEndpoint } from "../../../store/app.endpoint";
import { CommandRequest } from "../../core/utilities/command-request";


// Define the API endpoints
const PaymentApi = appApi.injectEndpoints({
  endpoints: (build) => ({
  
   
    getAllPayments:build.query<any, void>({
      query: () => ({
        url: `${appEndpoint.   getAllPayment}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Payment"], // Tag for cache management
    }),
  
  }),

  overrideExisting: false, // Ensures this endpoint doesn't override others
});

// Export hooks for querying
export const {
  
  useGetAllPaymentsQuery
} = PaymentApi ;
