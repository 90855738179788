import React from 'react';
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { FaUsers, FaBuilding, FaTruck, FaDonate } from 'react-icons/fa';

ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Dashboard = () => {
  const cardData = [
    { title: 'Total Receiver', value: 150, icon: <FaUsers size={40} /> },
    { title: 'Total Center', value: 75, icon: <FaBuilding size={40} /> },
    { title: 'Total Provider', value: 50, icon: <FaTruck size={40} /> },
    { title: 'Total Donation', value: 200, icon: <FaDonate size={40} /> },
  ];

  const barData = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
      {
        label: 'Total revenue',
        data: [0, 0, 0, 0, 8, 0, 0],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const pieData = {
    labels: ['Group A', 'Group B', 'Group C'],
    datasets: [
      {
        label: 'Groups',
        data: [33.3, 33.3, 33.3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        {cardData.map((card, index) => (
          <Box key={index} sx={{ flex: '1', margin: '0 10px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px', textAlign: 'center' }}>
            {card.icon}
            <Typography variant="h6">{card.title}</Typography>
            <Typography variant="h4" sx={{ margin: '10px 0' }}>{card.value}</Typography>
            <a href="#">View All</a>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ flex: 1, padding: '10px', height: '300px' }}>
          <Typography variant="h6" gutterBottom>
            Revenue Overview
          </Typography>
          <Bar data={barData} options={{ maintainAspectRatio: false }} />
        </Box>
        <Box sx={{ flex: 1, padding: '10px', height: '300px' }}>
          <Typography variant="h6" gutterBottom>
            Drivers grouped by different status
          </Typography>
          <Pie data={pieData} options={{ maintainAspectRatio: false }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;