import { Description, Download } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, IconButton, ListItem, ListItemText, Typography } from "@mui/material";

import { useEffect, useState } from "react";

import { computeFileSize } from "../file-input/utils/compute-file-size";
import SidePanelDialog from "../../dialogs/SidelPanelDialog";
import { T } from "../../../localisations/T";
import { generateFileUrl } from "../../utilities/generate-file-url";
import { getCurrentSession } from "../../../auth";
import { appEndpoint } from "../../../../store/app.endpoint";
import { DefaultDialog } from "../../dialogs";

/* Component props */
type FilePreviewProps = {
  fileId: string;
  fileDisplay: "fileId" | "details";
  hideDownload?: boolean;
};

export const FilePreview = (props: FilePreviewProps): JSX.Element => {
  /* Component states */
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fileObject, setFileObject] = useState<any>();
  const [isError, setIsError] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
  const [maxHeight, setMaxHeight] = useState(100);

  const [fileUrl, setFileUrl] = useState<string>(generateFileUrl(props?.fileId));
  const [file, setFile] = useState<any>();

  /* event handler */
  const openModal = (): void => {
    setModalOpen(true);
  };
  const closeModal = (): void => {
    setModalOpen(false);
  };

  /* when fileDisplay is set to "fileId" mode load and open the modal */

  const displayFileOnFileIdMode = async (): Promise<void> => {
    if (!file) {
      try {
        const token = getCurrentSession()?.accessToken;
        const response = await fetch(`${appEndpoint.getFile}/${props.fileId}`, { method: "GET" , headers: {
             
          Authorization: token ? `Bearer ${token}` : "",
        },});
        const blob = await response.blob();
        setFileObject(blob);
        const disposition = response.headers?.get("content-disposition");
        const filename = disposition
          ?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1]
          ?.replace(/['"]/g, "");

        setFile(new File([blob], filename!));
        openModal();
      } catch (error) {
        console.error("eee");
      }
    } else {
      openModal();
    }
  };

  /* custom variables */
  const style = {
    position: "absolute" as "absolute",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -25%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 2,
    px: 4,
    overflow: "auto",
  };

  /* useEffect hooks */

  useEffect(() => {
    const fetchFile = async (): Promise<void> => {
      if (fileUrl && props?.fileDisplay === "details") {
        const token = getCurrentSession()?.accessToken;
        try {
          const response = await fetch( `${appEndpoint.getFile}/${props.fileId}`, {
            method: "GET",
          
            headers: {
             
              Authorization: token ? `Bearer ${token}` : "",
            },
          });
          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.status} ${response.statusText}`);
          }
          const blob = await response.blob();
          setFileObject(blob);
          let filename = "";
          const disposition = response.headers.get("content-disposition");
          if (disposition?.includes("attachment")) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches?.[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          setFile(new File([blob], filename));
          if (blob.type === "application/pdf") {
            // Display the PDF file using an <iframe>
            const fileUrl = URL.createObjectURL(blob);
            setPdfUrl(fileUrl); // Set the PDF URL in state
          }
        } catch (error) {
          setIsError(true);
          console.error("eaa", error);
          // handle error here
        }
      }
    };
    void fetchFile();
  }, [fileUrl, props?.fileDisplay]);

  return (
    <>
      {modalOpen && (
        <>
          <DefaultDialog
            open={modalOpen}
            title="Document View"
            onClose={() => setModalOpen(false)}
            minWidth={'50%'}
          >
            <Box
              sx={{
                pt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {pdfUrl ? (
                <iframe
                  src={pdfUrl}
                  loading="lazy"
                  width="100%"
                  height={`${maxHeight}px`}
                  title="PDF Viewer"
                  onLoad={(event) => {
                    const imgElement = event.target as HTMLImageElement;
                    const rect = imgElement.getBoundingClientRect();
                    const availableHeight = window.innerHeight - rect.top;
                    setMaxHeight(availableHeight);
                  }}
                />
              ) : (
                <Box sx={{ flex: "1", mb: "10px", mx: "10px" }}>
                  <img
                    src={URL.createObjectURL(fileObject)}
                    alt="My Image"
                    loading="lazy"
                    width={"100%"}
                    height={`${maxHeight - 10}px`}
                    style={{ objectFit: "contain", borderRadius: "5px" }}
                    onLoad={(event) => {
                      const imgElement = event.target as HTMLImageElement;
                      const rect = imgElement.getBoundingClientRect();
                      const availableHeight = window.innerHeight - rect.top;
                      setMaxHeight(availableHeight);
                    }}
                  />
                </Box>
              )}
            </Box>
          </DefaultDialog>
          
        </>
      )}

      {!isError ? (
        <>
          {props?.fileDisplay === "details" && file && (
            <ListItem
              secondaryAction={
                !props.hideDownload && (
                  <a href={fileUrl} download>
                    <IconButton size="small">
                      <Download style={{ color: "#007AFE" }} />
                    </IconButton>
                  </a>
                )
              }
            >
              <Box style={{ marginRight: "0.5rem" }}>
                <Description
                  style={{
                    padding: "8px",
                    width: 40,
                    height: 40,
                  }}
                  className={"bg-lighter-blue rounded-full"}
                  color={"primary"}
                />
              </Box>

              <ListItemText
                primary={
                  <Typography
                    style={{
                      textDecoration: "underline",
                      color: "#007AFE",
                      fontWeight: "lighter",
                      cursor: "pointer",
                      wordWrap: "break-word",
                      overflowWrap: "anywhere",
                    }}
                    onClick={openModal}
                  >
                    {file?.name}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {computeFileSize(file?.size)}
                  </Typography>
                }
              />
            </ListItem>
          )}

          {props?.fileDisplay === "fileId" && (
            <Typography
              variant={"body2"}
              className={"link"}
              onClick={displayFileOnFileIdMode}
              style={{
                wordWrap: "break-word",
                overflowWrap: "anywhere",
              }}
            >
              {props?.fileId}
            </Typography>
          )}
        </>
      ) :
      
      
      
      
      
      
      
      
      
      
      (
        <>
          <ListItem>
            <Box style={{ marginRight: "0.5rem" }}>
              <ErrorOutlineIcon
                style={{
                  padding: "8px",
                  width: 40,
                  height: 40,
                  backgroundColor: "#fff0f0",
                  borderRadius: 20,
                }}
                color={"error"}
              />
            </Box>

            <ListItemText secondary={T("file-not-found")} />
          </ListItem>
        </>
      )}
    </>
  );
};

FilePreview.defaultProps = { fileDisplay: "details" };
