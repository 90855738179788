import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useTheme,
  Chip,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import { T } from "../../localisations/T";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { LoadingIndicator } from "..";
import { ConfirmModal } from "../ui/confirm-modal/confirm-modal";
import {
  useUpdateReceiverMutation,
  useCreateReceiverMutation,
} from "../../distribution-center/api/DistributionCentersQuery";
import PadTable, { Pad } from "../../recivers/padTable";
import { useGetReceiverByIdQuery } from "../../recivers/api/ReciverQuery";
import { useAuth } from "../../auth";
import AddEditReceiver from "./add-reciver";
import Avatar from "../components/avatar";
import DonatePadToReceiver from "./donatePad";
import { MRT_ColumnDef } from "material-react-table";
import { DataTable } from "../components/data-table";
import { FilePreview } from "../ui/file-preview/file-preview";

const CenterReceiverDetail: React.FC = () => {
  const { id } = useParams();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditDialog1, setOpenEditDialog1] = useState(false);
  const [openEditDialog2, setOpenEditDialog2] = useState(false);
  const { data, error, isLoading } = useGetReceiverByIdQuery({ id: id ?? "" });
  const { session } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const documents = [
    { name: "Birth Certificate", size: "604" },
    { name: "Student ID Card", size: "929" },
    { name: "Letter", size: "929" },
  ];

  const columns: Array<MRT_ColumnDef<any>> = useMemo(
    () => [
      {
        header: T("Pad ID"),
        accessorKey: "serialNumber",
        size: 100,
      },
      {
        header: T("Received Date"),
        accessorKey: "receivedDate",
        size: 150,
        Cell: ({ row: { original } }) => (
          <Typography>
            {new Date(original.receivedDate).toLocaleDateString()}
          </Typography>
        ),
      },

      {
        header: T("Pad Type"),
        accessorKey: "type",
        size: 120,
      },
    ],
    []
  );

  const [updateReceiver, { isLoading: isLoading1 }] =
    useUpdateReceiverMutation();

  const calculateAge = (birthDate: string) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }
    return age;
  };

  if (isLoading!) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <LoadingIndicator/>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Paper sx={{ padding: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={2}
        >
          <Typography variant="h6">
            {data?.data?.firstName ?? ""}{" "}
            {data?.data?.lastName ?? T("not-available")}
          </Typography>
          {isMobile ? (
            <>
              <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setOpenEditDialog1(true);
                    handleMenuClose();
                  }}
                >
                  {data?.data?.status ? T("deactivate") : T("activate")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenEditDialog(true);
                    handleMenuClose();
                  }}
                  disabled={!data?.data?.status}
                >
                  {T("edit")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenEditDialog2(true);
                    handleMenuClose();
                  }}
                  disabled={!data?.data?.status}
                >
                  {T("donate-pad")}
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Box
              display="flex"
              flexWrap="wrap"
              gap={1}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
              }}
            >
              <Button
                onClick={() => setOpenEditDialog1(true)}
                variant="outlined"
              >
                {data?.data?.status ? T("deactivate") : T("activate")}
              </Button>
              <Button
                disabled={!data?.data?.status}
                onClick={() => setOpenEditDialog(true)}
                variant="contained"
              >
                {T("edit")}
              </Button>
              <Button
                disabled={!data?.data?.status}
                onClick={() => setOpenEditDialog2(true)}
                variant="contained"
              >
                {T("donate-pad")}
              </Button>
            </Box>
          )}
        </Box>

        <Box
          my={2}
          display="flex"
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
          alignItems="center"
          gap={3}
        >
          <Avatar
            imageId={data?.data?.photo ?? null}
            editMode={false}
            sx={{
              width: 100,
              height: 100,
              alignSelf: {
                xs: "center",
                sm: "flex-start",
              },
            }}
            variant="circular"
          />

          <Box>
            <Box my={2} display="flex" flexWrap="wrap" gap={2}>
              <Stack>
                <Typography>{T("phone-number")}</Typography>
                <Typography>
                  {data?.data?.phoneNumber ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("email")}</Typography>
                <Typography>
                  {data?.data?.email ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("age")}</Typography>
                <Typography>
                  {data?.data?.dateOfBirth
                    ? `${calculateAge(data.data.dateOfBirth)} ${T("years")}`
                    : T("not-available")}
                </Typography>
              </Stack>
            </Box>

            <Divider />

            <Box my={2} display="flex" flexWrap="wrap" gap={3}>
              <Stack>
                <Typography>{T("region")}</Typography>
                <Typography>
                  {data?.data?.region ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("zone")}</Typography>
                <Typography>
                  {data?.data?.zone ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("city")}</Typography>
                <Typography>
                  {data?.data?.city ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("sub-city")}</Typography>
                <Typography>
                  {data?.data?.subCity ?? T("not-available")}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box mt={3}>
          <Typography variant="h5">{T("documents")}</Typography>
          {documents.length > 0 ? (
            <List>
              <ListItem
                secondaryAction={
                  <IconButton edge="end">
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <InsertDriveFileIcon style={{ marginRight: 16 }} />
                <ListItemText
                  primary={"identificationCard"}
                  secondary={
                    <FilePreview
                      fileDisplay="fileId"
                      fileId={data?.data?.identificationCard}
                    />
                  }
                />
                <ListItem
                  secondaryAction={
                    <IconButton edge="end">
                      <MoreVertIcon />
                    </IconButton>
                  }
                >
                  <InsertDriveFileIcon style={{ marginRight: 16 }} />
                  <ListItemText
                    primary={"supporting-document"}
                    secondary={
                      <FilePreview
                        fileDisplay="fileId"
                        fileId={data?.data?.supportDocument}
                      />
                    }
                  />
                </ListItem>
              </ListItem>
            </List>
          ) : (
            <Typography>{T("no-documents-found")}</Typography>
          )}
        </Box>

        <Box mt={2}>
          <DataTable<any>
            list={data?.data?.pads ?? []} // Replace with actual data
            count={data?.data?.pads?.length ?? 0}
            columns={columns}
            getRowId={(row) => row.id}
            isLoading={isLoading}
            isSuccess={!!data}
            isError={!!error}
            isFetching={false}
          />
        </Box>
      </Paper>

      <AddEditReceiver
        centerId={session?.userInfo?.centerId ?? ""}
        open={openEditDialog}
        receiver={data?.data}
        setModalOpen={setOpenEditDialog}
      />

      <ConfirmModal
        type="warning"
        title={`Are you sure you want to ${
          data?.data?.status ? "deactivate" : "activate"
        } this receiver?`}
        message={`Are you sure you want to ${
          data?.data?.status ? "deactivate" : "activate"
        } this receiver?`}
        modalOpen={openEditDialog1}
        setModalOpen={setOpenEditDialog1}
        onOk={() =>
          updateReceiver({
            body: {
              id: data?.data.id ?? "",
              status: !data?.data?.status,
            },
          }).unwrap()
        }
      />

      <DonatePadToReceiver
        open={openEditDialog2}
        setModalOpen={setOpenEditDialog2}
        receiver={id ?? ""}
      />
    </Box>
  );
};

export default CenterReceiverDetail;
