import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import SettingsTitle from "./common/components/SettingsTitle";
import { DataTable } from "../core/components/data-table";
import { useGetAllCategoryQuery } from "../provider/api/ProviderQuery";
import AddCategoryForm from "./addCatagory";


export interface Category {
  id: string;
  name: string;
}

const CategorySettingPage = (): JSX.Element => {
  const { data, error, isLoading } = useGetAllCategoryQuery();
  const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false);

  const columns = useMemo<Array<MRT_ColumnDef<Category>>>(
    () => [
      {
        header: "Category Name",
        accessorKey: "name",
        Cell: ({ cell }) => (
          <Typography variant="body2">{cell.getValue<string>()}</Typography>
        ),
      },
    ],
    []
  );

  return (
    <>
      <SettingsTitle
        prm="CATEGORY_SETTINGS"
        title="Categories"
        actionTitle="Add Category"
        onClick={() => setOpenCategoryModal(true)}
      >
        <DataTable<Category>
          variant="outlined"
          columns={columns}
          list={data?.data || []}
          count={data?.data?.length || 0}
          getRowId={(row) => row.id}
          isLoading={isLoading}
          isSuccess={!!data}
          isError={!!error}
          isFetching={isLoading}
        />
      </SettingsTitle>
      {openCategoryModal && (
        <AddCategoryForm
          openModal={openCategoryModal}
          setOpenModal={setOpenCategoryModal}
        />
      )}
    </>
  );
};

export default CategorySettingPage;
