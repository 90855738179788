import React, { useState } from "react";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { T } from "../localisations/T";
import { LoadingIndicator } from "../core";
import { useParams } from "react-router-dom";
import {
  useGetBatchDetailQuery,
} from "./api/DistributionCentersQuery";
import { MRT_ColumnDef } from "material-react-table";
import { Pads } from "../providers/Pads";
import { DataTable } from "../core/components/data-table";
import AssignCenter from "./assigncenter";

const AdminBatchesDetail: React.FC = ({}) => {
  const { id } = useParams();
  const [openDialog1, setOpenDialog1] = useState(false);
  const { data, error, isLoading } = useGetBatchDetailQuery({
    id: id ?? "",
  });

 
  const columns: Array<MRT_ColumnDef<Pads>> = [
    {
      header: T("serial-number"),
      accessorKey: "serialNumber",
      size: 15,
      Cell({ row: { original } }): JSX.Element {
        return <Typography>{original.serialNumber ?? "NA"}</Typography>;
      },
    },

    // {
    //   header: T("pad-type"),
    //   accessorKey: "type",
    //   size: 15,
    // },
    {
      header: T("status"),
      accessorKey: "padStatus",
      size: 15,
    },
    {
      header: T("date"),
      accessorKey: "createdAt",
      size: 15,
    },
  ];
  return (
    <>
      {isLoading ? (
       <Box
       sx={{
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         height: '100vh',
         width: '100%',
       }}
     >
       <LoadingIndicator/>
     </Box>
      ) : data ? (
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Paper sx={{ padding: 2 }}>

            <Box display={"flex"} justifyContent={"space-between"}>
              {" "}
              <Typography variant="h6">{data.data.name}</Typography>
              <Box display={"flex"} gap={2}>

                {
                !  data.data.distributionCenter&& <Button
                  onClick={() => {
                    setOpenDialog1(true);
                  }}
                  variant="outlined"
                >
                  {T("assign-center")}
                </Button>
                }
               
              </Box>
            </Box>
            <Box my={2} display={"flex"} gap={2}>
              <Stack>
                <Typography>{T("pad-provider")}</Typography>
                <Typography>{data?.data?.padProvider.name ?? ""}</Typography>
              </Stack>
              {
                data.data.distributionCenter&&
                 <Stack>
                 <Typography>{T("distributionCenter")}</Typography>
                 <Typography>{data?.data?.distributionCenter.name ?? ""}</Typography>
               </Stack>
              }
              <Stack>
                <Typography>{T("totalPad")}</Typography>
                <Typography>{data?.data?.totalPad}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("type")}</Typography>
                <Typography>{data?.data?.type}</Typography>
              </Stack>
            </Box>
            <Divider />
            <Box my={2} display={"flex"} gap={3}>
              <Stack>
                <Typography>{T("status")}</Typography>
                <Typography>{data?.data?.status}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("paymentAmount")}</Typography>
                <Typography>{data?.data?.paymentAmount}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("maximumBatchCapacity")}</Typography>
                <Typography>{data?.data?.maximumBatchCapacity}</Typography>
              </Stack>
            </Box>
          </Paper>
          <Paper>
            <Box sx={{ pb: 5, px: 1 }}>
              <DataTable<Pads>
                list={data?.data?.pads ?? []}
                count={data?.data?.pads.length ?? 0}
                columns={columns}
                getRowId={(row) => row.id}
                isLoading={isLoading}
                isSuccess={!!data}
                isError={!!error}
                isFetching={false}
              />
            </Box>

            <AssignCenter
            batchId={id??""}
              openModal={openDialog1}
              setOpenModal={setOpenDialog1}
              padProviderId={data?.data?.padProvider?.id??""}
            />
          </Paper>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminBatchesDetail;
