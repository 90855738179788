import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  Skeleton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { getCurrentSession, useAuth } from "../../../auth";
import { appEndpoint } from "../../../../store/app.endpoint";
import { T } from "../../../localisations/T";

interface AvatarProps extends Omit<MuiAvatarProps, "src"> {
  imageId: string | null | undefined;
  setFile?:
    | React.Dispatch<React.SetStateAction<string | null>>
    | ((value: string) => void);
  editMode?: boolean;
  required?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ imageId, ...props }) => {
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [fileId, setFileId] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { session } = useAuth();

  useEffect(() => {
    if (imageId) {
      setFileId(imageId);
      props?.setFile && props?.setFile(imageId);
    }
    if (fileId && imageId !== fileId) {
      props?.setFile && props?.setFile(fileId);
    }
  }, [imageId, fileId]);

  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const cachedImageUrl = useMemo(() => {
    if (session && fileId) {
      return `${appEndpoint.getFile}/${fileId}`;
    } else {
      return null;
    }
  }, [session, fileId]);

  useEffect(() => {
    if (cachedImageUrl) {
      const token = getCurrentSession()?.accessToken;
      setLoading(true);
      fetch(cachedImageUrl, {
        method: "GET",
        headers: {
          
          Authorization: token ? `Bearer ${token}` : "",
        },
      })
        .then(async (response) => {
          return await response.blob();
        })
        .then((blob) => {
          const file = new File([blob], "filename");
          setImageUrl(file);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [cachedImageUrl]);

  const uploadFile = async (event: any): Promise<void> => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      const response = await axios.post(appEndpoint.postFile, formData);
      setFileId(response?.data?.[0].fileId);
      props?.setFile && props.setFile(response?.data[0].fileId);
      setUploading(false);
    } catch (error: any) {
      setUploading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      gap={2}
      alignItems="center"
      justifyContent="center"
    >
      {!loading ? (
        <MuiAvatar
          src={
            URL.createObjectURL(new Blob([imageUrl])) ??
            "/assets/placeholder-person.jpg"
          }
          {...props}
        ></MuiAvatar>
      ) : (
        <Skeleton variant={"rounded"} sx={props.sx ?? {}} />
      )}
      {uploading && <LinearProgress sx={{ width: "100%" }} />}
      {props?.editMode ? (
        <>
          <Button component="span" onClick={handleButtonClick}>
            <Typography variant="body2">
              {imageUrl ? T("change-image") : T("add-image")}
            </Typography>
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            hidden
            id="image-upload"
            style={{ display: "none" }}
            onChange={async (file: any) => await uploadFile(file)}
            accept="image/png, image/jpeg"
            required={props?.required}
          />
        </>
      ) : null}
    </Box>
  );
};

export default React.memo(Avatar);
