
import { Outlet } from "react-router-dom";
import MobileMenu from "./DistributionMenu";


const DistLayout = () => {
    return (
      <>
        <MobileMenu />
        <Outlet /> {/* Render the nested routes here */}
      </>
    );
  };

export default  DistLayout;
