import { appApi } from "../../../store/app.api";
import { appEndpoint } from "../../../store/app.endpoint";

// Define the API endpoints
const DonationApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    // Fetch all distribution centers
    getAllDonation: build.query<any, void>({
      query: () => ({
        url: appEndpoint.getAllDonation,
        method: "get",
      }),
      providesTags: ["Donation"], // Tag for cache management
    }),

    // Fetch a specific distribution center by ID
    getDistributionCenterById: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getDistributionCenterById}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["DistributionCenters"], // Tag for cache management
    }),

  
    getOrderByProviderId: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint. getOrdersByProviderId}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Donation"], // Tag for cache management
    }),

  }),

  overrideExisting: false, // Ensures this endpoint doesn't override others
});

// Export hooks for querying
export const {
  useGetAllDonationQuery,
  useLazyGetDistributionCenterByIdQuery,
  useGetDistributionCenterByIdQuery,
useGetOrderByProviderIdQuery

} = DonationApi;
