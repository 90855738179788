import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import { Box, Button, Chip, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useGetAllDonorQuery } from "./api/donerApi";

export interface Donor {
  id: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}

const DonorList: React.FC = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    mode: "add" | "edit";
    donorId?: string;
  }>({ open: false, mode: "add" });
  const [donors, setDonors] = useState<Donor[]>([]);

  const { data, error, isLoading } = useGetAllDonorQuery();

  const columns: Array<MRT_ColumnDef<Donor>> = [
    {
      header: T("name"),
      accessorKey: "firstName",
      size: 50,
      Cell: ({ row: { original } }) => (
        <Typography>
          {original.firstName} {original.lastName}
        </Typography>
      ),
    },
    {
      header: T("email"),
      accessorKey: "email",
      size: 50,
    },
    {
      header: T("phone-number"),
      accessorKey: "phoneNumber",
      size: 50,
    },
  ];

  return (
    <Box>
      <DataTable<Donor>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("donors")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`${data?.meta.totalCount??0} ${T("donors")}`}
              />
            </Box>
            {/* <Button
              onClick={() => setDialogState({ open: true, mode: "add" })}
              sx={{ minWidth: "200px" }}
              variant="contained"
            >
              {T("add-new-donor")}
            </Button> */}
          </Box>
        }
        list={data?.data ?? []}
        count={data?.meta.totalCount ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        onRowClick={(row) => navigate(`/donor-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </Box>
  );
};

export default DonorList;
