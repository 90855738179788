import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import LoginIcon from "@mui/icons-material/Login";
import { LoadingButton } from "@mui/lab";

import { useAuth } from "../hooks/useAuth";
import { LoginRequest } from "../model/login-request";

/* Form schema */

export const LoginForm = (): JSX.Element => {
  /* component states */
  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email format")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test("no-consecutive-dots", "Email cannot contain consecutive dots", 
        value => !value?.includes('..'))
      .test("no-consecutive-at", "Email cannot contain consecutive @ symbols", 
        value => !value?.includes('@@'))
      .min(5, "Email must be at least 5 characters")
      .max(255, "Email must not exceed 255 characters")
      .trim(),

    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(50, "Password must not exceed 50 characters")
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      //   "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      // )
      .test("no-spaces", "Password cannot contain spaces", 
        value => !/\s/.test(value || ''))
      .test("no-consecutive-chars", "Password cannot contain consecutive repeating characters", 
        value => !/(.)\1{2,}/.test(value || '')),
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  /* hooks */
  const { submitLoginRequest, selectIsLoading } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (data: any) => {
      const request: LoginRequest = {
        ...data,
        clientInfo: window.navigator.userAgent,
      };
      submitLoginRequest(request);
    },
  });

  /* Event handlers */
  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ marginTop: "1rem" }}>
        <TextField
          fullWidth
          size="medium"
          id="email"
          name="email"
          label={"email"}
          autoComplete="off"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email?.toString()}
          autoFocus
        />
      </div>

      <div style={{ marginTop: "0.7rem" }}>
        <TextField
          fullWidth
          id="password"
          autoComplete="off"
          name="password"
          size="medium"
          label={"Password"}
          type={showPassword ? "text" : "password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && typeof formik.errors.password === 'string' ? formik.errors.password : undefined}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div style={{ marginTop: "1.5rem" }}>
        <LoadingButton
          size="medium"
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          loading={selectIsLoading} // Set the loading prop
          disableElevation
          startIcon={<LoginIcon />}
        >
          {"Login"}
        </LoadingButton>
      </div>
    </form>
  );
};
