import { KeyboardReturn } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): Partial<State> {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error });
  }

  // Function to refresh the page
  private readonly refreshPage = (): void => {
    window.location.reload();
  };

  // Function to go back to the previous route
  private readonly goBack = (): void => {
    window.history.back();
  };

  private readonly handleGoBack = (): void => {
    sessionStorage.setItem("lastPath", "/");
    window.location.reload();
  };

  public render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Box
          display="flex"
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          height={"100vh"}
        >
          <Box
            maxWidth="400px"
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <img src="/assets/robot.svg" width="150px" alt="page not found" />
            <Typography variant="h3">Unexpected error!</Typography>
            <Typography variant="body1" color="text.secondary" textAlign="center">
              Sorry, an unexpected error happened! Please refresh the page or try again later.
            </Typography>

            {this.state.error && (
              <div>
                <Typography variant="body1" color="error">
                  {this.state.error.message}
                </Typography>
              </div>
            )}
            <Box display="flex" gap={2} marginTop={2}>
              {/* Button to go back to the previous route */}
              <Button
                startIcon={<KeyboardReturn />}
                variant="contained"
                color="primary"
                onClick={this.handleGoBack}
              >
                Back
              </Button>

              {/* Button to refresh the page */}
              <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                Refresh Page
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}
