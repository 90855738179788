/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, List, Typography } from "@mui/material";
import { useState } from "react";
import { SettingsList } from "./setting-list";
import { DefaultPage } from "../core/pages/default-page";
import { T } from "../localisations/T";
import SettingListItem from "./common/components/SettingListItem";
import MappingSettingPage from "./CenterToProviderMapping";
import CategoryList from "./catagory";


const SettingsIndex = (): JSX.Element => {
  const [selectedSetting, setSelectedSetting] = useState<SettingsList>(SettingsList.CenterMapping);

  const handleClick = (key: string): void => {
    const index = key as keyof typeof SettingsList;
    setSelectedSetting(SettingsList[`${index}`]);
  };

  const renderSetting = () => {
    switch (selectedSetting) {
      case SettingsList.CenterMapping:
        return <MappingSettingPage/>;
        case SettingsList.BlogCategory:
          return <CategoryList />;
      default:
        return <MappingSettingPage />;
    }
  };

  return (
    <DefaultPage title={T("settings")}>
      <Box display={"flex"}>
        <Box sx={{ borderRight: "1px solid lightgrey", py: 2 }}>
          <Typography variant="h6" ml={3} mb={1}>
            {T("catagories")}
          </Typography>
          <List sx={{ width: 230 }}>
            {(Object.keys(SettingsList) as Array<keyof typeof SettingsList>).map((key) => {
              return (
                <SettingListItem
                  name={T(SettingsList[`${key}`])}
                  handleClick={() => handleClick(key)}
                  key={key}
                  active={selectedSetting === SettingsList[`${key}`]}
                />
              );
            })}
          </List>
        </Box>

        <Box sx={{ overflowX: "auto", width: "100%" }}>{renderSetting()}</Box>
      </Box>
    </DefaultPage>
  );
};

export default SettingsIndex;
