import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Grid,
  FormHelperText,
} from "@mui/material";
import { DefaultDialog } from "../core/dialogs";
import { T } from "../localisations/T";
import * as yup from "yup";
import { DistributionCenter } from "./distribution-center-list";
import { useFormik } from "formik";
import { FilePreview } from "../core/ui/file-preview/file-preview";
import { Delete, Visibility } from "@mui/icons-material";
import { FileInput } from "../core/ui/file-input/utils/file-input";
import {
  useCreateDistributionCentersMutation,
  useUpdateCenterMutation,
} from "./api/DistributionCentersQuery";

interface AddDistributionCenterProps {
  open: boolean;
  setModalOpen: (x: boolean) => void;
  distributionCenter?: DistributionCenter; // Optional, for editing case
}

type DistributionCenterFormValues = Omit<DistributionCenter, "id"> & {
  image: string | File | null;
};

function isFile(value: any): value is File {
  return value instanceof File;
}

function isImageFile(file: any): boolean {
  if (!file) return false;
  if (isFile(file)) {
    return file.type.startsWith("image/");
  }
  if (typeof file === "string") {
    return file.startsWith("image/");
  }
  return false;
}

function getFileType(file: File | string | null): string {
  if (!file) return "";
  if (isFile(file)) {
    return file.type;
  }
  const extension =
    typeof file === "string" ? file.split(".").pop()?.toLowerCase() : "";
  switch (extension) {
    case "pdf":
      return "application/pdf";
    case "doc":
    case "docx":
      return "application/msword";
    case "xls":
    case "xlsx":
      return "application/vnd.ms-excel";
    default:
      return "";
  }
}

const AddEditDistributionCenter: React.FC<AddDistributionCenterProps> = ({
  open,
  setModalOpen,
  distributionCenter,
}) => {
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .matches(/^[a-zA-Z\s]+$/, "Name should only contain letters and spaces"),
  
    status: yup.boolean().required("Status is required"),
  
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(
        /^(?:\+251[0-9]{9}|09[0-9]{8})$/,
        "Please enter a valid Ethiopian phone number (e.g., 09xxxxxxxx or +251xxxxxxxxx)"
      ),
  
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email format")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test(
        "no-consecutive-dots",
        "Email cannot contain consecutive dots",
        (value) => !value?.includes("..")
      )
      .test(
        "no-consecutive-at",
        "Email cannot contain consecutive @ symbols",
        (value) => !value?.includes("@@")
      )
      .min(5, "Email must be at least 5 characters")
      .max(255, "Email must not exceed 255 characters"),
  
    city: yup
      .string()
      .required("City is required")
      .matches(/^[a-zA-Z\s]+$/, "City should only contain letters and spaces"),
  
    subCity: yup
      .string()
      .required("Sub City is required")
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        "Sub City should only contain letters, numbers and spaces"
      ),
  
    woreda: yup
      .string()
      .required("Woreda is required")
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        "Woreda should only contain letters, numbers, and spaces"
      ),
  
    houseNumber: yup
      .string()
      .nullable()
      .matches(
        /^[a-zA-Z0-9\-\/]+$/,
        "House number can contain letters, numbers, hyphens and forward slashes"
      ),
  
    region: yup
      .string()
      .required("Region is required")
      .matches(/^[a-zA-Z\s]+$/, "Region should only contain letters and spaces"),
  
    zone: yup
      .string()
      .required()
      .matches(/^[a-zA-Z\s]*$/, "Zone should only contain letters and spaces"),
  
    image: yup
      .mixed()
      // .test("fileType", "Unsupported file format", function (value) {
      //   if (!value) return true; // Skip validation if no file is provided
      //   const file = value as File;
      //   const supportedFormats = [
      //     "image/jpeg",
      //     "image/jpg",
      //     "image/png",
      //     "image/gif",
      //     "application/pdf",
      //   ];
      //   return supportedFormats.includes(getFileType(file));
      // }),
  });
  
  
 
  // Initial form values
  const initialValues: DistributionCenterFormValues = {
    name: distributionCenter?.name || "",
    image: distributionCenter?.image ?? "",
    status: distributionCenter?.status ?? true,
    phoneNumber: distributionCenter?.phoneNumber || "",
    email: distributionCenter?.email || "",
    city: distributionCenter?.city || "",
    subCity: distributionCenter?.subCity || "",
    woreda: distributionCenter?.woreda || "",
    zone: distributionCenter?.zone || "",
    houseNumber: distributionCenter?.houseNumber || "",
    region: distributionCenter?.region || "",
  };
  const [createDistributionCenter, { isLoading }] =
    useCreateDistributionCentersMutation();

  const [updateDistributionCenter, { isLoading: isLoading1 }] =
    useUpdateCenterMutation();

  // Formik setup
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true, // Reinitialize when distributionCenter changes
    onSubmit: async (values) => {
      console.log("Form values on submit:", values.image);
      try {
        // Add image file if provided

        if (distributionCenter) {
          const payload = {
            id: distributionCenter?.id ?? "",
            ...values,
          };
          await updateDistributionCenter({
            body: payload,
          }).unwrap();
        } else {
          const payload = {
            ...values,
          };
          await createDistributionCenter({
            body: payload,
          }).unwrap();
        }

        setModalOpen(false); // Close the modal
      } catch (error) {}
    },
  });

  // Add effect to log image changes
  useEffect(() => {
    console.log("Image value changed:", formik.values.image);
  }, [formik.values.image]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>("");

  // Update preview URL when image changes
  useEffect(() => {
    if (isFile(formik.values.image)) {
      const url = URL.createObjectURL(formik.values.image);
      setPreviewUrl(url);
      return () => URL.revokeObjectURL(url); // Cleanup
    } else if (typeof formik.values.image === "string") {
      setPreviewUrl(formik.values.image);
    }
  }, [formik.values.image]);

  return (
    <DefaultDialog
      title={
        distributionCenter
          ? T("edit-distribution-center")
          : T("add-distribution-center")
      }
      open={open}
      minWidth={"60%"}
      
      onClose={() => setModalOpen(false)}
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box flexDirection={"column"} display={"flex"} gap={2}>
            <Box>
              <Typography variant="subtitle1">Name</Typography>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Phone</Typography>
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label="phoneNumber"
                type="text"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Email</Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Region</Typography>
              <TextField
                fullWidth
                id="region"
                name="region"
                label="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                error={formik.touched.region && Boolean(formik.errors.region)}
                helperText={formik.touched.region && formik.errors.region}
                margin="normal"
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">City</Typography>
              <TextField
                fullWidth
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">SubCity</Typography>
              <TextField
                fullWidth
                id="subCity"
                name="subCity"
                label="SubCity"
                value={formik.values.subCity}
                onChange={formik.handleChange}
                error={formik.touched.subCity && Boolean(formik.errors.subCity)}
                helperText={formik.touched.subCity && formik.errors.subCity}
                margin="normal"
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Zone</Typography>
              <TextField
                fullWidth
                id="zone"
                name="zone"
                label="Zone"
                value={formik.values.zone || ""}
                onChange={formik.handleChange}
                error={formik.touched.zone && Boolean(formik.errors.zone)}
                helperText={formik.touched.zone && formik.errors.zone}
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">Woreda</Typography>
              <TextField
                fullWidth
                id="woreda"
                name="woreda"
                label="Woreda"
                value={formik.values.woreda}
                onChange={formik.handleChange}
                error={formik.touched.woreda && Boolean(formik.errors.woreda)}
                helperText={formik.touched.woreda && formik.errors.woreda}
                margin="normal"
              />
            </Box>

            <Box>
              <Typography variant="subtitle1">House Number</Typography>
              <TextField
                fullWidth
                id="houseNumber"
                name="houseNumber"
                label="House Number"
                value={formik.values.houseNumber || ""}
                onChange={formik.handleChange}
                error={
                  formik.touched.houseNumber &&
                  Boolean(formik.errors.houseNumber)
                }
                helperText={
                  formik.touched.houseNumber && formik.errors.houseNumber
                }
                margin="normal"
              />
            </Box>

            <Box>
              <Box>
                <Typography variant="subtitle1">Active Status</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="status"
                      name="status"
                      checked={formik.values.status}
                      onChange={formik.handleChange}
                      color="primary"
                    />
                  }
                  label="Active Status"
                />
              </Box>

              <Box>
                <>
                  <Grid item xs={2} sx={{ py: 1, pb: -1, width: "100%" }}>
                    {formik.values.image ? (
                      <Box display="flex" alignItems="center" gap={2}>
                        <Typography variant="body2">
                          {isFile(formik.values.image)
                            ? formik.values.image.name.substring(0, 20) +
                              (formik.values.image.name.length > 20
                                ? "..."
                                : "")
                            : (
                                formik.values.image
                                  ?.split("/")
                                  .pop()
                                  ?.split("?")[0] || "File"
                              ).substring(0, 20) + "..."}
                        </Typography>
                        <IconButton
                          onClick={() => setPreviewOpen(true)}
                          color="primary"
                        >
                          <Visibility />
                        </IconButton>
                        <IconButton
                          onClick={() => formik.setFieldValue("image", null)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    ) : (
                      <FileInput
                        accept={{
                          "image/*": [".jpeg", ".jpg", ".png", ".gif"],
                          "application/pdf": [".pdf"],
                          "application/msword": [".doc"],
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            [".docx"],
                          "application/vnd.ms-excel": [".xls"],
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            [".xlsx"],
                        }}
                        onChange={(files) => {
                          if (files && files.length > 0) {
                            const file = files[0];
                            console.log("Selected file:", file);
                            formik.setFieldValue("image", file);
                          }
                        }}
                        message="Upload file (Images, PDF, DOC, DOCX, XLS, XLSX)"
                      />
                    )}
                    <FormHelperText
                      error={
                        (formik.touched.image &&
                          Boolean(formik.errors.image)) ||
                        (!formik.values.image && formik.submitCount > 0)
                      }
                    >
                      {formik.touched.image && formik.errors.image
                        ? formik.errors.image
                        : !formik.values.image && formik.submitCount > 0
                        ? T("add-image")
                        : null}
                    </FormHelperText>
                  </Grid>
                </>
              </Box>
            </Box>

            <Box padding={2}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
              >
                {T(distributionCenter ? "update" : "submit")}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>

      <DefaultDialog
        title="File Preview"
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        minWidth="50%"
      >
        <Box sx={{ p: 2, textAlign: "center" }}>
          {formik.values.image && (
            <>
              {isImageFile(formik.values.image) ? (
                <img
                  src={
                    typeof formik.values.image === "string"
                      ? formik.values.image
                      : URL.createObjectURL(formik.values.image as File)
                  }
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "500px" }}
                />
              ) : (
                <iframe
                  src={
                    typeof formik.values.image === "string"
                      ? formik.values.image
                      : URL.createObjectURL(formik.values.image as File)
                  }
                  width="100%"
                  height="500px"
                  style={{ border: "none" }}
                  title="File Preview"
                />
              )}
            </>
          )}
        </Box>
      </DefaultDialog>
    </DefaultDialog>
  );
};

export default AddEditDistributionCenter;
