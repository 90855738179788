/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Cancel, Circle, Close, UploadFile } from "@mui/icons-material";
import {
  Box,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FileStatus } from "../model/File";
import { computeFileSize } from "../utils/compute-file-size";
import { getCurrentSession, useAuth } from "../../../../auth";
import { appEndpoint } from "../../../../../store/app.endpoint";
/* Component props */
interface FileValueProps {
  index: number;
  file: any;
  removeFile: (index: number) => void;
  status: FileStatus;
  onUpload: (fileId: string, index: number) => void;
  allImage?: boolean;
}

const FileValue = (props: FileValueProps): JSX.Element => {
  /* Component state */

  const [status, setStatus] = useState<FileStatus>(props?.status);
  const [imageData, setImageData] = useState<string | null>(null);
  /* hooks */
  const { session } = useAuth();

  /* Event handler */
  const uploadFile = useCallback(async () => {
    try {
      const formData = new FormData();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formData.append("file", props.file);
      const token = getCurrentSession()?.accessToken;
      const response = await axios.post(
        appEndpoint.postFile,
        formData,

        {
          headers: {
            "Accept-Language": localStorage.getItem("local"), // Add the Accept-Language header here.
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      
      props?.onUpload(response?.data.data.id, props?.index);
      setStatus("uploaded");

      // setFiles(
      //   files?.map((item: FileType, fileIndex: number) => {
      //     if (index === fileIndex) {
      //       return {
      //         ...item,
      //         status: "uploaded",
      //         id: response.data[0].fileId,
      //       };
      //     }
      //     return item;
      //   })
      // );
    } catch (error: any) {
      setStatus("failed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.file, session]);

  /* useEffect hooks */
  useEffect(() => {
    setStatus("uploading");
  }, [props.status]);

  useEffect(() => {
    if (status === "uploading") {
      void uploadFile();
    }
  }, [status, uploadFile]);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageData(reader.result as string);
    };
    reader.readAsDataURL(props.file);
  }, [props.file]);

  return (
    <div key={props.file.path}>
      {props.allImage ? (
        <Box sx={{ position: "relative", width: "100px", height: "100px" }}>
          <Tooltip title={"Remove file"}>
            <IconButton
              aria-label="delete"
              onClick={() => props?.removeFile(props?.index)}
              sx={{ position: "absolute", top: -15, right: -15 }}
            >
              <Cancel
                color="error"
                sx={{ fontSize: 30, bgcolor: "white", borderRadius: 100 }}
              />
            </IconButton>
          </Tooltip>
          {imageData && (
            <img
              src={imageData}
              alt="Image"
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", borderRadius: "15px" }}
            />
          )}
        </Box>
      ) : (
        <ListItem
          secondaryAction={
            <div>
              {/* {file.status === "pending" && (
                <Tooltip title={"Upload file"}>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => uploadFile(index)}
                  >
                    <Upload />
                  </IconButton>
                </Tooltip>
              )} */}

              <Tooltip title={"Remove file"}>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => props?.removeFile(props?.index)}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          }
        >
          <div style={{ marginRight: "0.5rem" }}>
            <UploadFile
              style={{
                padding: "5px",
              }}
              className={"bg-lighter-blue rounded-full"}
              color={"primary"}
            />
          </div>

          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body2"
                fontWeight="500"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "anywhere",
                }}
              >
                {props.file.name}
              </Typography>
            }
            secondary={
              <Typography
                variant="body2"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "anywhere",

                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                {computeFileSize(props.file.size)}
                <Circle style={{ fontSize: "5px" }} />
                {status === "uploading" && <span>Uploading</span>}
                {status === "uploaded" && <span>Uploaded</span>}
              </Typography>
            }
          />
        </ListItem>
      )}

      {status === "uploading" && <LinearProgress />}
      {status === "failed" && (
        <Typography variant="body2" color={"error.light"}>
          Something went wrong while uploading file.
        </Typography>
      )}
    </div>
  );
};

export default React.memo(FileValue);
