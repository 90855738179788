import React, { useState } from "react";
import { Box, Typography, Grid, Button, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOrderDetailIdQuery } from "./api/ProvidersApi";
import BarcodeScanner from "./BarcodeScanning";
import { T } from "../localisations/T";
import { LoadingIndicator } from "../core";
import { QrCode, QrCodeScannerOutlined } from "@mui/icons-material";
import { Pads } from "./Pads";
import { MRT_ColumnDef } from "material-react-table";
import { DataTable } from "../core/components/data-table";

const OrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const { data, error, isLoading } = useGetOrderDetailIdQuery({
    id: id ?? "",
  });

  if (isLoading!) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <LoadingIndicator/>
      </Box>
    );
  }
  const columns: Array<MRT_ColumnDef<Pads>> = [
    {
      header: T("serial-number"),
      accessorKey: "serialNumber",
      Cell: ({ row: { original } }) => (
        <Typography>
          {original.serialNumber??"Not Assigned"}
        </Typography>
      ),
      size: 15,
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {T("order-detail")}
      </Typography>

      {/* Details Section */}
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          p: 2,
          backgroundColor: "#fff",
        }}
      >
        <Grid container spacing={2}>
          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("Order-No")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.trackingNumber}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("date")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.createdAt}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("Pad Type")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.type}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("status")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.orderStatus}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        </Grid>
      </Box>

      <Box my={3}>
        <DataTable<Pads>
          list={data?.data?.pads ?? []}
          count={data?.data?.pads?.length ?? 0}
          columns={columns}
          getRowId={(row) => row.id}
          isLoading={isLoading}
          isSuccess={!!data}
          isError={!!error}
          isFetching={false}
          renderRowActions={(row) => (
            <Box display={"flex"} gap={1}>
              <QrCodeScannerOutlined />
            </Box>
          )}
          onRowClick={(row) => navigate(`/pad-detail/${row.id}`)}
        />
      </Box>
    </Box>
  );
};

export default OrderDetail;
