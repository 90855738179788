import {
    MRT_ColumnDef,
    MRT_PaginationState,
    MRT_RowSelectionState,
  } from "material-react-table";
  import { useEffect, useMemo, useState } from "react";

  import {
    Autocomplete,
    Box,
    Button,
    TextField,
    Typography,
  } from "@mui/material";
  

  import { useNavigate } from "react-router-dom";
  

import { DataTable } from "../components/data-table";
import { useAuth } from "../../auth";
import { T } from "../../localisations/T";
import { useGetBatchByCenterQuery, useGetBatchToBeRecivedByCenterQuery, useReceiveBatchesMutation } from "./api/DistributionCentersApi.api";
import { ConfirmModal } from "../ui/confirm-modal/confirm-modal";
  
  export interface Order {
    paymentStatus: string;
    padProvider: any;
    quantity: number;
    type: string;
    date: string;
    trackingNumber: string;
    id: string;
    totalPad: number;
    name: string;
    status: string;
    maximumBatchCapacity: number;
  }
  export enum OrderStatus {
    Pending = "Pending",
    Confirmed = "Confirmed",
    Cancelled = "Cancelled",
    Processing = "Processing",
    Matched = "Matched",
    Delivered = "Delivered",
  }
  const CenetrBatchList: React.FC = () => {
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [receive, { isLoading: isLoading1 }] =  useReceiveBatchesMutation()
    
    const { session } = useAuth();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  
   
  
    useEffect(() => {}, []);

    const statusOptions: Array<string> = Object.values(OrderStatus);
   
  
    const menuItems: Array<{ id: string; value: number; label: string }> =
      useMemo(
        () => [
          {
            id: "last_week",
            value: -7,
            label: T("last-week"),
          },
          {
            id: "last_month",
            value: -30,
            label: T("last-30-days"),
          },
          {
            id: "last_6_months",
            value: -180,
            label: T("last-6-mounths"),
          },
          {
            id: "last_year",
            value: -365,
            label: T("last-year"),
          },
        ],
        []
      );
  
    const columns: Array<MRT_ColumnDef<Order>> = [
      {
        header: T("name"),
        accessorKey: "name",
        size: 15,
      },
      {
        header: T("pad-type"),
        accessorKey: "type",
        size: 15,
      },
      {
        header: T("status"),
        accessorKey: "status",
        size: 15,
      },
      {
        header: T("payment-status"),
        accessorKey: "paymentStatus",
        size: 15,
      },
    ];
    const [openDialog1, setOpenDialog1] = useState(false);
    const { data, error, isLoading } =   useGetBatchToBeRecivedByCenterQuery({
      id: session?.userInfo?.centerId??""
    })
  
  ;
    return (
      <Box>
        <DataTable<Order>
          toolbar={
            <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Button
                onClick={() => {
                  setOpenDialog1(true);
                }}
                disabled={
                  Object.keys(rowSelection).length===0
                }
                sx={{ minWidth: "200px" }}
                variant="contained"
              >
                {T("receive")}
              </Button>
            </Box>

            {/* <Box gap={3} display={"flex"} flexDirection={"row"}>
              <Autocomplete
                options={statusOptions}
                getOptionLabel={(option) => T(option)} // Translate the status options
                onChange={(event, value) => setSelectedStatus(value || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={T("status")}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                options={menuItems}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={T("date")}
                    variant="outlined"
                    fullWidth
                    //   sx={{ width: props.width }}
                  />
                )}
              />
            </Box> */}
          </Box>
          }
          list={data?.data ?? []}
          count={data?.data?.length ?? 0}
          columns={columns}
          getRowId={(row) => row.id}
          isLoading={isLoading}
          isSuccess={!!data}
          isError={!!error}
          isFetching={false}
          rowSelection={rowSelection}
          onRowSelectionChange={setRowSelection}
          enableRowSelection={(row) => 

            true
          }
          onRowClick={(row) => navigate(`/batch-detail/${row.id}`)}
         
          pagination={pagination}
          onPaginationChange={setPagination}
        />
     <ConfirmModal
        type="info"
        title="Are you sure you want to receive this batches for shipping"
        message=""
        modalOpen={openDialog1}
        setModalOpen={setOpenDialog1}
        onOk={() => {
          receive({
            body: {
              batchIds: Object.keys(rowSelection),
            },
          });
        }}
      ></ConfirmModal>
      
      </Box>
    );
  };
  
  export default CenetrBatchList;
  