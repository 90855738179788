import { Box } from "@mui/material";
import EtDatePicker from "mui-ethiopian-datepicker";
import React, { SetStateAction } from "react";
import { T } from "../../../../localisations/T";

interface YearRangeProps {
  startDate: Date,
  setStartDate: React.Dispatch<SetStateAction<Date>>,
  endDate: Date,
  setEndDate: React.Dispatch<SetStateAction<Date>>
}
const YearRangeFilterToolbarAction = ({startDate, endDate, setStartDate, setEndDate}: YearRangeProps): JSX.Element => {
  return (
    <Box display={"flex"} gap={2} alignItems={"center"}>
<EtDatePicker
            label={T('from')}
                value={startDate as any}
                onChange={(newValue:any) => {
                  setStartDate(newValue);
                }}
              />
    <EtDatePicker
        label={T('to')}
        value={endDate as any}
        onChange={(newValue:any) => {
          setEndDate(newValue);
        }}
    />
    </Box>
  );
};

export default YearRangeFilterToolbarAction;
