/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Autocomplete, Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { YupSchema } from "../core/utilities/validation/yup-schema";
import { T } from "../localisations/T";
import { DefaultDialog } from "../core/dialogs";
import { useAddMappingMutation, useGetAllPadProviderApiQuery } from "../provider/api/ProviderQuery";
import { LoadingButton } from "@mui/lab";
import { useGetAllDistributionCentersApiQuery } from "../distribution-center/api/DistributionCentersQuery";
interface SettingFormProp {
  openModal: boolean;
  setOpenModal: (flag: boolean) => void;
}

const AddMappingForm= ({
  openModal,
  setOpenModal,
}: SettingFormProp): JSX.Element => {

    const [addMapping, { isLoading: isCreating }] = useAddMappingMutation();


  const onSubmit = async (data:any) => {
   addMapping({body:data})
    setOpenModal(false);
  };
  const validationSchema = yup.object().shape({
    distributionCenterIds: yup.array().min(1).required(T("please-select-centers")),
    padProviderId: yup.string().required(T("please-select-provider")),
  });
  const formik = useFormik({
    initialValues: {
      padProviderId: "",
      
      distributionCenterIds: [],
    },
    validationSchema,
    onSubmit,
  });

  const { data:providers, error, isLoading } = useGetAllPadProviderApiQuery();
  const { data:centers, error:error1, isLoading:isLoading1 } = useGetAllDistributionCentersApiQuery();

 


  return (
    <DefaultDialog
      title={T("add-mapping")}
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <form onSubmit={formik.handleSubmit}>
       
        <Box display="flex" flexDirection="column" gap={2} p={2}>
        <Autocomplete
            options={providers?.data??[]}
            size="medium"
            autoHighlight
            disabled={isLoading || isLoading1}
            filterSelectedOptions
            getOptionLabel={(option: any) => `${option.name}`}
            sx={{ minWidth: "200px" }}
            fullWidth
            includeInputInList
            onChange={async (e: any, value: any) => {
              if (value !== null) {
                await formik.setFieldValue("padProviderId", value.id);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="padProviderId"
                name="padProviderId"
                label={T("provider")}
                onBlur={formik.handleBlur}
                error={formik.touched.padProviderId && Boolean(formik.errors.padProviderId)}
                helperText={formik.touched.padProviderId && formik.errors.padProviderId}
                
                fullWidth
              />
            )}
          />
          <Autocomplete
            options={centers?.data??[]}
            size="medium"
            autoHighlight
            multiple
            disabled={isLoading || isLoading1}
            filterSelectedOptions
            getOptionLabel={(option) => `${option.name}`}
            sx={{ minWidth: "200px" }}
            fullWidth
            includeInputInList
          
          
            onChange={async (e: any, value: any) => {
              const values = value.map((val: any) => val.id); // Map the selected values to their IDs
              await formik.setFieldValue("distributionCenterIds", values);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="distributionCenterIds"
                name="distributionCenterIds"
                label={T("centers")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.distributionCenterIds && Boolean(formik.errors.distributionCenterIds)}
                helperText={formik.touched.distributionCenterIds && formik.errors.distributionCenterIds}
                fullWidth
              />
            )}
          />

          
          
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            {T("add-mapping")}
          </LoadingButton>
        </Box>
      </form>
    </DefaultDialog>
  );
};

export default AddMappingForm;
