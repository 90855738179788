import React from "react";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Card from "../core/components/cards/card";
import OrderList from "./recentOrders";
import { useGetProviderSummeryQuery } from "./api/ProvidersApi";
import { useAuth } from "../auth";
import { LoadingIndicator } from "../core";
import { Box } from "@mui/material";

const Home = () => {
  const handleCardClick = (title: string) => {
    console.log(`${title} card clicked!`);
  };
  const { session } = useAuth();

  const { data, error, isLoading } = useGetProviderSummeryQuery({
    id: session?.userInfo?.centerId ?? "",
  });

  if (isLoading!) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <LoadingIndicator/>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "16px",
          gap: "16px",
        }}
      >
        <Card
          title="NewOrder"
          count={data?.data?.newOrder}
          icon={<ShoppingCartIcon style={{ fontSize: "25px" }} />}
          onClick={() => handleCardClick("New Order")}
        />
        <Card
          title="Unassigned"
          count={data?.data?.processingCount}
          icon={<ShoppingCartIcon style={{ fontSize: "25px" }} />}
          onClick={() => handleCardClick("Unassigned")}
        />

        <Card
          title="Shipped"
          count={data?.data?.deliveredCount}
          icon={<ShoppingCartIcon style={{ fontSize: "25px" }} />}
          onClick={() => handleCardClick("Shipped")}
        />
        <Card
          title="Delivered"
          count={data?.data?.deliveredCount}
          icon={<ShoppingCartIcon style={{ fontSize: "25px" }} />}
          onClick={() => handleCardClick("Shipped")}
        />
      </div>

      <OrderList centerId="" />
    </>
  );
};

export default Home;
