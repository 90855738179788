import React from "react";
import { Box, List, ListItem, ListItemText, Button, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";

const Account = () => {
    const navigate = useNavigate();
    const { session, logOut } = useAuth();
    const handleLogOut = (): void => {
      // polling.stopPolling();
    //  void axios.delete(`${appEndpoint.logout}?access_token= ${session?.accessToken as string}` );
      logOut();
    };
  return (


    <Box sx={{ width: "100%", margin: "auto", padding: 2 }}>
      {/* Header */}
      <Box
        sx={{
          height: 50,
          backgroundColor: "grey.300",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Account</Typography>
      </Box>

      {/* Menu */}
      <List>
        <ListItem onClick={()=>    navigate("/payment")}  button>
          <ListItemText primary="Payments" />
        </ListItem>
        <Divider />
        <ListItem onClick={()=>    navigate("/users")}  button>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem onClick={()=>    navigate("/batch")}  button>
          <ListItemText primary="Batch" />
        </ListItem>
      </List>

      {/* Logout Button */}
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <Button onClick={handleLogOut} variant="text" color="error">
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Account;
