import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../libs/core/axios-base-query";


export const appApi = createApi({
  reducerPath: "appApi",
  
  baseQuery: axiosBaseQuery({ baseUrl: "https://api.pad.enkubeauty.com" }),
  // baseQuery: axiosBaseQuery({ baseUrl: "http://196.188.249.25:5005" }),
  tagTypes: [
    "user",
    "DistributionCenters",
    "PadProviders",
    "Receiver",
    "Donor",
    "Donation",
    "Payment",
    "Pad",
    "PadDetail",
    "Scan",
    "Orders",
    "Mapping",
    "Batches",
    "Category",
    "Blogs",
    
  ],
  endpoints: () => ({}),
});
