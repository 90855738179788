import React, { useState } from "react";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { Box, Chip, Typography } from "@mui/material";
import { DataTable } from "../core/components/data-table";
import { T } from "../localisations/T";
import { useGetPaymentByProviderIdQuery } from "./api/ProviderQuery";
import { useNavigate } from "react-router-dom";

export interface Payment {
  id: string;
  orderId: string;
  paymentInfo: string;
  amount: string;
  totalPad: number;
  paymentStatus: string;
  paymentReferenceNumber: string;
}
interface UserProps {
  centerId: string;
}
const PaymentTable: React.FC<UserProps> = ({ centerId }) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Fetch data using a query (replace with your actual API hook)
  const { data, error, isLoading } = useGetPaymentByProviderIdQuery({
    id: centerId,
  });
  const navigate = useNavigate();
  // Define table columns
  const columns: Array<MRT_ColumnDef<Payment>> = [
    {
      header: T("order-id"),
      accessorKey: "paymentReferenceNumber",
      size: 100,
    },
    {
      header: T("Amount"),
      accessorKey: "amount",
      size: 100,
      Cell: ({ row: { original } }) => (
        <Typography>${parseFloat(original.amount).toFixed(2)}</Typography>
      ),
    },
    {
      header: T("Quantity"),
      accessorKey: "totalPad",
      size: 100,
      Cell: ({ row: { original } }) => (
        <Typography>{original.totalPad}</Typography>
      ),
    },
    {
        header: T("status"),
        accessorKey: "paymentStatus",
        size: 100,
        
      },
  ];

  return (
    <Box p={3}>
      <DataTable<Payment>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("Payments")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`${data?.length ?? 0} ${T("Payments")}`}
              />
            </Box>
          </Box>
        }
        list={data?.data ?? []} // Replace with actual data
        count={data?.data?.length ?? 0}
        columns={columns}
        getRowId={(row) => row.orderId}
        onRowClick={(row) => navigate(`/payment-detail/${row.id}`)}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </Box>
  );
};

export default PaymentTable;
