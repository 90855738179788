

import React, { useState } from "react";
import { MRT_ColumnDef, MRT_PaginationState, MRT_RowSelectionState } from "material-react-table";
import { Box, Button, Chip, Typography } from "@mui/material";
import { DataTable } from "../core/components/data-table";
import { T } from "../localisations/T";
import {
  useGetPaymentByProviderIdQuery,
  useGetPendingPaymentByProviderIdQuery,
  usePayForProviderMutation,
} from "./api/ProviderQuery";
import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";

export interface Payment {
 id: string;
  name: string;
  paymentStatus: string;
  paymentAmount: string;
  totalPad: number;
}
interface UserProps {
  centerId: string;
}
const PendingPaymentTable: React.FC<UserProps> = ({ centerId }) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Fetch data using a query (replace with your actual API hook)
  const { data, error, isLoading } = useGetPendingPaymentByProviderIdQuery({
    id: centerId,
  });

  const [openDialog1, setOpenDialog1] = useState(false);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});




  const [pay, { isLoading: isLoading1 }] = usePayForProviderMutation();


  // Define table columns
  const columns: Array<MRT_ColumnDef<Payment>> = [
    {
      header: T("batch-name"),
      accessorKey: "name",
      size: 100,
    },
    {
      header: T("quantity"),
      accessorKey: "totalPad",
      size: 150,
    },
    {
      header: T("Amount"),
      accessorKey: "paymentAmount",
      size: 100,
      Cell: ({ row: { original } }) => (
        <Typography>
          ${parseFloat(original.paymentAmount).toFixed(2)}
        </Typography>
      ),
    },
    {
      header: T("status"),
      accessorKey: "paymentStatus",
      size: 100,
    },
  ];

  return (
    <Box p={3}>
      <DataTable<Payment>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
           <Button onClick={()=>{  setOpenDialog1(true);}}  sx={{minWidth:"200px"}} variant="contained" >{T('pay')}</Button>
            </Box>
          </Box>
        }
        list={data?.data ?? []} // Replace with actual data
        count={data?.data?.length ?? 0}
        columns={columns}
        rowSelection={rowSelection}
        onRowSelectionChange={setRowSelection}
        enableRowSelection={(row) =>
        true
        }
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        pagination={pagination}
        onPaginationChange={setPagination}
      />


<ConfirmModal
            type="info"
            title="Are you sure you want to generate Invoice for this batches"
            message=""
            modalOpen={openDialog1}
            setModalOpen={setOpenDialog1}
            onOk={() => {
              pay({
                body: {
                    batchIds:  Object.keys(rowSelection),
                },
              });
            }}
          ></ConfirmModal>
    </Box>
  );
};

export default PendingPaymentTable;
