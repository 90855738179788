import React from "react";
import {
  Route,
  Routes,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { LoginPage, useAuth } from "../libs/auth";
import Shell from "../libs/shell";
import { logOut } from "../libs/auth/store/auth.slice";
import { useEffect } from "react";
import { useAppDispatch } from "../store/hooks/app-store.hook";
import { NotFoundPage } from "../libs/core";
import Dashboard from "../libs/auth/pages/dashboard";
import DistributionCenterList from "../libs/distribution-center/distribution-center-list";
import DistributionCenterDetail from "../libs/distribution-center/distribution-center-detail";
import { Provider } from "react-redux";
import ProviderList from "../libs/provider/provider-list";
import ProviderDetail from "../libs/provider/provider-detail";
import ReceiversList from "../libs/recivers/ReceiversList";
import ReceiverDetail from "../libs/recivers/reciverDetail";
import DonorList from "../libs/doner/donerlist";
import DonationList from "../libs/donation/donotionList";
import AllPaymentTable from "../libs/paymet/payment";
import MobileMenu from "../libs/providers/providersMenu";
import Home from "../libs/providers/Home";
import AllOrderList from "../libs/providers/Pads";
import BarcodeScanner from "../libs/providers/BarcodeScanning";
import OrderDetail from "../libs/providers/orderDetail";
import DonorDetail from "../libs/doner/donor-detail";
import Feedback from "../libs/feedback/feedback";
import BlogList from "../libs/blog/blog-list";
import CreateBlog from "../libs/blog/create-blog";
import Account from "../libs/providers/account";
import AllPaymentForProvider from "../libs/providers/PaymentsForProvider";
import ProviderUserList from "../libs/providers/users";
import BatchList from "../libs/providers/batchlist";
import BatchDetail from "../libs/providers/batchdetail";
import PadDetail from "../libs/providers/PadDetail";

import ReceiverListByProvider from "../libs/core/distribution/receiver-list";
import ProvidersLayout from "../libs/providers/ProvidersLayout";
import DistLayout from "../libs/core/distribution/Layout";
import SettingsIndex from "../libs/CenterToProviderSetting/setting";
import AdminBatchList from "../libs/distribution-center/bathes";
import AdminBatchesDetail from "../libs/distribution-center/batchesdetails";
import PaymentDetail from "../libs/provider/paymentdetail";
import CenterHome from "../libs/core/distribution/Home";
import CenterReceiverDetail from "../libs/core/distribution/reciverDetail";
import CenetrBatchList from "../libs/core/distribution/centerBatchesList";
import CenterUserList from "../libs/core/distribution/users";
import AllCenetrBatchList from "../libs/core/distribution/centerAllBatchList";
import CenterAccount from "../libs/core/distribution/account";
import CenterBatchDetail from "../libs/core/distribution/batchDetails";

export const Routing = (): JSX.Element => {
  /* hooks */
  const { session } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const localSession = localStorage.getItem("session");

  useEffect(() => {
    if (!session || !localSession) {
      dispatch(logOut());
      navigate("/login");
    } else if (session && location.pathname === "/login") {
      navigate("/");
    }
  }, [session, location.pathname, navigate, dispatch, localSession]);

  return (
    <>
      {/* <ScrollRestoration /> */}
      <Routes>
        {session === null && <Route path="/login" element={<LoginPage />} />}

        {session !== null && session?.userInfo.type === "CENTRAL_ADMIN" && (
          <Route path="/" element={<>{<Shell />}</>}>
            <Route index element={<Dashboard></Dashboard>} />
            <Route
              path="distribution-center"
              element={<DistributionCenterList></DistributionCenterList>}
            />
            <Route path="batch" element={<AdminBatchList></AdminBatchList>} />
            <Route path="receiver" element={<ReceiversList />} />
            <Route path="receiver-detail/:id" element={<ReceiverDetail />} />
            <Route
              path="distribution-center-detail/:id"
              element={<DistributionCenterDetail></DistributionCenterDetail>}
            />
             <Route
              path="payment-detail/:id"
              element={< PaymentDetail/>}
            />
            <Route
              path="batch-detail/:id"
              element={<AdminBatchesDetail></AdminBatchesDetail>}
            />


            <Route path="provider" element={<ProviderList></ProviderList>} />
            <Route path="donar" element={<DonorList />} />
            <Route path="donor-detail/:id" element={<DonorDetail />} />
            <Route path="provider-detail/:id" element={<ProviderDetail />} />
            <Route path="donations" element={<DonationList />} />
            <Route path="payment" element={<AllPaymentTable />} />
            <Route path="setting" element={<SettingsIndex />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="blog-list" element={<BlogList />} />
            <Route path="create-blog" element={<CreateBlog />} />
          </Route>
        )}

        {session !== null && session?.userInfo.type === "PROVIDER" && (
          <Route path="/" element={<>{<ProvidersLayout />}</>}>
            <Route index element={<Home />} />
            <Route path="donations" element={<DonationList />} />
            <Route path="orders" element={<AllOrderList />} />
            <Route path="profile" element={<Account />} />
            <Route path="order-detail/:id" element={<OrderDetail />} />
            <Route path="pad-detail/:id" element={<PadDetail />} />
            <Route path="payment" element={<AllPaymentForProvider />} />
            <Route path="users" element={<ProviderUserList />} />
            <Route path="batch" element={<BatchList />} />
            <Route path="batch-detail/:id" element={<BatchDetail />} />
          </Route>
        )}

        {session !== null && session?.userInfo.type === "DISTRIBUTION" && (
          <Route path="/" element={<>{<DistLayout />}</>}>
            <Route index element={<CenterHome />} />
            <Route path="receiver" element={<ReceiverListByProvider />} />
            <Route path="profile" element={<CenterAccount />} />
            <Route path="donations" element={<DonationList />} />
            <Route path="orders" element={<AllOrderList />} />
            <Route path="batch" element={<AllCenetrBatchList />} />
            <Route path="users" element={<CenterUserList />} />
            <Route path="batch-detail/:id" element={< CenterBatchDetail/>} />
            <Route path="receiver-detail/:id" element={<CenterReceiverDetail />} />
            <Route path="order-detail/:id" element={<OrderDetail />} />
          </Route>
        )}

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
