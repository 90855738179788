/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { Circle } from "@mui/icons-material";
import { T } from "../localisations/T";
import SettingsTitle from "./common/components/SettingsTitle";
import { DataTable } from "../core/components/data-table";
import AddMappingForm from "./addMapping";
import { useGetAllMappingQuery } from "../provider/api/ProviderQuery";

export interface Mapping {
    distributionCenters: any;
  id: string;
  name: string;
}

const MappingSettingPage = (): JSX.Element => {
  const { data, error, isLoading } = useGetAllMappingQuery();
  const [openStoreSettingModal, setOpenStoreSettingModal] =
    useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  const [tableData, setTableData] = useState<Mapping[]>([]);

  const columns = useMemo<Array<MRT_ColumnDef<Mapping>>>(
    () => [
      {
        header: T("name"),
        accessorKey: "name",
      },

      {
        header: T("distributionCenters"),
        accessorKey: "distributionCenters",
        Cell({
          row: {
            original: { distributionCenters},
          },
        }): JSX.Element {
          if (distributionCenters?.length! > 3) {
            return (
              <Tooltip
                title={distributionCenters?.map(
                  (center: any) => `${center.name}\n`
                )}
              >
                <Box display="flex" gap={1} alignItems="center">
                  <Chip
                    key={distributionCenters![0].name}
                    label={distributionCenters?.name}
                  />
                  <Chip
                    key={distributionCenters![1].name}
                    label={distributionCenters?.name}
                  />
                  <Chip
                    key={distributionCenters![2].name}
                    label={distributionCenters?.name}
                  />
                  <Typography variant="caption">{`+ ${
                    Permissions.length - 3
                  }`}</Typography>
                </Box>
              </Tooltip>
            );
          } else if (distributionCenters?.length !== 0) {
            return (
              <Box display="flex" gap={1} alignItems="center">
                {distributionCenters?.map((center: any) => (
                  <Chip key={center.name} label={center.name} />
                ))}
              </Box>
            );
          } else {
            return <></>;
          }
        },
      },
    ],
    []
  );

  return (
    <>
      <SettingsTitle
        prm="FIN_STORE"
        title={T("distribution-center-to-store-mapping")}
        actionTitle={T("add-setting")}
        onClick={() => {
          setOpenStoreSettingModal(true);
        }}
      >
        <DataTable<Mapping>
          variant="outlined"
          columns={columns}
          list={data?.data}
          count={data?.data.length}
          getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
          renderRowActions={(store) => {
            return (
              <Box>
                {/* <StoreActionsMenu reload={reload} setReload={setReload} store={store} /> */}
              </Box>
            );
          }}
        />
      </SettingsTitle>
      {openStoreSettingModal && (
        <AddMappingForm
          openModal={openStoreSettingModal}
          setOpenModal={setOpenStoreSettingModal}
        />
      )}
    </>
  );
};

export default MappingSettingPage;
