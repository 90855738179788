import { appApi } from '../../../store/app.api';
import { appEndpoint } from '../../../store/app.endpoint'; 

interface Blog {
  id: string;
  title: string;
  content: string;
  status: string;
  createdOn: string;
}
const blogApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBlogs: builder.query<Blog[], void>({
      query: () => ({ url: appEndpoint.getBlogs, method: 'GET' }),
      providesTags: ['Blogs'],
    }),
    getBlogById: builder.query<Blog, string>({
      query: (id) => ({ url: `${appEndpoint.getBlogs}/${id}`, method: 'GET' }),
    }),
    createBlog: builder.mutation<Blog, FormData>({
      query: (formData) => ({
        url: appEndpoint.createBlog,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Blogs'],
    }),
    updateBlog: builder.mutation<Blog, Blog>({
      query: (updatedBlog) => ({
        url: `${appEndpoint.getBlogs}/${updatedBlog.id}`,
        method: 'PUT',
        body: updatedBlog,
        providesTags: ['Blogs'],
      }),
    }),
    deleteBlog: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `${appEndpoint.getBlogs}/${id}`,
        method: 'DELETE',
        providesTags: ['Blogs'],
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBlogsQuery,
  useGetBlogByIdQuery,
  useCreateBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
} = blogApi;
