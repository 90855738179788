import { appApi } from "../../../store/app.api";
import { appEndpoint } from "../../../store/app.endpoint";
import { CommandRequest } from "../../core/utilities/command-request";


// Define the API endpoints
const PadProvidersApi = appApi.injectEndpoints({
  endpoints: (build) => (
    
    {
    // Fetch all distribution centers
    getAllPadProviderApi: build.query<any, void>({
      query: () => ({
        url: appEndpoint.getPadProviders,
        method: "get",
      }),
      providesTags: ["PadProviders"], // Tag for cache management
    }),

    getAllCategory: build.query<any, void>({
      query: () => ({
        url: appEndpoint.getCategory,
        method: "get",
      }),
      providesTags: ["Category"], // Tag for cache management
    }),


    getAllMapping: build.query<any, void>({
      query: () => ({
        url: appEndpoint.getMapping,
        method: "get",
      }),
      providesTags: ["Mapping"], // Tag for cache management
    }),

    // Fetch a specific distribution center by ID
    getPadProvidersDetail: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getPadProviderDetails}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["PadProviders"], // Tag for cache management
    }),

    getUsersByProviderId: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getUsersByProviderId}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["user"], // Tag for cache management
    }),
    getPaymentByProviderId: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint. getPaymentOfProvider}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Payment"], // Tag for cache management
    }),
    getPendingPaymentByProviderId: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getPendingPaymentByProviderId}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Payment"], // Tag for cache management
    }),

    getPendingPaymentDetail: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getPendingPaymentDetail}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Payment"], // Tag for cache management
    }),

    
    // Create a new distribution center
    createPadProviders: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.addPadProviders,
        method: "post",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["PadProviders"] : []),
    }),
  payForProvider: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint. payPadProviders,
        method: "post",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["PadProviders","Payment"] : []),
      
    }),
    AddCategory: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint. addCategory,
        method: "post",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Category"] : []),
      
    }),
    updatePadProviders: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.updatePadProviders,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["PadProviders"] : []),
    }),
    removePadProviders: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.updatePadProviders,
        method: "delete",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["PadProviders"] : []),
    }),
    deactivatePadProviders: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.deactivateProvider,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["PadProviders"] : []),
    }),

    addMapping: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.mapCenters,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Mapping"] : []),
    }),
    assignBatch: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.assignBatch,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Mapping","PadProviders","Payment"] : []),
    }),
    finalizePayment: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.finalizePayment,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Mapping","PadProviders","Payment"] : []),
    }),
  }),

  overrideExisting: false, // Ensures this endpoint doesn't override others
});

// Export hooks for querying
export const {
  useGetAllPadProviderApiQuery,
  useLazyGetPadProvidersDetailQuery,
  useGetPadProvidersDetailQuery,
  useGetUsersByProviderIdQuery,
  useCreatePadProvidersMutation,
  useGetPaymentByProviderIdQuery,
  useGetPendingPaymentByProviderIdQuery,
  useDeactivatePadProvidersMutation,
  useUpdatePadProvidersMutation,
  useRemovePadProvidersMutation,
  usePayForProviderMutation,
  useAddMappingMutation,
  useGetAllMappingQuery,
  useAssignBatchMutation,
  useGetPendingPaymentDetailQuery,
  useFinalizePaymentMutation,useGetAllCategoryQuery,useAddCategoryMutation

} = PadProvidersApi;
