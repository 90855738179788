/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ArrowBack } from "@mui/icons-material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, Grid, Typography, Button, Select, SelectProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import IsPermitted from "../../../../auth/components/IsPermitted";

export type DefaultPageHeaderProps = {
  title?: string;
  subTitle?: React.ReactNode | string;
  primaryButtonProps?: DefaultPageHeaderButtonProps;
  outlinedButtonProps?: DefaultPageHeaderButtonProps;
  secondaryButtonProps?: DefaultPageHeaderButtonProps;
  secondarySelectProps?: DefaultPageHeaderSelectProps;
  backButtonLink?: string | any;
  backButtonTitle?: string;
  dropdownSelector?: React.ReactNode;
  showWarningColor?: boolean;
  showTotalEmployees?: boolean;
  total?: number;
  rightSideComponent?: React.ReactElement;
};

type DefaultPageHeaderButtonProps = PropsWithChildren<
  Omit<LoadingButtonProps, "size" | "variant">
> & {
  requiredPermissions?: string[];
};
type DefaultPageHeaderSelectProps = PropsWithChildren<SelectProps>;

export const DefaultPageHeader = (props: DefaultPageHeaderProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box mx={1} mb={2}>
      {props.backButtonLink && props.backButtonTitle && (
        <Button
          startIcon={<ArrowBack />}
          sx={{
            textTransform: "capitalize",
            fontWeight: "semibold",
            fontSize: 16,
            // backgroundColor: "#F4F4F4",
          }}
          color="inherit"
          size="small"
          onClick={() => {
            navigate(props.backButtonLink);
          }}
        >
          {props.backButtonTitle}
        </Button>
      )}

      <Grid
        container
        direction="row"
        justifyContent={"space-between"}
        flexDirection="row"
        alignItems="center"
        spacing={2}
        wrap="nowrap"
      >
        {(props.title ?? props.subTitle) && (
          <Grid item>
            <Typography variant="h4" sx={{ fontWeight: 500, fontSize: "35px" }}>
              {props.title}
            </Typography>
            {typeof props?.subTitle === "string" ? (
              <Typography variant="subtitle1">{props?.subTitle}</Typography>
            ) : (
              props?.subTitle
            )}
          </Grid>
        )}
        <Grid item container direction="row" xs={"auto"} spacing={1}>
          {props.dropdownSelector && <Grid item>{props.dropdownSelector}</Grid>}
          {props.secondarySelectProps && (
            <Grid item>
              <Select
                style={{ textTransform: "none" }}
                variant="outlined"
                sx={{ minWidth: "20rem" }}
                {...props.secondarySelectProps}
              >
                {props.secondarySelectProps.children}
              </Select>
            </Grid>
          )}
          {props.primaryButtonProps && (
            <Grid item>
              {props.primaryButtonProps.requiredPermissions ? (
                <IsPermitted requiredPermissions={props.primaryButtonProps.requiredPermissions}>
                  <LoadingButton
                    style={{ textTransform: "none" }}
                    size="large"
                    variant="contained"
                    {...props.primaryButtonProps}
                  >
                    {props.primaryButtonProps.children}
                  </LoadingButton>
                </IsPermitted>
              ) : (
                <LoadingButton
                  style={{ textTransform: "none" }}
                  size="large"
                  variant="contained"
                  {...props.primaryButtonProps}
                >
                  {props.primaryButtonProps.children}
                </LoadingButton>
              )}
            </Grid>
          )}
          {props.outlinedButtonProps && (
            <Grid item>
              {props.outlinedButtonProps.requiredPermissions ? (
                <IsPermitted requiredPermissions={props.outlinedButtonProps.requiredPermissions}>
                  <LoadingButton
                    style={{ textTransform: "none" }}
                    size="large"
                    variant="outlined"
                    {...props.outlinedButtonProps}
                  >
                    {props.outlinedButtonProps.children}
                  </LoadingButton>
                </IsPermitted>
              ) : (
                <LoadingButton
                  style={{ textTransform: "none" }}
                  size="large"
                  variant="outlined"
                  {...props.outlinedButtonProps}
                >
                  {props.outlinedButtonProps.children}
                </LoadingButton>
              )}
            </Grid>
          )}
         

          {props.rightSideComponent && <Grid item>{props.rightSideComponent}</Grid>}

          {props.secondaryButtonProps && (
            <Grid item>
              {props.secondaryButtonProps.requiredPermissions ? (
                <IsPermitted requiredPermissions={props.secondaryButtonProps.requiredPermissions}>
                  <LoadingButton
                    style={{ textTransform: "none" }}
                    size="large"
                    variant="outlined"
                    color={props.showWarningColor ? "warning" : "inherit"}
                    {...props.secondaryButtonProps}
                  >
                    {props.secondaryButtonProps.children}
                  </LoadingButton>
                </IsPermitted>
              ) : (
                <LoadingButton
                  style={{ textTransform: "none" }}
                  size="large"
                  variant="outlined"
                  color={props.showWarningColor ? "warning" : "inherit"}
                  {...props.secondaryButtonProps}
                >
                  {props.secondaryButtonProps.children}
                </LoadingButton>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
