import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import StatusButton from "../core/ui/status/status";
import { useNavigate } from "react-router-dom";
import { useGetPadsQuery, useGetRecentOrdersQuery } from "./api/ProvidersApi";
import {
  AdfScanner,
  QrCodeScanner,
  QrCodeScannerOutlined,
  Scanner,
} from "@mui/icons-material";
import { useAuth } from "../auth";

export interface Pads {
  padStatus: string;
  quantity: number;
  type: string;
  date: string;
  serialNumber: string;
  id: string;
  createdAt: string
}
export enum padStatus {
  Pending = "Pending",
  Confirmed = "Confirmed",
  Cancelled = "Cancelled",
  Processing = "Processing",
  Matched = "Matched",
  Delivered = "Delivered",
}
const AllOrderList: React.FC = () => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [order, setOrders] = useState<Pads[]>([]);

  const { data, error, isLoading } =  useGetPadsQuery({
    id: session?.userInfo?.centerId ?? "",
  });

  useEffect(() => {}, []);

  const [Order, setOrder] = useState<Pads| null>(null);
  const statusOptions: Array<string> = Object.values(padStatus);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const menuItems: Array<{ id: string; value: number; label: string }> =
    useMemo(
      () => [
        {
          id: "last_week",
          value: -7,
          label: T("last-week"),
        },
        {
          id: "last_month",
          value: -30,
          label: T("last-30-days"),
        },
        {
          id: "last_6_months",
          value: -180,
          label: T("last-6-mounths"),
        },
        {
          id: "last_year",
          value: -365,
          label: T("last-year"),
        },
      ],
      []
    );

  const columns: Array<MRT_ColumnDef<Pads>> = [
    {
      header: T("serial-number"),
      accessorKey: "serialNumber",
      size: 15,
    },
  
    // {
    //   header: T("pad-type"),
    //   accessorKey: "type",
    //   size: 15,
    // },
    {
      header: T("status"),
      accessorKey: "padStatus",
      size: 15,
    },
    {
      header: T("date"),
      accessorKey: "createdAt",
      size: 15,
    },
  ];

  return (
    <Box sx={{ pb: 5, px: 1 }}>
      <DataTable<Pads>
        toolbar={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Autocomplete
              options={statusOptions}
              getOptionLabel={(option) => T(option)} // Translate the status options
              onChange={(event, value) => setSelectedStatus(value || null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={T("status")}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Autocomplete
              options={menuItems}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={T("date")}
                  variant="outlined"
                  fullWidth
                  //   sx={{ width: props.width }}
                />
              )}
            />
          </Box>
        }
        list={data?.data ?? []}
        count={data?.data?.length ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        renderRowActions={(row) => (
          <Box display={"flex"} gap={1}>
            <QrCodeScannerOutlined />
          </Box>
        )}
        onRowClick={(row) => navigate(`/pad-detail/${row.id}`)}
        // onRowClick={(row) => navigate(`/distribution-center-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </Box>
  );
};

export default AllOrderList;
