import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import { Box, Button, Typography } from "@mui/material";

import StatusButton from "../core/ui/status/status";
import { useNavigate } from "react-router-dom";
import {
  useGetRecentOrdersQuery,
  useLazyGetRecentOrdersQuery,
} from "./api/ProvidersApi";
import { QrCodeScannerOutlined, Scanner } from "@mui/icons-material";
import { useAuth } from "../auth";

export interface Order {
  orderStatus: string;
  quantity: number;
  type: string;
  date: string;
  createdAt: string;
  trackingNumber: string;
  id: string;
}

interface OrderProps {
  centerId: string;
}

const OrderList: React.FC<OrderProps> = ({ centerId }) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [order, setOrders] = useState<Order[]>([]);
  const { session } = useAuth();

  const [getGetRecentOrders, { data, error, isLoading }] =
    useLazyGetRecentOrdersQuery();

  //   const =  useGetRecentOrdersQuery({id:session?.userInfo?.centerId??"1"});

  useEffect(() => {
    if (session) {
      getGetRecentOrders({ id: session?.userInfo?.centerId ?? "" });
    }
  }, [session]);

  const [Order, setOrder] = useState<Order | null>(null);

  const columns: Array<MRT_ColumnDef<Order>> = [
    {
      header: T("tracking-id"),
      accessorKey: "trackingNumber",
      size: 15,
    },
    {
      header: T("status"),
      accessorKey: "orderStatus",
      size: 15,
    },
    {
      header: T("date"),
      accessorKey: "createdAt",
      size: 15,
    },
    // {
    //   header: T("pad-type"),
    //   accessorKey: "type",
    //   size:15
    // },
  ];

  return (
    <Box sx={{ pb: 5, px: 1 }}>
      <DataTable<Order>
        toolbar={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography>{T("recent-order")}</Typography>
            <Button>{T("view-all")}</Button>
          </Box>
        }
        list={data?.data ?? []}
        count={data?.data?.length ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        onRowClick={(row) => navigate(`/order-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </Box>
  );
};

export default OrderList;
