import { Delete, UploadFile } from "@mui/icons-material";
import { Box, IconButton, List, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { T } from "../../../../localisations/T";
import FileValue from "../components/file-value";
import { FileType } from "../model/File";
import { FilePreview } from "../../file-preview/file-preview";

/* Component props */
type FileInputProps = {
  accept?: Record<string, string[]>;
  maxFiles?: number;
  message: string;
  validator?: (file: any) => { code: string; message: string } | null;
  onChange?: (value: string[]) => void;
  previousFiles?: string[];
  allImages?: boolean;
  maxSize?: { size: number; measurement: "KB" | "MB" };
  name?: string;
  resetAttachment?: boolean; // New prop to trigger reset
  disabled?: boolean;
};

export const FileInput = (props: FileInputProps): JSX.Element => {
  /* component state */
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [files, setFiles] = useState<FileType[]>([]);
  const [previouslyUploadedFiles, setPreviouslyUploadedFiles] = useState<string[]>(
    props.previousFiles ? props.previousFiles : [],
  );
  const mb = 1024 * 1024;
  const kb = 1024;

  /* hooks */
  const fileIds = useRef<Record<number, string>>({});

  const dropZoneOption: any = {};
  if (props?.accept) {
    dropZoneOption.accept = { ...props.accept };
  }
  if (props?.maxFiles) {
    dropZoneOption.maxFiles = props.maxFiles;
  }
  if (props?.validator) {
    dropZoneOption.validator = props.validator;
  }
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    ...dropZoneOption,
    maxSize: props.maxSize
      ? props.maxSize.measurement === "MB"
        ? props.maxSize.size * mb
        : props.maxSize.size * kb
      : 10 * mb,
    accept: props.accept ?? "",
    validator: (file) => {
      if (props.maxSize) {
        if (props.maxSize.measurement === "MB" && file.size > props.maxSize.size * mb) {
          const errorMessage = `${T("file-larger-than")} ${props.maxSize.size}${T("MB")}`;
          setErrorMessage(errorMessage);

          return {
            code: "file-too-large",
            message: errorMessage,
          };
        } else if (props.maxSize.measurement === "KB" && file.size > props.maxSize.size * kb) {
          const errorMessage = `${T("file-larger-than")} ${props.maxSize.size}${T("KB")}`;
          setErrorMessage(errorMessage);

          return {
            code: "file-too-large",
            message: errorMessage,
          };
        }
      } else if (file.size > 10 * mb) {
        const errorMessage = T("file-must-be-below-10MB");
        setErrorMessage(errorMessage);

        return {
          code: "file-too-large",
          message: errorMessage,
        };
      }
      setErrorMessage(null);

      return null;
    },
 
  });

  useEffect(() => {
    if (props.resetAttachment) {
      setFiles([]);
    }
  }, [props.resetAttachment]);

  /* Event handlers */
  const onUpload = (fileId: string, index: number): void => {
    fileIds.current = { ...fileIds.current, [index]: fileId };
    if (props?.onChange) {
      const filesId = [];
      const fileKeys = Object.keys(fileIds.current);
      for (let i = 0; i < fileKeys.length; i++) {
        filesId.push(fileIds.current?.[i]);
      }
      props?.onChange([...previouslyUploadedFiles, ...filesId]);
    }
  };

  const removeFile = (index: number): void => {
    const filteredFiles = files?.filter((itme: any, fileIndex: number) => index !== fileIndex);
    setFiles([...filteredFiles]);
    const fileIdKeys = Object.keys(fileIds.current);
    for (let i = 0; i < fileIdKeys.length; i++) {
      if (i < index) {
        fileIds.current[i] = fileIds.current?.[index];
      } else if (i >= index && i !== fileIdKeys.length - 1) {
        fileIds.current[i] = fileIds.current?.[i + 1];
      }
    }
    delete fileIds?.current?.[fileIdKeys.length - 1];

    if (props?.onChange) {
      const filesId = [];
      const fileKeys = Object.keys(fileIds.current);
      for (let i = 0; i < fileKeys.length; i++) {
        filesId.push(fileIds.current?.[i]);
      }
      props?.onChange([...previouslyUploadedFiles, ...filesId]);
    }
  };

  const removePreviouslyUploadedFile = (fileId: string): void => {
    setPreviouslyUploadedFiles(previouslyUploadedFiles?.filter((id: string) => id !== fileId));
  };

  /* useEffect hooks */
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const reformattedFiles: FileType[] = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        let fileExists: boolean = false;
        for (let j = 0; j < files.length; j++) {
          if (acceptedFiles[i].name === files[j].file.name) {
            fileExists = true;
            break;
          }
        }
        if (!fileExists) {
          reformattedFiles.push({
            file: acceptedFiles[i],
            status: "pending",
          });
        }
      }
      if (files.length !== props?.maxFiles) {
        setFiles([...files, ...reformattedFiles]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    if (props?.previousFiles && props?.previousFiles?.length > 0 && props?.onChange) {
      const filesId = [];
      const fileKeys = Object.keys(fileIds.current);
      for (let i = 0; i < fileKeys.length; i++) {
        filesId.push(fileIds.current?.[i]);
      }
      props?.onChange([...previouslyUploadedFiles, ...filesId]);
    }
  }, [previouslyUploadedFiles]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <section className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <div
            style={{ border: "1px dashed grey", borderRadius: "6px" }}
            className={"bg-secondary"}
          >
            <div
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <UploadFile
                  name={props.name ?? "upload-image"}
                  style={{
                    padding: "8px",
                    width: 40,
                    height: 40,
                  }}
                  className={"bg-lighter-blue rounded-full"}
                  color={"primary"}
                />
              </div>
              <Typography className="cursor-pointer" fontSize={"16px"} textAlign="center">
                {T("drag-and-drop-message")}
              </Typography>

              <Typography
                color={"gray"}
                variant="body2"
                fontSize={"14px"}
                className={"text-neutral"}
              >
                {props?.message}
              </Typography>
            </div>
            <input {...getInputProps()} />
          </div>
          <Typography color="red" variant="subtitle2" my={1}>
            {errorMessage}
          </Typography>
        </div>
        <div>
          {props.allImages ? (
            <Box display={"flex"} gap={2} my={2} flexWrap={"wrap"}>
              {files.map((fileItem: FileType, index: number) => (
                <div key={fileItem.file.name}>
                  <FileValue
                    index={index}
                    file={fileItem.file}
                    status={fileItem.status}
                    removeFile={removeFile}
                    onUpload={onUpload}
                    allImage={props.allImages}
                  />
                </div>
              ))}
            </Box>
          ) : (
            <List>
              {files.map((fileItem: FileType, index: number) => (
                <div key={fileItem.file.name}>
                  <FileValue
                    index={index}
                    file={fileItem.file}
                    status={fileItem.status}
                    removeFile={removeFile}
                    onUpload={onUpload}
                  />
                </div>
              ))}
            </List>
          )}
        </div>
      </section>

      {previouslyUploadedFiles.length > 0 && (
        <Box sx={{ my: 2 }}>
          <Typography>{T("previously-uploaded-files")}</Typography>
          {!props.allImages
            ? previouslyUploadedFiles?.map((item: string) => (
                <Box sx={{ display: "flex" }} key={item}>
                  <FilePreview fileId={item} key={item} hideDownload={true} />
                  <IconButton
                    color={"error"}
                    aria-label="delete"
                    onClick={() => removePreviouslyUploadedFile(item)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              ))
            : previouslyUploadedFiles?.map((item: string) => (
                <Box sx={{ display: "flex" }} key={item}>
                  <FilePreview fileId={item} key={item} hideDownload={true} />
                  <IconButton
                    color={"error"}
                    aria-label="delete"
                    onClick={() => removePreviouslyUploadedFile(item)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              ))}
        </Box>
      )}
    </div>
  );
};