import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { T } from "../localisations/T";
import { useParams } from "react-router-dom";
import { useGetReceiverByIdQuery } from "./api/ReciverQuery";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { LoadingIndicator } from "../core";
import PadTable from "./padTable";

import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";
import {
  useUpdateReceiverMutation,
  useCreateReceiverMutation,
} from "../../libs/distribution-center/api/DistributionCentersQuery";
import Avatar from "../core/components/avatar";
import AddEditReceiver from "../distribution-center/add-reciver";
import { FilePreview } from "../core/ui/file-preview/file-preview";
const ReceiverDetail: React.FC = () => {
  const { id } = useParams();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditDialog1, setOpenEditDialog1] = useState(false);
  const { data, error, isLoading } = useGetReceiverByIdQuery({ id: id ?? "" });

  console.log("Receiver data:", data?.data);

  const documents = [
    { name: "Birth Certificate", size: "604" },
    { name: "Student ID Card", size: "929" },
    { name: "Letter", size: "929" },
  ]; // Assuming `documents` is part of the API response.
  const [updateReciever, { isLoading: isLoading1 }] =
    useUpdateReceiverMutation();
  const calculateAge = (birthDate: string) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }
    return age;
  };

  if (isLoading) {
    return (
      <>
        <LoadingIndicator></LoadingIndicator>
      </>
    );
  }

  return (
    <Box display={"flex"} flexDirection={"column"} gap={3}>
      <Paper sx={{ padding: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={2}
        >
          <Typography variant="h6">
            {data?.data?.firstName ?? ""}{" "}
            {data?.data?.lastName ?? T("not-available")}
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            gap={1}
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Button onClick={() => setOpenEditDialog1(true)} variant="outlined">
              {data?.data?.status ? T("deactivate") : T("activate")}
            </Button>
            <Button onClick={() => setOpenEditDialog(true)} variant="contained" disabled={!data?.data?.status}>
              {T("edit")}
            </Button>
          </Box>
        </Box>

        <Box
          my={2}
          display="flex"
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
          alignItems="center"
          gap={3}
        >
          <Avatar
            imageId={data?.data?.photo ?? null}
            editMode={false}
            sx={{
              width: 100,
              height: 100,
              alignSelf: {
                xs: "center",
                sm: "flex-start",
              },
            }}
            variant="circular"
          />

          <Box>
            <Box my={2} display="flex" flexWrap="wrap" gap={2}>
              <Stack>
                <Typography>{T("phone-number")}</Typography>
                <Typography>
                  {data?.data?.phoneNumber ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("email")}</Typography>
                <Typography>
                  {data?.data?.email ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("age")}</Typography>
                <Typography>
                  {data?.data?.dateOfBirth
                    ? `${calculateAge(data.data.dateOfBirth)} ${T("years")}`
                    : T("not-available")}
                </Typography>
              </Stack>
            </Box>

            <Divider />

            <Box my={2} display="flex" flexWrap="wrap" gap={3}>
              <Stack>
                <Typography>{T("region")}</Typography>
                <Typography>
                  {data?.data?.region ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("zone")}</Typography>
                <Typography>
                  {data?.data?.zone ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("city")}</Typography>
                <Typography>
                  {data?.data?.city ?? T("not-available")}
                </Typography>
              </Stack>
              <Stack>
                <Typography>{T("sub-city")}</Typography>
                <Typography>
                  {data?.data?.subCity ?? T("not-available")}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box mt={3}>
          <Typography variant="h5">{T("documents")}</Typography>
          {documents.length > 0 ? (
            <List>
              <ListItem
                secondaryAction={
                  <IconButton edge="end">
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <InsertDriveFileIcon style={{ marginRight: 16 }} />
                <ListItemText
                  primary={"identificationCard"}
                  secondary={
                    <FilePreview
                      fileDisplay="fileId"
                      fileId={data?.data?.identificationCard}
                    />
                  }
                />
                <ListItem
                  secondaryAction={
                    <IconButton edge="end">
                      <MoreVertIcon />
                    </IconButton>
                  }
                >
                  <InsertDriveFileIcon style={{ marginRight: 16 }} />
                  <ListItemText
                    primary={"supporting-document"}
                    secondary={
                      <FilePreview
                        fileDisplay="fileId"
                        fileId={data?.data?.supportDocument}
                      />
                    }
                  />
                </ListItem>
              </ListItem>
            </List>
          ) : (
            <Typography>{T("no-documents-found")}</Typography>
          )}
        </Box>
        <Box>
          <PadTable receiverId={id ?? ""} />
        </Box>
      </Paper>
      <AddEditReceiver
        open={openEditDialog}
        receiver={data?.data}
        centerId={data?.data.distributionCenterId ?? ""}
        setModalOpen={setOpenEditDialog}
      />
      <ConfirmModal
        type="warning"
        title={`Are you sure you want to ${
          data?.data?.status ? "deactivate" : "activate"
        } this receiver?`}
        message={`Are you sure you want to ${
          data?.data?.status ? "deactivate" : "activate"
        } this receiver?`}
        modalOpen={openEditDialog1}
        setModalOpen={setOpenEditDialog1}
        onOk={() => {
          updateReciever({
            body: {
              id: data?.data.id ?? "",
              status: !data?.data?.status,
            },
          }).unwrap();
        }}
      ></ConfirmModal>
    </Box>
  );
};

export default ReceiverDetail;
