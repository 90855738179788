import React from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  MenuItem,
  FormHelperText,
  Grid,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import * as yup from "yup";
import { useFormik } from "formik";
import { useCreateUsersMutation, useGetRoleQuery, useUpdateUserMutation } from "../../distribution-center/api/DistributionCentersQuery";
import { DefaultDialog } from "../dialogs";
import { T } from "../../localisations/T";
import { FileInput } from "../ui/file-input/utils/file-input";
import { MRT_RowSelectionState } from "material-react-table";

interface AddUserProps {
  open: boolean;
  setModalOpen: (x: boolean) => void;
  user?: User;
  centerId: string;
}

interface Role {
  id: string;
  name: string;
}

interface User {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: boolean;
  photo: string;
  sex: string;
  role: {
    id: string;
    name: string;
  };
  distributionCenterId: string;
  userType: "CENTRAL_ADMIN" | "DISTRIBUTION" | "PROVIDER";
}

type UserFormValues = Omit<User, "id">;

const AddEditUser: React.FC<AddUserProps> = ({
  open,
  setModalOpen,
  user,
  centerId,
}) => {
  const { data, error, isLoading } = useGetRoleQuery();
  const [createUser, { isLoading: isCreating }] = useCreateUsersMutation();
  const [updateUser, { isLoading: isUpdting }] = useUpdateUserMutation();
  // Validation schema
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .min(2, "First name must be at least 2 characters")
      .matches(/^[a-zA-Z\s]+$/, "First name should only contain letters and spaces")
      .max(50, "First name must not exceed 50 characters"),

    lastName: yup
      .string()
      .required("Last name is required")
      .min(2, "Last name must be at least 2 characters")
      .matches(/^[a-zA-Z\s]+$/, "Last name should only contain letters and spaces")
      .max(50, "Last name must not exceed 50 characters"),

    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email format")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test("no-consecutive-dots", "Email cannot contain consecutive dots", 
        value => !value?.includes('..'))
      .test("no-consecutive-at", "Email cannot contain consecutive @ symbols", 
        value => !value?.includes('@@'))
      .min(5, "Email must be at least 5 characters")
      .max(255, "Email must not exceed 255 characters"),

    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(
        /^(?:\+251[0-9]{9}|09[0-9]{8})$/,
        "Please enter a valid Ethiopian phone number (e.g., 09xxxxxxxx or +251xxxxxxxxx)"
      ),

    status: yup
      .boolean()
      .required("Active status is required"),

    photo: yup
      .mixed()
      .required("Photo is required")
     
    ,

    sex: yup
      .string()
      .required("Sex is required")
      .oneOf(["Male", "Female"], "Please select either Male or Female"),

    role: yup.object().shape({
      id: yup
        .string()
        .required("Role selection is required"),
      name: yup
        .string()
        .required("Role name is required")
    }),

    distributionCenterId: yup
      .string()
      .required("Distribution Center is required"),

    userType: yup
      .string()
      .required("User type is required")
      .oneOf(
        ["CENTRAL_ADMIN", "DISTRIBUTION", "PROVIDER"],
        "Please select a valid user type"
      ),
  });


  // Initial values
  const initialValues: UserFormValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    photo: user?.photo || "",
    sex: user?.sex || "Female",
    role: { id: user?.role?.id || "", name: user?.role?.name || "" },
    distributionCenterId: centerId,
    userType: user?.userType || "DISTRIBUTION",
    status: user?.status || true,
  };

  // Formik setup
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: UserFormValues) => {
      // Transform the values to match API expectations
      const submitData = {
        ...values,
        role: values.role.id  // Send only the role ID
      };

      if (user) {
        updateUser({"body":{
          id: user.id ?? "",
          ...submitData
        }});
      } else {
        createUser({ body: submitData });
        console.log("Creating user:", submitData);
      }
      setModalOpen(false);
    },
  });

  return (
    <DefaultDialog
    title={user ? T("edit-user") : T("add-user")}
    open={open}
    minWidth={"50%"}
    onClose={() => setModalOpen(false)}
  >
    <form style={{display:'flex'  , flexDirection:"column",gap:"20px"}}  onSubmit={formik.handleSubmit}>
    
        <TextField
          fullWidth
          label="First Name"
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={
            formik.touched.firstName && Boolean(formik.errors.firstName)
          }
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          fullWidth
          label="Last Name"
          id="lastName"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          fullWidth
          select
          label="Sex"
          id="sex"
          name="sex"
          value={formik.values.sex}
          onChange={formik.handleChange}
          error={formik.touched.sex && Boolean(formik.errors.sex)}
          helperText={formik.touched.sex && formik.errors.sex}
        >
          {["Male", "Female"].map((option) => (
            <MenuItem key={option} value={option}>
              {option.charAt(0).toUpperCase() + option.slice(1)}{" "}
              {/* Capitalize */}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          fullWidth
          label="Email"
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          label="Phone Number"
          id="phoneNumber"
          name="phoneNumber"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
          }
          helperText={
            formik.touched.phoneNumber && formik.errors.phoneNumber
          }
        />
        <Autocomplete
          id="role"
          options={data?.data || []}
          getOptionLabel={(option: Role) => option.name}
          value={
            data?.data?.find(
              (role: Role) => role.id === formik.values.role.id
            ) || null
          }
          onChange={(event, value) =>
            formik.setFieldValue("role", { id: value?.id || "", name: value?.name || "" })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Role"
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={formik.touched.role && (formik.errors.role as string)}
            />
          )}
        />

        <FormControlLabel
          control={
            <Checkbox
              id="status"
              name="status"
              checked={formik.values.status}
              onChange={formik.handleChange}
            />
          }
          label="Active Status"
        />
        <Box>
          <>
            <Grid item xs={2} sx={{ py: 1, pb: -1, width: "100%" }}>
              <FileInput
                message={"PNG, JPG or PDF (max. 3MB)"}
                accept={{
                  "image/*": [".png", ".jpeg", ".jpg"],
                  "application/pdf": [".pdf"],
                }}
                maxFiles={1}
                onChange={(val) => {
                  formik.setFieldValue("photo", val[0]);
                }}
                maxSize={{ measurement: "MB", size: 3 }}
              />
              <FormHelperText
                error={
                  (formik.touched.photo && Boolean(formik.errors.photo)) ||
                  (!formik.values.photo && formik.submitCount > 0)
                }
              >
                {formik.touched.photo && formik.errors.photo
                  ? formik.errors.photo
                  : !formik.values.photo && formik.submitCount > 0
                  ? T("add-image")
                  : null}
              </FormHelperText>
            </Grid>
          </>
        </Box>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          disabled={isCreating}
        >
          {T(user ? "update" : "submit")}
        </Button>
    
    </form>
  </DefaultDialog>
  );
};

export default AddEditUser;
