import React from "react";
import { Paper, Typography, Box } from "@mui/material";

interface CardProps {
  title: string;
  count: number;
  icon: React.ReactNode; // Pass any icon component
  backgroundColor?: string; // Optional to customize the background
  onClick?: () => void; // Optional onClick event handler
}

const Card: React.FC<CardProps> = ({
  title,
  count,
  icon,
  backgroundColor = "#0CCC70", // Default green background
  onClick,
}) => {
  return (
    <Paper
      sx={{
        backgroundColor,
        color: "#fff",
        padding: "16px",
        

        borderRadius: "8px",
        textAlign: "center",
        cursor: onClick ? "pointer" : "default",
        transition: "0.3s ease",
        "&:hover": {
          transform: onClick ? "scale(1.05)" : "none",
        },
      }}
      elevation={3}
      onClick={onClick}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {icon}
        <Typography   sx={{ fontSize: "10px", marginTop: "8px",  }}>
          {title}
        </Typography>
        <Typography  sx={{ fontSize: "10px",  }}>
          {count}
        </Typography>
      </Box>
    </Paper>
  );
};

export default Card;
