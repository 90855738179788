// import { sentenceCase } from 'change-case-all';
import { useTranslation } from 'react-i18next';

const useTranslate = ():any => {
  const { i18n } = useTranslation();

  const translate = (key: string):string => {
    const currentLanguage = i18n.language;
    if (currentLanguage && i18n?.options?.resources?.[currentLanguage]) {
      const translations: any = i18n.options.resources[currentLanguage];
      if (key in translations) {
        return translations[key];
      }
    }
    // if the translation is not available for the current language, return the key itself in the sentence case format
    // help to remove '-' from the key. e.g. 'user-name' => 'User Name'
    // if (key) {
    //   return sentenceCase(key);
    // }

    // If the translation is not available for the current language, return the key itself
    return key;
  };

  return translate;
};

export default useTranslate;
