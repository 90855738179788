
import { Outlet } from "react-router-dom";
import MobileMenu from "./providersMenu";

const ProvidersLayout = () => {
    return (
      <>
        <MobileMenu />
        <Outlet /> {/* Render the nested routes here */}
      </>
    );
  };

export default  ProvidersLayout;
