import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style/global.css'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/app-store';
import { I18nextProvider } from "react-i18next";
import i18n from './libs/localisations/i18n';
import { TranslationProvider } from './libs/localisations/Translate';
import { GlobalThemeProvider } from "./libs/core/theme/GloabalThemeProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AxiosWrapper, ErrorBoundary } from "./libs/core";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
     <TranslationProvider>
            <Provider store={store}>
              <GlobalThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <AxiosWrapper>
                    <I18nextProvider i18n={i18n}>
                      <App />
                    </I18nextProvider>
                  </AxiosWrapper>{" "}
                </LocalizationProvider>
              </GlobalThemeProvider>
            </Provider>
          </TranslationProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
