import React, { useState, useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Box, Typography, Button, Alert } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { T } from "../../localisations/T";
import ImageCapture from "./image-capturing";
import { DefaultDialog } from "../dialogs";
import axios from "axios";
import { appEndpoint } from "../../../store/app.endpoint";
import { getCurrentSession } from "../../auth";
import { useGivePadProvidersMutation } from "./api/DistributionCentersApi.api";

interface Props {
  open: boolean;
  setModalOpen: (x: boolean) => void;
  receiver?: string;
}

const DonatePadToReceiver: React.FC<Props> = ({
  open,
  setModalOpen,
  receiver,
}) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [cameraAccess, setCameraAccess] = useState<boolean | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [imageId, setImageId] = useState<string | null>(null);
  const navigate = useNavigate();

  const requestPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraAccess(true);
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      console.error("Camera permission denied or unavailable:", err);
      setCameraAccess(false);
    }
  };

  useEffect(() => {
    requestPermissions();
  }, []);

  const convertToBinary = (capturedImage: string) => {
    return new Promise<Blob>((resolve, reject) => {
      const imageFile = dataURItoBlob(capturedImage); // Convert base64 string to Blob
      resolve(imageFile);
    });
  };

  const dataURItoBlob = (dataURI: string): Blob => {
    const byteString = atob(dataURI.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer]);
  };

  const uploadFile = async (capturedImage: string): Promise<void> => {
    try {
      setUploading(true);

      // Convert image to binary (Blob)
      const imageBinary = await convertToBinary(capturedImage);
      const token = getCurrentSession()?.accessToken;
      const formData = new FormData();
      formData.append("file", imageBinary, "captured-image.jpg"); // You can choose a file name

      const response = await axios.post(
        appEndpoint.postFile,
        formData,

        {
          headers: {
            "Accept-Language": localStorage.getItem("local"), // Add the Accept-Language header here.
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      ); // Replace with actual API endpoint

      if (response?.data.data.id) {
        setImageId(response?.data.data.id);
        setUploading(false);
      } else {
        throw new Error("Failed to upload image.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploading(false);
      setError("Failed to upload image.");
    }
  };

  const [donate, { isLoading }] = useGivePadProvidersMutation();
  const handleSubmit = async () => {
   try {
     donate({
       body: {
         realTimeImage: imageId,
         savedImageId: receiver,
       },
     }).unwrap();
   } catch (error) {
    
   }

    setModalOpen(false)
  };

  const StepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Typography variant="body1">Step 1: Scan the barcode.</Typography>
            {!data ? (
              <BarcodeScannerComponent
                delay={20}
                facingMode="environment"
                onUpdate={(err, result) => {
                  if (err) {
                    setError("Error scanning barcode.");
                    console.error(err);
                  } else if (result) {
                    setError(null);
                    setData(result.getText());
                  }
                }}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius={2}
                p={3}
                width="fit-content"
                bgcolor="#FFFFFF"
                gap={1}
              >
                <Typography variant="body1" color="textPrimary">
                  {data}
                </Typography>
                <CheckCircleIcon sx={{ color: "#27C96A" }} />
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => setData("")}
                >
                  Rescan
                </Button>
              </Box>
            )}
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="body1">Step 2: Capture an image.</Typography>
            {!image ? (
              <ImageCapture
                onCapture={(capturedImage) => setImage(capturedImage)}
              />
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
              >
                <img
                  src={image}
                  alt="Captured"
                  style={{
                    maxWidth: "100%",
                    borderRadius: 8,
                    border: "1px solid #ccc",
                  }}
                />
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => setImage(null)}
                >
                  Retake
                </Button>
              </Box>
            )}
          </>
        );
      case 3:
        return (
          <Box textAlign="center">
            <Typography variant="body1" color="textPrimary">
              Review your data before submission:
            </Typography>
            <Box mt={2}>
              <Typography variant="body2">Barcode: {data}</Typography>
              <Typography variant="body2">
                Image Uploaded: {imageId ? "Yes" : "No"}
              </Typography>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const handleNext = async () => {
    if (step === 2 && image) {
      await uploadFile(image); // Upload image before going to the last step
    }
    setStep((prev) => prev + 1);
  };

  return (
    <DefaultDialog
      title={receiver ? T("edit-receiver") : T("add-receiver")}
      open={open}
      minWidth={"50%"}
      onClose={() => setModalOpen(false)}
    >
      <Box
        py={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
      >
        {cameraAccess === null && (
          <Typography variant="body1">
            Requesting camera permissions...
          </Typography>
        )}

        {cameraAccess === false && (
          <Box textAlign="center">
            <Typography color="error" variant="body2">
              Camera access is denied. Please allow camera permissions in your
              browser settings.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={requestPermissions}
            >
              Retry Permission Request
            </Button>
          </Box>
        )}

        {cameraAccess === true && <StepContent />}

        {error && (
          <Alert severity="warning" variant="outlined">
            {error}
          </Alert>
        )}

        <Box display="flex" gap={2}>
          {step > 1 && (
            <Button
              variant="contained"
              onClick={() => setStep((prev) => prev - 1)}
            >
              Back
            </Button>
          )}
          {step < 3 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={(step === 1 && !data) || (step === 2 && !image)}
            >
              Next
            </Button>
          )}
          {step === 3 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!data || !imageId}
            >
              {T("submit")}
            </Button>
          )}
        </Box>
      </Box>
    </DefaultDialog>
  );
};

export default DonatePadToReceiver;
