import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Paper,
} from '@mui/material';
import {
  FormatBold,
  FormatItalic,
  FormatQuote,
  FormatListBulleted,
  Image,
  Undo,
  Redo,
} from '@mui/icons-material';
import { useGetAllCategoryQuery } from '../provider/api/ProviderQuery';
import { useCreateBlogMutation } from './api/blogQuery';

// Define a type for category, adjust fields as necessary
type Category = {
  id: string;
  name: string;
};

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [bannerImage, setBannerImage] = useState<File | null>(null);
  const [blogImages, setBlogImages] = useState<File[]>([]);
  const [createBlog, { isLoading, isSuccess, isError, error }] = useCreateBlogMutation();
  const editorRef = useRef<HTMLDivElement>(null);
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const multipleImagesInputRef = useRef<HTMLInputElement>(null);
  const [selectedBlogType, setSelectedBlogType] = useState('');

  const { data: categoryData, isLoading: isCategoryLoading, error: categoryError } = useGetAllCategoryQuery();

  const editorStyles = {
    padding: '16px',
    minHeight: '150px',
    position: 'relative',
  } as const;

  // Text formatting functions
  const handleFormat = (command: string, value: string | undefined = undefined) => {
    if (editorRef.current) {
      editorRef.current.focus();
      document.execCommand(command, false, value);
      const newContent = editorRef.current.innerHTML;
      setContent(newContent);
    }
  };

  // Image upload handlers
  const handleBannerUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setBannerImage(file);
      // Preview the image
      const reader = new FileReader();
      reader.onload = (e) => {
        const preview = document.getElementById('bannerPreview') as HTMLImageElement;
        if (preview && e.target) {
          preview.src = (e.target as FileReader).result as string;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMultipleImagesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    setBlogImages(prev => [...prev, ...files]);
  };

  // Drag and drop handlers
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleBannerDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setBannerImage(file);
    }
  };

  const handleMultipleImagesDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setBlogImages(prev => [...prev, ...files]);
  };

  useEffect(() => {
    if (editorRef.current && !editorRef.current.innerHTML) {
      editorRef.current.innerHTML = '<p></p>';
    }
  }, []);

  // Function to handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validation
    if (!title || !content || !selectedBlogType) {
      alert("Please fill in all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('selectedBlogType', selectedBlogType);
    if (bannerImage) {
      formData.append('bannerImage', bannerImage);
    }
    blogImages.forEach((image) => {
      formData.append('blogImages', image);
    });

    try {
      const result = await createBlog(formData).unwrap(); // Use unwrap to handle errors
      console.log('Blog created successfully', result);
      alert("Blog created successfully!"); // Success feedback
    } catch (err) {
      console.error('Error submitting blog:', err); // Log the error
      alert("Error submitting blog. Please try again."); // Error feedback
    }

    if (isError) {
      console.error('Error:', error);
    }
  };

  return (
    <Box p={3}>
      {/* Header with Back Button */}
      <Box display="flex" alignItems="center" gap={1} mb={3}>
        <Button
          variant="text"
          color="primary"
          onClick={() => window.history.back()}
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          ← Back
        </Button>
        <Typography variant="h6">Create Blog</Typography>
      </Box>

      {/* Blog Form */}
      <Box component="form" display="flex" flexDirection="column" gap={3} onSubmit={handleSubmit}>
        {/* Blog Type Dropdown */}
        <FormControl fullWidth>
          <InputLabel>Blog Type *</InputLabel>
          <Select
            value={selectedBlogType}
            label="Blog Type *"
            onChange={(event) => setSelectedBlogType(event.target.value as string)}
            {...(isCategoryLoading ? { disabled: true } : {})}
          >
            {isCategoryLoading ? (
              <MenuItem value="">Loading...</MenuItem>
            ) : categoryError ? (
              <MenuItem value="">Error</MenuItem>
            ) : (
              categoryData?.data.map((category: Category) => (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        {/* Blog Title */}
        <TextField
          fullWidth
          label="Blog Title *"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        {/* Rich Text Editor */}
        <Paper variant="outlined">
          <Box p={1} display="flex" gap={1} borderBottom="1px solid #e0e0e0">
            <IconButton 
              size="small" 
              onClick={() => handleFormat('bold')}
              sx={{ '&.active': { backgroundColor: 'action.selected' } }}
            >
              <FormatBold />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={() => handleFormat('italic')}
            >
              <FormatItalic />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={() => handleFormat('formatBlock', '<blockquote>')}
            >
              <FormatQuote />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={() => handleFormat('insertUnorderedList')}
            >
              <FormatListBulleted />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={() => handleFormat('insertImage')}
            >
              <Image />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={() => handleFormat('undo')}
            >
              <Undo />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={() => handleFormat('redo')}
            >
              <Redo />
            </IconButton>
          </Box>
          <div
            ref={editorRef}
            contentEditable
            style={editorStyles}
            className="blog-editor"
            onInput={(e) => {
              const target = e.target as HTMLDivElement;
              setContent(target.innerHTML);
            }}
          />
        </Paper>

        {/* Image Upload Sections */}
        <Box display="flex" gap={2}>
          {/* Banner Image Upload */}
          <Box flex={1}>
            <Typography variant="subtitle1" mb={1}>Blog Banner Image</Typography>
            <input
              type="file"
              accept="image/*"
              hidden
              ref={bannerInputRef}
              onChange={handleBannerUpload}
            />
            <Paper 
              variant="outlined" 
              sx={{ 
                p: 3, 
                textAlign: 'center',
                minHeight: 200,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                position: 'relative',
              }}
              onClick={() => bannerInputRef.current?.click()}
              onDragOver={handleDragOver}
              onDrop={handleBannerDrop}
            >
              {bannerImage ? (
                <Box width="100%" height="100%" position="relative">
                  <img 
                    src={URL.createObjectURL(bannerImage)} 
                    alt="Banner preview" 
                    style={{ maxWidth: '100%', maxHeight: '180px', objectFit: 'contain' }}
                  />
                </Box>
              ) : (
                < >
                  <Button variant="text">Add Image</Button>
                  <Typography variant="body2" color="textSecondary">
                    Drag and drop banner image
                  </Typography>
                </ >
              )}
            </Paper>
          </Box>

          {/* Multiple Images Upload */}
          <Box flex={1}>
            <Typography variant="subtitle1" mb={1}>Upload Multiple Image for the Blog</Typography>
            <input
              type="file"
              accept="image/*"
              hidden
              multiple
              ref={multipleImagesInputRef}
              onChange={handleMultipleImagesUpload}
            />
            <Paper 
              variant="outlined" 
              sx={{ 
                p: 3, 
                textAlign: 'center',
                minHeight: 200,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
              onClick={() => multipleImagesInputRef.current?.click()}
              onDragOver={handleDragOver}
              onDrop={handleMultipleImagesDrop}
            >
              {blogImages.length > 0 ? (
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {blogImages.map((image, index) => (
                    <img 
                      key={index}
                      src={URL.createObjectURL(image)}
                      alt={`Blog image ${index + 1}`}
                      style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                    />
                  ))}
                </Box>
              ) : (
                < >
                  <Button variant="text">Add Image</Button>
                  <Typography variant="body2" color="textSecondary">
                    Drag and drop blog images
                  </Typography>
                </ >
              )}
            </Paper>
          </Box>
        </Box>

        {/* Save Button */}
        <Box display="flex" justifyContent="flex-end">
          <Button 
            variant="contained" 
            color="primary"
            sx={{ minWidth: 100 }}
            type="submit"
            disabled={isLoading}
          >
            Save
          </Button>
        </Box>
      </Box>

      <style>
        {`
          .blog-editor:empty:before {
            content: 'Start typing...';
            color: gray;
            position: absolute;
          }
          .blog-editor:focus {
            outline: none;
          }
          .blog-editor blockquote {
            border-left: 3px solid #ccc;
            margin: 1.5em 0 1.5em 10px;
            padding: 0.5em 10px;
          }
        `}
      </style>
    </Box>
  );
};

export default CreateBlog;
