import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddEditReceivingHistory from "./add-receiving-history";

export interface ReceivingHistory {
  id: string;
  batch: string;
  receivedDate: Date;
  quantity: number;
}
interface ReceivingHistoryProps {
  disabled?: boolean;
}
const ReceivingHistoryList: React.FC<ReceivingHistoryProps> = ({
  disabled,
}) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [receivingHistories, setReceivingHistories] = useState<
    ReceivingHistory[]
  >([
    {
      id: "1",
      batch: "Batch A1",
      receivedDate: new Date(2023, 10, 15),
      quantity: 50,
    },
    {
      id: "2",
      batch: "Batch B2",
      receivedDate: new Date(2023, 11, 5),
      quantity: 75,
    },
  ]);

  useEffect(() => {}, []);

  const columns: Array<MRT_ColumnDef<ReceivingHistory>> = [
    {
      header: T("batch"),
      accessorKey: "batch",
      size: 50,
    },
    {
      header: T("receivedDate"),
      accessorKey: "receivedDate",
      size: 50,
      Cell({ row: { original } }): JSX.Element {
        return (
          <Typography>{original.receivedDate.toLocaleDateString()}</Typography>
        );
      },
    },
    {
      header: T("quantity"),
      accessorKey: "quantity",
      size: 50,
      Cell({ row: { original } }): JSX.Element {
        return <Typography>{original.quantity}</Typography>;
      },
    },
    {
      header: "",
      accessorKey: "id",
      size: 50,
      Cell({ row: { original } }): JSX.Element {
        return (
          <Box display={"flex"} gap={1}>
            <IconButton>
              <Delete />
            </IconButton>
            <IconButton onClick={() => setOpenEditDialog(true)}>
              <Edit />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <DataTable<ReceivingHistory>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("receivingHistory")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`100 pad received`}
              />
            </Box>
            <Button
              onClick={() => setOpenDialog(true)}
              sx={{ minWidth: "200px" }}
              variant="contained"
              disabled={!disabled}
            >
              {T("add-new-receiving-history")}
            </Button>
          </Box>
        }
        list={receivingHistories}
        count={receivingHistories.length}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={false}
        isSuccess={true}
        isError={false}
        isFetching={false}
        onRowClick={(row) => navigate(`/receiving-history/detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
      <AddEditReceivingHistory open={openDialog} setModalOpen={setOpenDialog} />
      <AddEditReceivingHistory
        open={openEditDialog}
        setModalOpen={setOpenEditDialog}
      />
    </Box>
  );
};

export default ReceivingHistoryList;
