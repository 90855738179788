export const appEndpoint = {
  getUser: `/api/getUser`,
  logout: 'api/logout',
  postFile: `http://13.60.206.129:5005/v1/files`,
  getFile: `http://13.60.206.129:5005/v1/files`,
  getDistributionCenters: '/v1/admin/distribution_centers',
  getDistributionCenterById: '/v1/admin/distribution_centers/detail',
  getReceiverByDistributionCenters: '/v1/admin/distribution_centers/pad_receiver',
  getReceiverById: '/v1/admin/pad_receiver',
  addDistributionCenters: '/v1/admin/distribution_centers',
  addPadProviders: '/v1/admin/pad_providers',
  payPadProviders: '/v1/admin/batches/process_payment',
 addCategory: '/v1/admin/category',
  getPadProviders: "/v1/admin/pad_providers",
  getCategory: "/v1/admin/category",
  getMapping: "/v1/admin/pad_providers/map/pad_provider_map",
  getPadProviderDetails: "/v1/admin/pad_providers",
  roles: "/v1/admin/roles",
  getAllReceiver: "/v1/admin/pad_receiver",
  getAllBatches: "/v1/admin/batches/detail/batches_with_provider",
  getAdminBatchDetail: "/v1/admin/batches",
  addUsers: "/v1/admin/users/create_staff",
  getUsersByDistributionCenterId: "/v1/admin/distribution_centers/users",
  getCentersByProviderId: "/v1/admin/distribution_centers/lists",
  getUsersByProviderId: "/v1/admin/pad_providers/users",
  getPaymentByProviderId: "/v1/admin/pad_providers/get_all_payment",
  getPaymentOfProvider: "/v1/admin/payments",
  getPendingPaymentByProviderId: "/v1/admin/batches/pending",
  getPendingPaymentDetail: "/v1/admin/payments/detail",
  finalizePayment:"/v1/admin/batches/update_payment",
  getOrdersByProviderId: "/v1/admin/pad_providers/orders",
  getAllDonor: "/v1/admin/donor",
  addReceiver: "/v1/admin/pad_receiver",
  getAllDonation: "/v1/admin/donations",
  getDonorDetailById: "/v1/admin/donor/detail",
  removeCenter: "/v1/admin/distribution_centers",
  updateCenter: "/v1/admin/distribution_centers",
  deactivateCenter: "/v1/admin/distribution_centers/change_status",
  removeUser: "/v1/admin/users",
  updateUser: "/v1/admin/users",
  updateReceiver: "/v1/admin/pad_receiver",
  removeReceiver: "/v1/admin/pad_receiver",
  deactivateProvider: "/v1/admin/pad_providers/change_status",
  mapCenters: "/v1/admin/pad_providers/map_distribution_center",
  assignBatch: "/v1/admin/batches/assign_batch",
  updatePadProviders: '/v1/admin/pad_providers',
  getAllPayment:"/v1/admin/payments",
  getAllPaymentByProvider:"/v1/pad_providers/payment",
  getPadByReceivers: '/v1/admin/pad_receiver',
  getPadByReceivers1: '/v1/admin/pad_receiver1',
 getRecentOrder: '/v1/pad_providers/order',
 getPads: '/v1/pad_providers/pad/all_pads',
 getBatch: '/v1/pad_providers/batch',
 getOrderDetail: '/v1/pad_providers/order/detail',
 getBlogs: '/v1/admin/blog',
 createBlog: '/v1/admin/blog',

 
 getBatchDetail: '/v1/pad_providers/pad',
scanPads: '/v1/pad_providers/pad/assign',
scanPadSystem: '/v1/pad_providers/pad/system_assign',
shipBatches: '/v1/pad_providers/batch/change_status',
padDetail: '/v1/pad_providers/pad/detail',
provideSummery: '/v1/pad_providers/order/summary',
centerSummery: '/v1/distribution_centers/pads',
batchesByCenter:"/v1/distribution_centers/batches",
shipedbatchesByCenter:"/v1/distribution_centers/batches/shipped",
batchDetailByCenter:"/v1/distribution_centers/batches/detail",
givePad:"/v1/distribution_centers/face_detect/face_recognition",
receiveBatches:"/v1/distribution_centers/batches/change_status",


};