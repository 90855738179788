import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { useGetDonorDetailByIdQuery } from "./api/donerApi";
import { LoadingIndicator } from "../core"
import { T } from "../localisations/T";
import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";

const DonorDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const { data, error, isLoading } = useGetDonorDetailByIdQuery({ id: id ?? "" });

  if (isLoading) return <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
  }}
>
  <LoadingIndicator/>
</Box>;
  if (error) return <div>Error loading donor details</div>;

  return (
    <>
      {data ? (
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Paper sx={{ padding: 2 }}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h6">{data.name}</Typography>
              <Button
                onClick={() => setOpenEditDialog(true)}
                variant="contained"
              >
                {T("edit")}
              </Button>
            </Box>
            <Box my={2} display={"flex"} gap={2}>
              <Stack>
                <Typography>{T("phone-number")}</Typography>
                <Typography>{data.phoneNumber}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("email")}</Typography>
                <Typography>{data.email}</Typography>
              </Stack>
              {/* Add more fields as necessary */}
            </Box>
            <Divider />
            <Box my={2} display={"flex"} gap={3}>
              <Stack>
                <Typography>{T("address")}</Typography>
                <Typography>{data.address}</Typography>
              </Stack>
              {/* Add more fields as necessary */}
            </Box>
          </Paper>
          {/* Additional components or features can be added here */}
        </Box>
      ) : (
        <div>No donor data available</div>
      )}
      <ConfirmModal
        type="warning"
        title={`Are you sure you want to edit this donor?`}
        message={`Are you sure you want to edit this donor?`}
        modalOpen={openEditDialog}
        setModalOpen={setOpenEditDialog}
        onOk={() => {
          // Handle edit logic here
        }}
      />
    </>
  );
};

export default DonorDetail;
