import React from "react";
import { Box, Typography, Button, Chip } from "@mui/material";
import { DataTable } from "../core/components/data-table";
import { MRT_ColumnDef } from "material-react-table";

const Feedback = () => {
  // Static data for feedbacks
  const feedbacks = [
    { id: 1, content: "Great service!", user: "User1" },
    { id: 2, content: "Could be better.", user: "User2" },
    { id: 3, content: "Had a fantastic experience!", user: "User3" },
  ];

  // Define columns for DataTable
  const columns: Array<MRT_ColumnDef<typeof feedbacks[0]>> = [
    {
      header: 'Feedback Content',
      accessorKey: 'content',
      size: 200,
    },
    {
      header: 'User',
      accessorKey: 'user',
      size: 100,
    },
  ];

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Feedback
      </Typography>
      <DataTable
        columns={columns}
        list={feedbacks}
        count={feedbacks.length}
        getRowId={(row) => row.id.toString()}
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>Feedbacks</Typography>
              <Chip
                label="Add Feedback"
                onClick={() => {}}
                variant="outlined"
                color="primary"
              />
            </Box>
          </Box>
        }
        isLoading={false}
        isSuccess={true}
        isError={false}
      />
    </Box>
  );
};

export default Feedback;
