/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Autocomplete, Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { YupSchema } from "../core/utilities/validation/yup-schema";
import { T } from "../localisations/T";
import { DefaultDialog } from "../core/dialogs";
import {
  useAddMappingMutation,
  useAssignBatchMutation,
  useGetAllPadProviderApiQuery,
} from "../provider/api/ProviderQuery";
import { LoadingButton } from "@mui/lab";
import { useGetAllDistributionCentersApiQuery, useGetCentersByIdQuery } from "../distribution-center/api/DistributionCentersQuery";
interface SettingFormProp {
  openModal: boolean;
  padProviderId: string;
  batchId: string;
  setOpenModal: (flag: boolean) => void;
}

const AssignCenter = ({
  openModal,
  setOpenModal,
  padProviderId, batchId,
}: SettingFormProp): JSX.Element => {
  const [addMapping, { isLoading: isCreating }] = useAssignBatchMutation();

  const onSubmit = async (data: any) => {
    addMapping({ body: data });
    setOpenModal(false);
  };

  const validationSchema = yup.object().shape({
   distributionCenterId: yup.string().required(T("please-select-provider")),
  });
  const formik = useFormik({
    initialValues: {
        batchId: batchId,

     distributionCenterId: '',
    },
    validationSchema,
    onSubmit,
  });

  
  const {
    data: centers,
    error: error1,
    isLoading: isLoading1,
  } = useGetCentersByIdQuery({id:padProviderId});

  return (
    <DefaultDialog
      title={T("assign-center")}
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <Autocomplete
            options={centers?.data ?? []}
            size="medium"
            autoHighlight
            // multiple
            disabled={ isLoading1}
            filterSelectedOptions
            getOptionLabel={(option:any) => `${option.name}`}
            sx={{ minWidth: "200px" }}
            fullWidth
          
            onChange={async (e: any, value: any) => {
             
              await formik.setFieldValue("distributionCenterId", value.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="distributionCenterId"
                name="distributionCenterId"
                label={T("centers")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.distributionCenterId &&
                  Boolean(formik.errors.distributionCenterId)
                }
                helperText={
                  formik.touched.distributionCenterId &&
                  formik.errors.distributionCenterId
                }
                fullWidth
              />
            )}
          />
          <LoadingButton loading={false} variant="contained" type="submit">
            {T("assign-center")}
          </LoadingButton>
        </Box>
      </form>
    </DefaultDialog>
  );
};

export default AssignCenter;
