import {
  Build,
  Edit,
  Girl,
  Groups,
  Home,
  Landscape,
  List,
  ManageAccounts,
  Payment,
  PersonAdd,
  Settings,
  ShoppingCart,
  Storefront,
} from "@mui/icons-material";

import { Menu } from "../model/menu";

export const menus: Menu[] = [
  {
    name: "report",
    path: "/",
    icon: Home,
  },
  {
    name: "distribution-centers",
    path: "/distribution-center",
    icon: ManageAccounts,
  
  },
  {
    name: "receiver",
    path: "/receiver",
    icon: Girl,
  },
  {
    name: "provider",
    path: "/provider",
    icon: Storefront,
   
  },
  {
    name: "batch",
    path: "/batch",
    icon: List,
   
  },
  {
    name: "donations",
    path: "/donations",
    icon: Build,
  
  },
  {
    name: "donar",
    path: "/donar",
    icon: ShoppingCart
  },
  {
    name: "payments",
    path: "/payment",
    icon: Payment
  },
  {
    name: "feedback",
    path: "/feedback",
    icon: Groups
  },
  {
    name: "setting",
    path: "/setting",
    icon: Settings
  },
  {
    name: "blog",
    path: "/blog-list",
    icon: Settings
  },
  {
    name: "create-blog",
    path: "/create-blog",
    icon: Settings
  },

 
 
];
