import { ViewColumnOutlined } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  PaperProps,
  Stack,
  Switch,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExpandedState, OnChangeFn, PaginationOptions } from "@tanstack/table-core";

import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableInstance,
  MRT_TableState,
  MaterialReactTableProps,
} from "material-react-table";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { ButtonToolbarAction } from "./toolbar-actions";
import { MaterialTranslation } from "../../../localisations/material-transaltion";
import { T } from "../../../localisations/T";

export type DT_ColumnDef<TData extends Record<string, any> = {}> = MRT_ColumnDef<TData> & {
  initialVisibility?: boolean;
};

type RowClickCallback<T extends Record<string, any> = {}> = (row: T) => void;
type RenderRowActionsCallback<T extends Record<string, any> = {}> = (
  row: T,
  index: number,
) => ReactNode;

type RenderBottomToolbarCustomActionsCallback<T extends Record<string, any> = {}> = () => ReactNode;

type RenderDetailPanelCallback<T extends Record<string, any> = {}> = ({
  row,
}: {
  row: MRT_Row<T>;
}) => ReactNode;

type DataTableRecordsProps<T extends Record<string, any>> = {
  list: MaterialReactTableProps<T>["data"] | undefined;
  count: MaterialReactTableProps<T>["rowCount"];
} & Required<Pick<MaterialReactTableProps<T>, "getRowId">> & {
    columns: DT_ColumnDef<T>[];
  };

type DataTableOptionProps<T extends Record<string, any>> = Pick<
  MaterialReactTableProps<T>,
  | "enableBottomToolbar"
  | "enableRowSelection"
  | "onRowSelectionChange"
  | "renderRowActionMenuItems"
  | "renderDetailPanel"
  | "enableRowNumbers"
  | "rowNumberMode"
  | "positionPagination"
  | "enableHiding"
> & { title?: string };

interface DataTableStateProps {
  isLoading: boolean;
  isSuccess: boolean;
  isFetching?: boolean;
  isError: boolean;
  rowSelection?: MRT_RowSelectionState;
  shouldResetPage?: boolean;
  emptyMessage?: string;
}

interface DataTableFilterProps {
  globalFilter?: string;
  muiSearchTextFieldProps?: TextFieldProps;
  positionGlobalFilter?: "left" | "right" | "none";
}

interface DataTablePaginationProps {
  rowsPerPageOptions?: number[];
}

interface DataTableActionProps<T extends Record<string, any>> {
  onRowClick?: RowClickCallback<T>;
  toolbarActions?: ReactNode;
  toolbar?: ReactNode;
  renderRowActions?: RenderRowActionsCallback<T>;
}

interface DataTableEditingProps<T extends Record<string, any>> {
  enableTableEditing?: boolean;
  editingMode?: "modal" | "cell" | "row" | "table";
  muiTableBodyCellEditTextFieldProps?: MaterialReactTableProps<T>["muiTableBodyCellEditTextFieldProps"];
  enableMultiRowSelection?: boolean;
}

interface DataTableRenderDetailPanel<T extends Record<string, any>> {
  renderDetailPanel?: RenderDetailPanelCallback<T>;
}

interface DataTableRenderBottomToolbarCustomActions<T extends Record<string, any>> {
  renderBottomToolbarCustomActions?: RenderBottomToolbarCustomActionsCallback<T>;
}

interface DataTableSubRows<T extends Record<string, any>> {
  enableExpanding?: boolean;
  manualExpanding?: boolean;
  toolbarActionMargin?: string;
  onExpandedChange?: OnChangeFn<ExpandedState>;
  enableStickyHeader?: boolean;
  enablePinning?: boolean;
  enableExpandAll?: boolean;
  pinAction?: boolean;
  hideRowActions?: boolean;
}

interface DataTableStylingProps<T extends Record<string, any>> {
  getRowBackgroundColor?: (original: MRT_Row<T>, theme: Theme) => string | undefined;
}

type DataTableProps<T extends Record<string, any>> = DataTableRecordsProps<T> &
  DataTableOptionProps<T> &
  DataTableStateProps &
  DataTablePaginationProps &
  DataTableActionProps<T> &
  Partial<Pick<MRT_TableState, "pagination">> &
  Pick<PaginationOptions, "onPaginationChange"> &
  Pick<PaperProps, "variant"> &
  DataTableEditingProps<T> &
  DataTableFilterProps &
  DataTableRenderDetailPanel<T> &
  DataTableRenderBottomToolbarCustomActions<T> &
  DataTableSubRows<T> &
  DataTableStylingProps<T>;
 

export const DataTable = <T extends Record<string, any> = {}>(
  props: DataTableProps<T>,
): JSX.Element => {
  const manualPagination = Boolean(props.pagination);
  const { localization } = MaterialTranslation();
  return (
    <Box sx={{ overflowX: "auto", width: "100%" }}>
      {props.title && (
        <Typography paddingX={1.5} paddingY={2} variant="h6" style={{ textTransform: "none" }}>
          {props.title}
        </Typography>
      )}
      <MaterialReactTable<T>
        /**
        Props related to the data and rendering of the table.
        @property {Array<Object>} columns - An array of objects that define the columns of the table.
        @property {Array<Object>} data - An array of objects that represent the rows of the table.
        @property {number} rowCount - The total number of rows in the table.
        @property {function} getRowId - A function that returns a unique ID for each row.
        */
        columns={props.columns}
        data={props?.list ?? []}
        rowCount={props?.count ?? 0}
        getRowId={props.getRowId}
        /**
        Props that manage the state of the table.
        @property {Object} initialState - The initial state of the table.
        @property {Object} state - The current state of the table.
        @property {boolean} state.isLoading - Determines if the table is in a loading state.
        @property {boolean} state.showProgressBars - Determines if the table should display progress bars during loading.
        @property {boolean} state.showAlertBanner - Determines if the table should display an error banner.
        @property {Object} [state.pagination] - An object that contains pagination related properties.
        @property {string} state.globalFilter - The current global filter value.
        */
        initialState={{
          showGlobalFilter: true,
          showColumnFilters: false,
          columnPinning: {
            left: props.columns
              .filter((col) => Boolean(col.id) && col.enablePinning)
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .map((column) => column.id!.toString()),
            right: [props.pinAction ? "mrt-row-actions" : ""],
          },
          columnVisibility: props.columns
            .filter((col) => Boolean(col.id) && col.initialVisibility !== undefined)
            .reduce<Record<string, boolean>>((acc, column) => {
              acc[column.id!.toString()] = column.initialVisibility as boolean;
              return acc;
            }, {}),
        }}
        /**
        Props related to row actions and menu items.
        @property {boolean} enableRowActions - Determines if row actions are enabled.
        @property {function} renderRowActions - A function that renders custom row actions.
        @property {function} renderRowActionMenuItems - A function that renders custom row action menu items.
        @property {boolean} enableRowNumbers - Determines whether row numbers are enabled.
        @property {string} rowNumberMode - Determines how row numbers are displayed.
        */

        enableRowActions={
          Boolean(props.renderRowActions) || Boolean(props.renderRowActionMenuItems)
        }
        enableRowNumbers={props.enableRowNumbers}
        positionActionsColumn="last"
        // Props related to selection
        enableRowSelection={props.enableRowSelection}
        onRowSelectionChange={props.onRowSelectionChange}
        /**
        Props related to pagination and sorting in the table.
        @property {boolean} manualPagination - Determines whether pagination is manual.
        @property {Function} onPaginationChange - Function that gets called when the pagination changes.
        @property {boolean} enableSorting - Determines whether sorting is enabled.
        */
        enableSorting={!props.pagination}
        /**
        Props related to the top toolbar of the table.
        @property {boolean} enableTopToolbar - Determines if the top toolbar is enabled.
        @property {Object} muiTopToolbarProps - An object that contains Material UI properties for the top toolbar.
        @property {function} renderTopToolbarCustomActions - A function that renders custom actions for the top toolbar.
        */
        enableTopToolbar={
          Boolean(props.toolbarActions) || Boolean(props.toolbar || Boolean(props.enableHiding))
        }
        muiTopToolbarProps={{
          sx: (theme: Theme) => ({
            background: theme.palette.background.paper,
            borderTopLeftRadius: `${theme.shape.borderRadius}px`,
            borderTopRightRadius: `${theme.shape.borderRadius}px`,
          }),
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Stack
            direction="column"
            width="100%"
            display="flex"
            gap={2}
            justifyContent="end"
            alignItems="center"
            sx={{ padding: props.toolbarActionMargin ? props.toolbarActionMargin : "25px" }}
          >
            <Box
              width="100%"
              display="flex"
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                width="100%"
                display="flex"
                gap={2}
                alignItems="center"
                justifyContent="space-between"
              >
                {props.toolbarActions && props.toolbarActions}
              </Box>
              {props.enableHiding && <DataTableHideColumnAction table={table} />}
            </Box>
            {props.toolbar && (
              <Box width="100%" display="flex" gap={2} alignItems="center">
                {props.toolbar}
              </Box>
            )}
          </Stack>
        )}
        renderToolbarInternalActions={({ table }) => (
          <>
            {/* 
              This section is intentionally left empty to prevent conflicts with custom actions
              (e.g., <DataTableHideColumnAction />).
        
              Uncomment and use the following code blocks if needed:
        
              // {enableFilters &&
              //   enableGlobalFilter &&
              //   !initialState?.showGlobalFilter && (
              //     <MRT_ToggleGlobalFilterButton table={table} />
              //   )}
              {enableFilters &&
                enableColumnFilters &&
                columnFilterDisplayMode !== 'popover' && (
                  <MRT_ToggleFiltersButton table={table} />
                )}
              {(enableHiding || enableColumnOrdering || enableColumnPinning) && (
                <MRT_ShowHideColumnsButton table={table} />
              )}
              {enableDensityToggle && (
                <MRT_ToggleDensePaddingButton table={table} />
              )}
              {enableFullScreenToggle && (
                <MRT_ToggleFullScreenButton table={table} />
              )}
            */}
          </>
        )}
        /**
        Props related to the top toolbar of the table.
        @property {boolean} enableBottomToolbar - Determines if the bottom toolbar is enabled.
        */
        enableBottomToolbar={props.enableBottomToolbar}
        renderRowActions={
          !props?.renderRowActions
            ? undefined
            : ({ row: { original, index } }) => <>{props?.renderRowActions?.(original, index)}</>
        }
        renderRowActionMenuItems={
          props.hideRowActions
            ? undefined
            : !props?.renderRowActionMenuItems
            ? undefined
            : props.renderRowActionMenuItems
        }
        manualPagination={manualPagination}
        {...(props.pagination && {
          onPaginationChange: props.onPaginationChange,
        })}
        state={{
          isLoading: props.isLoading,
          showProgressBars: props.isFetching,
          showAlertBanner: props.isError,

          ...(props.rowSelection && {
            rowSelection: props.rowSelection,
          }),
          ...(props.pagination && {
            pagination: props.pagination,
          }),
        }}
        muiToolbarAlertBannerProps={
          props.isError
            ? {
                color: "error",
                children: T("sth-went-wrong-loading-records"),
              }
            : undefined
        }
        muiTableHeadProps={{
          sx: {
            textTransform: "none",
          },
        }}
        /**
        Props related to filtering in the table.
        @property {boolean} enableFilters - Determines if filtering is enabled.
        @property {boolean} enableGlobalFilter - Determines if the global filter is enabled.
        @property {boolean} enableColumnFilters - Determines if column filters are enabled.
        @property {string} positionGlobalFilter - Determines the position of the global filter.
        */
        enableFilters={false}
        enableGlobalFilter={false}
        enableColumnFilters={false}
        positionGlobalFilter={props.positionGlobalFilter ?? "none"}
        /**
        Props related to enabling table editing.
        @property {boolean} enableEditing - Determines if the table can be edited.
        @property {string} editingMode - Sets the type of editing mode to "table".
        @property {string} [memoMode] - Caches previous rows for performance optimization when enableEditing is true.
        @property {boolean} autoResetPageIndex - Disables when enableEditing is false to maintain the current page during re-render.
        */
        autoResetPageIndex={false}
        enableEditing={props.enableTableEditing}
        {...(props.enableTableEditing && {
          editingMode: props.editingMode ?? "table",
          memoMode: "cells",
          muiTableBodyCellEditTextFieldProps: props.muiTableBodyCellEditTextFieldProps,
        })}
        // Props related to enabling table styling.
        muiTablePaperProps={{
          elevation: props.variant !== "outlined" ? 0 : undefined,
          variant: props.variant,
        }}
        muiTableContainerProps={{
          sx: {
            borderRadius: props.toolbarActions ? 0 : "inherit",
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: props.rowsPerPageOptions ?? [5, 10, 20, 25, 30, 50, 100, 500, 1000],
        }}
        muiTableHeadCellProps={({ column }) => {
          return {
            sx: {
              background: "#FFF",
            },
          };
        }}
        muiTableBodyCellProps={({ cell, row }) => {
          return {
            sx: (theme) => ({
              background:
              row.index  % 2 !== 0 ?
                props.getRowBackgroundColor?.(row, theme) ?? theme.palette.background.paper:
                "#F6FFFB",


              "&:hover": {
                borderColor: "transparent", // or any desired color
              },
            }),
          };
        }}
        localization={localization}
        muiTableBodyRowProps={({ row }) => ({
          sx: (theme) => ({
            background: props.getRowBackgroundColor?.(row, theme) ?? theme.palette.background.paper,
            cursor: props.onRowClick ? "pointer" : "inherit",
          }),
          onClick: () => {
            props.onRowClick?.(row.original);
          },
        })}
        muiTableBodyProps={(table) => {
          return {
            sx: (theme) => ({}),
            children:
              !props.isLoading && !props.isFetching && !props.count ? (
                <tr>
                  <td
                    // style={{ textTransform: "capitalize" }}
                    colSpan={table.table.getVisibleLeafColumns().length}
                  >
                    <EmptyDataTable message={props.emptyMessage} />
                  </td>
                </tr>
              ) : undefined,
          };
        }}
        /**
        Props used to disable unused features.
        @property {boolean} enableColumnActions - Disables the ability to perform actions on columns.
        @property {boolean} enableDensityToggle - Disables the ability to toggle the density of the table.
        @property {boolean} enableFullScreenToggle - Disables the ability to toggle full screen mode.
        @property {boolean} enableHiding - Disables the ability to hide columns.
        @property {boolean}  enableStickyHeader - Toggle stick header.
        @property {boolean}  enableExpandAll - Toggle expand all.
        */
        enableColumnActions={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={props.enableHiding}
        renderDetailPanel={props.renderDetailPanel}
        renderBottomToolbarCustomActions={props.renderBottomToolbarCustomActions}
        enableStickyHeader={props.enableStickyHeader}
        enablePinning={props.enablePinning}
        enableExpandAll={props.enableExpandAll}
        enableMultiRowSelection={props.enableMultiRowSelection}
        muiSearchTextFieldProps={props.muiSearchTextFieldProps}
      />
    </Box>
  );
};

type EmptyTableProps = {
  message?: string;
};
const EmptyDataTable = ({ message }: EmptyTableProps): JSX.Element => {
  return (
    <Box
      sx={{
        textAlign: "center",
        p: 5,
      }}
    >
      <Grid container direction="column" spacing={1}>
        {/* <Grid item>
          <img src="/assets/empty-box.svg" alt="Empty" />
        </Grid> */}
        <Grid item>
          {/* <Typography variant="h4" fontWeight="500">
            {T("empty")}
          </Typography> */}
          <Typography variant="body1">{message ?? T("no-records-available")}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

interface DataTableHideColumnDialogProps<TData extends Record<string, any>> {
  table: MRT_TableInstance<TData>;
}

const DataTableHideColumnAction = <TData extends Record<string, any>>({
  table,
}: DataTableHideColumnDialogProps<TData>): JSX.Element => {
  const [open, setOpen] = useState(false);
  const onClose = (): void => {
    setOpen(false);
  };

  const columns = useMemo(
    () => table.getAllColumns().filter(({ getCanHide }) => getCanHide()),
    [table],
  );

  return (
    <Fragment>
      <ButtonToolbarAction onClick={() => setOpen(!open)} variant={"outlined"}>
        <Tooltip title={T("showHideColumns")}>
          <ViewColumnOutlined />
        </Tooltip>
      </ButtonToolbarAction>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        componentsProps={{ backdrop: { invisible: true } }} // This removes the backdrop
        PaperProps={{
          style: {
            position: "absolute",
            top: 210,
            right: 20,
            maxWidth: 400,
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <MenuList disablePadding>
            {columns.map(({ columnDef, getIsVisible, getCanHide, toggleVisibility }) => {
              return (
                <MenuItem key={columnDef.id} onClick={() => toggleVisibility()} divider>
                  <ListItemIcon>
                    <Switch disabled={!getCanHide()} checked={getIsVisible()} />
                  </ListItemIcon>
                  <ListItemText primary={columnDef.header} />
                </MenuItem>
              );
            })}
          </MenuList>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
