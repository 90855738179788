import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import StatusButton from "../core/ui/status/status";
import { useNavigate } from "react-router-dom";
import {
  useGetBatchQuery,
  useGetRecentOrdersQuery,
  useShipBatchesMutation,
} from "./api/ProvidersApi";
import {
  AdfScanner,
  QrCodeScanner,
  QrCodeScannerOutlined,
  Scanner,
} from "@mui/icons-material";
import { useAuth } from "../auth";
import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";

export interface Order {
  paymentStatus: string;
  padProvider: any;
  quantity: number;
  type: string;
  date: string;
  trackingNumber: string;
  id: string;
  totalPad: number;
  name: string;
  status: string;
  maximumBatchCapacity: number;
}
export enum OrderStatus {
  Pending = "Pending",
  Confirmed = "Confirmed",
  Cancelled = "Cancelled",
  Processing = "Processing",
  Matched = "Matched",
  Delivered = "Delivered",
}
const BatchList: React.FC = () => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [order, setOrders] = useState<Order[]>([]);

  const { data, error, isLoading } = useGetBatchQuery({
    id: session?.userInfo?.centerId ?? "",
  });

  useEffect(() => {}, []);

  const [Order, setOrder] = useState<Order | null>(null);
  const statusOptions: Array<string> = Object.values(OrderStatus);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const menuItems: Array<{ id: string; value: number; label: string }> =
    useMemo(
      () => [
        {
          id: "last_week",
          value: -7,
          label: T("last-week"),
        },
        {
          id: "last_month",
          value: -30,
          label: T("last-30-days"),
        },
        {
          id: "last_6_months",
          value: -180,
          label: T("last-6-mounths"),
        },
        {
          id: "last_year",
          value: -365,
          label: T("last-year"),
        },
      ],
      []
    );

  const columns: Array<MRT_ColumnDef<Order>> = [
    {
      header: T("name"),
      accessorKey: "name",
      size: 15,
    },
    {
      header: T("pad-type"),
      accessorKey: "type",
      size: 15,
    },
    {
      header: T("status"),
      accessorKey: "status",
      size: 15,
    },
    {
      header: T("payment-status"),
      accessorKey: "paymentStatus",
      size: 15,
    },
  ];
  const [openDialog1, setOpenDialog1] = useState(false);
  const [ship, { isLoading: isLoading1 }] = useShipBatchesMutation();

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  return (
    <Box>
      <DataTable<Order>
        toolbar={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Button
                onClick={() => {
                  setOpenDialog1(true);
                }}
                sx={{ minWidth: "200px" }}
                variant="contained"
              >
                {T("ship")}
              </Button>
            </Box>

            <Box gap={3} display={"flex"} flexDirection={"row"}>
              <Autocomplete
                options={statusOptions}
                getOptionLabel={(option) => T(option)} // Translate the status options
                onChange={(event, value) => setSelectedStatus(value || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={T("status")}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                options={menuItems}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={T("date")}
                    variant="outlined"
                    fullWidth
                    //   sx={{ width: props.width }}
                  />
                )}
              />
            </Box>
          </Box>
        }
        list={data?.data ?? []}
        count={data?.data?.length ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        rowSelection={rowSelection}
        onRowSelectionChange={setRowSelection}
        enableRowSelection={(row) => row.original.status==='CLOSED'}
        renderRowActions={(row) => (
          <Box display={"flex"} gap={1}>
            <QrCodeScannerOutlined />
          </Box>
        )}
        onRowClick={(row) => navigate(`/batch-detail/${row.id}`)}
        // onRowClick={(row) => navigate(`/distribution-center-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      <ConfirmModal
        type="info"
        title="Are you sure you want to dispatch this batches for shipping"
        message=""
        modalOpen={openDialog1}
        setModalOpen={setOpenDialog1}
        onOk={() => {
          ship({
            body: {
              batchIds: Object.keys(rowSelection),
            },
          });
        }}
      ></ConfirmModal>
    </Box>
  );
};

export default BatchList;
