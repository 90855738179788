import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import { DefaultDialog } from "../core/dialogs";
import StatusButton from "../core/ui/status/status";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useGetAllReceiverQuery } from "./api/ReciverQuery";
import { List } from '../../libs/core/age-calculate';
export interface Receiver {
  id: string;
  firstName: string;
  lastName: string;
  sex: string;
  age: number;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  location: string;
  city: string;
  schoolName: string;
  grade: string;
  status: boolean;
}

const ReceiversList: React.FC = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  // Fetch data using the API hook
  const { data, error, isLoading } = useGetAllReceiverQuery();
  const [receivers, setReceivers] = useState<Receiver[]>([
    {
      id: "42356b4f-a747-11ef-b93a-ba38b30dc3bc",
      firstName: "Hannah",
      lastName: "Hamsa",
      sex: "Female",
      age: 13,
      dateOfBirth: "2010-01-01",
      phoneNumber: "+25198765674",
      email: "hannah@gmail.com",
      location: "Bahir Dar",
      city: "Bahir Dar",
      schoolName: "Shimbit",
      grade: "8",
      status: true,
    },
  ]);
 
  const columns: Array<MRT_ColumnDef<Receiver>> = [
    {
      header: T("first-name"),
      accessorKey: "firstName",
      size: 50,
    },
    {
      header: T("last-name"),
      accessorKey: "lastName",
      size: 50,
    },
    {
      header: T("age"),
      accessorKey: "dateOfBirth",
      Cell({ row: { original } }): JSX.Element {
        const calculateAge = (birthDate: string) => {
          return Math.floor((new Date().getTime() - new Date(birthDate).getTime()) / (365.25 * 24 * 60 * 60 * 1000));
        };

        return (
          <Typography>
            {original.dateOfBirth ? calculateAge(original.dateOfBirth) : T("not-specified")}
          </Typography>
        );
      },
    },
    {
      header: T("phone-number"),
      accessorKey: "phoneNumber",
      size: 50,
    },
    {
      header: T("email"),
      accessorKey: "email",
      size: 50,
    },
    {
      header: T("school-name"),
      accessorKey: "schoolName",
      size: 50,
    },
    {
      header: T("grade"),
      accessorKey: "grade",
      size: 50,
    },
    {
      header: T("location"),
      accessorKey: "city",
      size: 50,
      Cell({ row: { original } }): JSX.Element {
        return (
          <Typography>
            {original.city || T("not-specified")}
          </Typography>
        );
      },
    },
    {
      header: T("status"),
      accessorKey: "status",
      size: 50,
      Cell({ row: { original } }): JSX.Element {
        return (
          <StatusButton
            status={original.status ? "active" : "inactive"}
            text={original.status ? "Active" : "Inactive"}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <DataTable<Receiver>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("receivers")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`${data?.data?.length ?? ""} ${T("receivers")}`}
              />
            </Box>
          </Box>
        }
        list={List(data)}
        
        // count={data?.meta.totalCount??0}
        count={data?.data?.length ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        isFetching={false}
        // renderRowActions={(row) => (
        //   <Box display={"flex"} gap={1}>
        //     <IconButton onClick={() => setOpenEditDialog(true)}>
        //       <Edit />
        //     </IconButton>
        //     <Box display={"flex"} gap={1}>
        //       <IconButton >
        //       <RemoveRedEye
        //         onClick={() => {
        //           navigate(`/distribution-center-detail/${row.id}`);
        //         }}
        //       />
        //       </IconButton>
        //     </Box>
        //   </Box>
        // )}
        onRowClick={(row) => navigate(`/receiver-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
    </Box>
  );
};

export default ReceiversList;
