import { LoadingButton } from "@mui/lab";
import { Box, Modal, Typography } from "@mui/material";
import { T } from "../../../localisations/T";


/* Component props */
type ConfirmModalProps = {
  setModalOpen: (value: boolean) => void;
  modalOpen: boolean;
  title: string;
  message: string | React.ReactNode;
  type: "error" | "success" | "info" | "warning";
  customOkText?: string;
  customCancelText?: string;
  onOk?: any;
  onCancel?: () => void;
  loading?: boolean;
  hideCancelButton?: boolean;
  subTitle?:string|null;
  subTitleColor?: string; 

  // await?: boolean;
};

export const ConfirmModal = (props: ConfirmModalProps): JSX.Element => {
  /* custom vatiables */
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "80%",
      md: 500,
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: {
      xs: "2rem 1rem",
      md: "3rem 2.5rem",
    },
    borderRadius: "10px",
  };
  /* Event handlers */
  const onOk = async (): Promise<void> => {
    // if (props.await) {
    try {
      if (props.onOk) {
        await props.onOk();
        props.setModalOpen(false);
      }
    } catch (error) {
      // Handle any errors during the onOk operation
    }
    // } else {
    //   if (props.onOk) {
    //     props.onOk();
    //   }
    //   props.setModalOpen(false);
    // }

    // setOperationComplete(true);
  };

  const onCancel = (): void => {
    if (props?.onCancel) {
      props?.onCancel();
    }
    props?.setModalOpen(false);
  };
  return (
    <Modal
      open={props?.modalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={(event, reason) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
          return;
        }
        props.setModalOpen(false);
      }}
    >
      <>
        {props?.modalOpen && (
          <Box sx={style}>
            <div>
              <Typography variant="h5" style={{ fontWeight: "bold" }} gutterBottom>
                {props?.title}
              </Typography>
            </div>
            {props?.subTitle && (
              <div>
                <Typography variant="subtitle1"  style={{ color: props?.subTitleColor || "gray" }}
 gutterBottom>
                  {props?.subTitle}
                </Typography>
              </div>
            )}

            <div style={{ marginTop: "1rem" }}>
              <Typography variant="body1" gutterBottom>
                {props?.message}
              </Typography>
            </div>
            <Box
              sx={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "flex-end",
                columnGap: 1,
              }}
            >
              {!props?.hideCancelButton && (
                // <Button variant="outlined" color={props?.type} onClick={onCancel}>
                //   {props?.loading ? (
                //     <CircularProgress size={24} />
                //   ) : props?.customCancelText ? (
                //     props?.customCancelText
                //   ) : (
                //     "Cancel"
                //   )}
                // </Button>
                <LoadingButton
                  loading={props?.loading}
                  variant="outlined"
                  color={props?.type}
                  onClick={onCancel}
                >
                  {props?.customCancelText ? props.customCancelText : T("no")}
                </LoadingButton>
              )}

              {/* <Button
                variant="contained"
                color={props?.type}
                onClick={onOk}
                disabled={props?.loading}
                disableElevation
              >
                {props?.loading ? (
                  <CircularProgress size={24} />
                ) : props?.customCancelText ? (
                  props?.customCancelText
                ) : (
                  "OK"
                )}
              </Button> */}
              <LoadingButton
                loading={props?.loading}
                loadingPosition="center"
                variant="contained"
                color={props?.type}
                onClick={onOk}
              >
                {props?.customOkText ? props?.customOkText : T("yes")}
              </LoadingButton>
            </Box>
          </Box>
        )}
      </>
    </Modal>
  );
};
