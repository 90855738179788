/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { T } from "../localisations/T";
import { DefaultDialog } from "../core/dialogs";

import { LoadingButton } from "@mui/lab";
import { useAddCategoryMutation } from "../provider/api/ProviderQuery";

interface CategoryFormProps {
  openModal: boolean;
  setOpenModal: (flag: boolean) => void;
}

const AddCategoryForm = ({
  openModal,
  setOpenModal,
}: CategoryFormProps): JSX.Element => {
  const [addCategory, { isLoading: isCreating }] = useAddCategoryMutation();

  const onSubmit = async (data: any) => {
    try {
      await addCategory({ body: data });
      setOpenModal(false);
    } catch (error) {
      console.error('Failed to add category:', error);
      // Optionally, handle the error in the UI, e.g., show a notification
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(T("please-enter-category-name")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <DefaultDialog
      title={T("add-category")}
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <TextField
            id="name"
            name="name"
            label={T("category-name")}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />

          <LoadingButton
            loading={isCreating}
            variant="contained"
            type="submit"
          >
            {T("add-category")}
          </LoadingButton>
        </Box>
      </form>
    </DefaultDialog>
  );
};

export default AddCategoryForm;
