import React, { useEffect, useState } from "react";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { T } from "../localisations/T";
import { DataTable } from "../core/components/data-table";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import AddEditDistributionCenter from "./add-distribution-center";
import StatusButton from "../core/ui/status/status";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";
import { useGetAllDistributionCentersApiQuery, useRemoveCenterMutation } from "./api/DistributionCentersQuery";

export interface DistributionCenter {
  id: string;
  name: string;
  status: boolean;
  phoneNumber: string;
  email: string;
  image: string;
  city: string;
  subCity: string;
  woreda: string;
  zone: string | null;
  region: string;
  houseNumber: string;
}

const DistributionCenterList: React.FC = () => {
  const navigate = useNavigate();

  // Fetch data using the API hook
  const { data, error, isLoading } = useGetAllDistributionCentersApiQuery();

  // Local state for pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Local state to manage dialog visibility
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  // Set fetched data to the local state
  const [distributionCenters, setDistributionCenters] = useState<
    DistributionCenter[]
  >([]);

  const [distributionCenter, setDistributionCenter] =
    useState<DistributionCenter | null>(null);

  const [delteDistributionCenter, { isLoading: isLoading1 }] =
    useRemoveCenterMutation();

  useEffect(() => {
    if (data) {
      setDistributionCenters(data); // Update state with fetched data
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      console.error("Error fetching distribution centers:", error);
    }

    if (data) {
      setDistributionCenters(data); // Update state with fetched data
    }
  }, [data, error]);

  const columns: Array<MRT_ColumnDef<DistributionCenter>> = [
    {
      header: T("distribution-center"),
      accessorKey: "name",
      size: 50,
    },
    {
      header: T("location"),
      accessorKey: "city",
      size: 50,
    },
    {
      header: T("phone-number"),
      accessorKey: "phoneNumber",
      size: 50,
    },
    {
      header: T("status"),
      accessorKey: "status",
      size: 50,
      Cell({ row: { original } }): JSX.Element {
        return (
          <StatusButton
            status={original.status ? "active" : "inactive"}
            text={original.status ? "Active" : "Inactive"}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <DataTable<DistributionCenter>
        toolbar={
          <Box display="flex" alignItems="center" width="100%">
            <Box display="flex" gap={3} alignItems="center" flex="1">
              <Typography>{T("distribution-centers")}</Typography>
              <Chip
                color="primary"
                variant="outlined"
                label={`${data?.meta?.totalCount ?? ""} ${T(
                  "distribution-centers"
                )}`}
              />
            </Box>
            <Button
              onClick={() => setOpenDialog(true)}
              sx={{ minWidth: "200px" }}
              variant="contained"
            >
              {T("add-new-distributer")}
            </Button>
          </Box>
        }
        list={data?.data ?? []}
        count={data?.meta.totalCount ?? 0}
        columns={columns}
        getRowId={(row) => row.id}
        isLoading={isLoading}
        isSuccess={!!data}
        isError={!!error}
        
        isFetching={false}
        renderRowActions={(row) => (
          <Box display={"flex"} gap={1}>
            <IconButton>
              <Delete
                onClick={() => {
                  {
                    setDistributionCenter(row);
                    setOpenDialog1(true);
                  }
                }}
              />
            </IconButton>
            <IconButton disabled={!row.status}>
              <Edit
              onClick={() => {
                {
                setDistributionCenter(row);
                setOpenEditDialog(true);
                }
              }}
              />
            </IconButton>
            <IconButton>
              <RemoveRedEye
                onClick={() => {
                  navigate(`/distribution-center-detail/${row.id}`);
                }}
              />
            </IconButton>
          </Box>
        )}
        // onRowClick={(row) => navigate(`/distribution-center-detail/${row.id}`)}
        pagination={pagination}
        onPaginationChange={setPagination}
      />
      <AddEditDistributionCenter
        open={openDialog}
        setModalOpen={setOpenDialog}
      />

      {distributionCenter && (
        <>
          <ConfirmModal
            type="warning"
            title="Are you sure you want to delete this center"
            message="Are you sure you want to delete this center?"
            modalOpen={openDialog1}
            setModalOpen={setOpenDialog1}
            onOk={() => {
              console.log("kkkk", distributionCenter?.id);

              delteDistributionCenter({
                body: {
                  id: distributionCenter?.id ?? "",
                },
              });
            }}
          ></ConfirmModal>
          <AddEditDistributionCenter
            open={openEditDialog}
            distributionCenter={distributionCenter}
            setModalOpen={setOpenEditDialog}
          />
        </>
      )}
    </Box>
  );
};

export default DistributionCenterList;
