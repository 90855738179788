import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { Circle } from "@mui/icons-material";

// Define possible statuses
type Status = "active" | "inactive" | "pending";

interface StatusButtonProps {
  status: Status;
  text: string;
}

const OrderStatusButton: React.FC<StatusButtonProps> = ({ status, text }) => {
  let iconColor = "";
  let tooltipTitle = "";

  // Set the icon color and tooltip text based on the status
  switch (status) {
    case "active":
      iconColor = "green";
      tooltipTitle = "The status is active";
      break;
    case "inactive":
      iconColor = "red";
      tooltipTitle = "The status is inactive";
      break;
    case "pending":
      iconColor = "yellow";
      tooltipTitle = "The status is pending";
      break;
    default:
      iconColor = "gray";
      tooltipTitle = "Unknown status";
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center", // Align icon and text vertically
        justifyContent: "flex-start", // Align the icon to the left
        gap: "8px", // Space between icon and text
        width: "110px", // Fixed width for the Box
        border: `1px solid ${iconColor}`, // Border matching the icon color
        padding: "8px", // Padding around the content
        borderRadius: "8px", // Rounded corners
      }}
    >
      <Tooltip title={tooltipTitle}>
        <Circle
          style={{
            color: iconColor,
            fontSize: "16px", // Smaller icon
          }}
        />
      </Tooltip>
      <Typography
        variant="body2"
        fontWeight="500"
        sx={{
          flex: 1, // Allow text to take the remaining space and center it
          textAlign: "center", // Center the text horizontally
          overflowWrap: "anywhere", // Ensure long text wraps correctly
          wordWrap: "break-word",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default OrderStatusButton;
