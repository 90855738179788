import React, { useState, useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Box, Typography, Button, Alert } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { T } from "../localisations/T";
import { useScanPadMutation } from "./api/ProvidersApi";
import { useNavigate } from "react-router-dom"; // Import useNavigate

interface Props {
  orderId?: string;
}

const BarcodeScanner: React.FC<Props> = ({ orderId }) => {
  const [data, setData] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [stop, setStop] = useState<boolean>(true);
  const [cameraAccess, setCameraAccess] = useState<boolean | null>(null);
  const [scanPad, { isLoading, isSuccess }] = useScanPadMutation();
  const navigate = useNavigate(); // Initialize navigate

  const requestPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraAccess(true);
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      console.error("Camera permission denied or unavailable:", err);
      setCameraAccess(false);
    }
  };

  useEffect(() => {
    requestPermissions();
  }, []);

  const Submit = async () => {
    try {
      if (data) {
        await scanPad({
          body: {
            id: orderId ?? "",
            serialNumber: data,
          },
        }).unwrap(); // Wait for the mutation to resolve

        console.log("Submitting data:", data);
        
        if (isSuccess) {
          
          navigate("/orders"); // Navigate back
        } else {
          setError("Submission failed. Please try again.");
        }
      } else {
        setError("No data to submit.");
      }
    } catch (err) {
      console.error("Error submitting data:", err);
      setError("An error occurred during submission.");
    }
  };

  return (
    <Box
      py={6}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
    >
      {cameraAccess === null && (
        <Typography variant="body1">
          Requesting camera permissions...
        </Typography>
      )}

      {cameraAccess === false && (
        <Box textAlign="center">
          <Typography color="error" variant="body2">
            Camera access is denied. Please allow camera permissions in your
            browser settings.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={requestPermissions}
          >
            Retry Permission Request
          </Button>
        </Box>
      )}

      {cameraAccess === true && (
        <BarcodeScannerComponent
          delay={20}
          facingMode="environment"
          stopStream={stop}
          onUpdate={(err, result) => {
            if (err) {
              setError("Error scanning barcode.");
              console.error(err);
            } else {
              setError(null);
              if (result) {
                setData(result.getText());
              } else {
                setData("Not Found");
              }
            }
          }}
        />
      )}

      {error && (
        <Alert severity="warning" variant="outlined">
          {error}
        </Alert>
      )}

      {data && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderRadius={2}
          p={3}
          width="fit-content"
          bgcolor="#FFFFFF;"
          gap={1}
        >
          <Typography variant="body1" color="textPrimary">
            {data}
          </Typography>
          <CheckCircleIcon sx={{ color: "#27C96A" }} />
        </Box>
      )}

      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setStop(false)}
          disabled={cameraAccess === false}
        >
          Start
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={Submit}
          disabled={!data}
        >
          {T("submit")}
        </Button>
      </Box>
    </Box>
  );
};

export default BarcodeScanner;
