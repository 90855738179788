import { appApi } from "../../../store/app.api";
import { appEndpoint } from "../../../store/app.endpoint";
import { CommandRequest } from "../../core/utilities/command-request";


// Define the API endpoints
const PadProvidersApi = appApi.injectEndpoints({
  endpoints: (build) => ({

    getRecentOrders: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getRecentOrder}/${id}`,
        method: "get",
      }),
      providesTags: ["Orders"], // Tag for cache management
    }),

    getPads: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getPads}/${id}`,
        method: "get",
      }),
      providesTags: ["Orders"], // Tag for cache management
    }),
    getBatch: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getBatch}/${id}`,
        method: "get",
      }),
      providesTags: ["Orders"], // Tag for cache management
    }),
    getOrderDetailId: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getOrderDetail}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Orders", "Pad",
        "PadDetail",], // Tag for cache management
    }),
    getBatchDetailId: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getBatchDetail}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Orders", "Pad",
        "PadDetail",], // Tag for cache management
    }),
    getPadDetail: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.padDetail}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Orders"], // Tag for cache management
    }),
    getProviderSummery: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.provideSummery}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Orders"], // Tag for cache management
    }),
    getAllPaymentsByProvider: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${appEndpoint.getAllPaymentByProvider}/${id}`, // Append the ID to the URL
        method: "get",
      }),
      providesTags: ["Payment"], // Tag for cache management
    }),

    scanPad: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.scanPads,
        method: "post",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Scan", "Pad",
        "PadDetail",'Orders'] : []),
    }),
    scanPadSystem: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.scanPadSystem,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Scan", "Pad",
        "PadDetail",] : []),
    }),
    shipBatches: build.mutation<any, CommandRequest>({
      query: (data) => ({
        url: appEndpoint.shipBatches,
        method: "put",
        data: data?.body,
      }),
      invalidatesTags: (result) => (result ? ["Scan", "Pad",
        "PadDetail",] : []),
    }),
  }),

  overrideExisting: false, // Ensures this endpoint doesn't override others
});

// Export hooks for querying
export const {
  useGetRecentOrdersQuery,
  useLazyGetRecentOrdersQuery,
  useGetOrderDetailIdQuery,
  useGetAllPaymentsByProviderQuery,
  useScanPadMutation,
  useScanPadSystemMutation,
  useGetBatchQuery,
  useGetBatchDetailIdQuery,
  useGetProviderSummeryQuery,
  useShipBatchesMutation,
  useGetPadsQuery, useGetPadDetailQuery

} = PadProvidersApi;
