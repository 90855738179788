import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { Box, Button, Typography } from "@mui/material";

interface ImageCaptureProps {
  onCapture: (image: string | null) => void;
}

const ImageCapture: React.FC<ImageCaptureProps> = ({ onCapture }) => {
  const [image, setImage] = useState<string | null>(null);
  const webcamRef = useRef<Webcam>(null);

  const handleCapture = () => {
    if (webcamRef.current) {
      const capturedImage = webcamRef.current.getScreenshot();
      setImage(capturedImage);
      onCapture(capturedImage);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={300}
      />
      {image && (
        <Box mt={2}>
          <Typography variant="body2">Captured Image Preview:</Typography>
          <img
            src={image}
            alt="Captured"
            style={{ width: "300px", borderRadius: "8px", marginTop: "8px" }}
          />
        </Box>
      )}
      <Button variant="contained" color="primary" onClick={handleCapture}>
        Capture Image
      </Button>
    </Box>
  );
};

export default ImageCapture;
