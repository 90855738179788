import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { DefaultDialog } from "../dialogs";
import { T } from "../../localisations/T";
import * as yup from "yup";
import { useFormik } from "formik";
import { Receiver as ReceiverType } from "./receiver-list";
import ImageCapture from "./image-capturing";
import { FileInput } from "../ui/file-input/utils/file-input";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCreateReceiverMutation,
  useUpdateReceiverMutation,
} from "../../distribution-center/api/DistributionCentersQuery";

interface AddReceiverProps {
  open: boolean;
  setModalOpen: (x: boolean) => void;
  receiver?: ReceiverType;
  centerId: string;
}

type ReceiverFormValues = Omit<ReceiverType, "id">;

const AddEditReceiver: React.FC<AddReceiverProps> = ({
  open,
  setModalOpen,
  receiver,
  centerId,
}) => {
  // Validation schema for the form
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .min(2, "First name must be at least 2 characters")
      .max(50, "First name must not exceed 50 characters")
      .matches(
        /^[a-zA-Z\s]+$/,
        "First name should only contain letters and spaces"
      )
      .trim(),

    lastName: yup
      .string()
      .required("Last name is required")
      .min(2, "Last name must be at least 2 characters")
      .max(50, "Last name must not exceed 50 characters")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Last name should only contain letters and spaces"
      )
      .trim(),

    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email format")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .test(
        "no-consecutive-dots",
        "Email cannot contain consecutive dots",
        (value) => !value?.includes("..")
      )
      .test(
        "no-consecutive-at",
        "Email cannot contain consecutive @ symbols",
        (value) => !value?.includes("@@")
      )
      .min(5, "Email must be at least 5 characters")
      .max(255, "Email must not exceed 255 characters"),

    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(
        /^(?:\+251[0-9]{9}|09[0-9]{8})$/,
        "Please enter a valid Ethiopian phone number (e.g., 09xxxxxxxx or +251xxxxxxxxx)"
      ),

    sex: yup
      .string()
      .required("Gender is required")
      .oneOf(["Male", "Female"], "Please select either Male or Female"),

    region: yup
      .string()
      .required("Region is required")
      .matches(/^[a-zA-Z\s]+$/, "Region should only contain letters and spaces")
      .min(2, "Region must be at least 2 characters")
      .max(50, "Region must not exceed 50 characters"),

    zone: yup
      .string()
      .required("Zone is required")
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        "Zone should only contain letters, numbers and spaces"
      )
      .min(2, "Zone must be at least 2 characters")
      .max(50, "Zone must not exceed 50 characters"),

    city: yup
      .string()
      .optional()
      .matches(/^[a-zA-Z\s]*$/, "City should only contain letters and spaces")
      .min(2, "City must be at least 2 characters")
      .max(50, "City must not exceed 50 characters"),

    subCity: yup
      .string()
      .optional()
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "Sub City should only contain letters, numbers and spaces"
      )
      .min(2, "Sub City must be at least 2 characters")
      .max(50, "Sub City must not exceed 50 characters"),

    woreda: yup
      .string()
      .required("Woreda is required")
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        "Woreda should only contain letters, numbers, and spaces"
      )
      .min(1, "Woreda must be at least 1 character")
      .max(20, "Woreda must not exceed 20 characters"),

    schoolName: yup
      .string()
      .optional()
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "School name should only contain letters, numbers and spaces"
      )
      .min(2, "School name must be at least 2 characters")
      .max(100, "School name must not exceed 100 characters"),

    grade: yup
      .string()
      .optional()
      .matches(/^[0-9]*$/, "Grade should only contain numbers")
      .max(2, "Grade must not exceed 2 digits"),

    dateOfBirth: yup
      .date()
      .required("Date of Birth is required")
      .max(new Date(), "Date of birth cannot be in the future")
      .min(
        new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
        "Age cannot exceed 100 years"
      )
      .test(
        "age-check",
        "Person must be at least 5 years old",
        function (value) {
          if (!value) return false;
          const age = new Date().getFullYear() - new Date(value).getFullYear();
          return age >= 5;
        }
      ),

    age: yup
      .number()
      .required("Age is required")
      .positive("Age must be positive")
      .integer("Age must be a whole number")
      .min(5, "Age must be at least 5 years")
      .max(100, "Age cannot exceed 100 years")
      .test("age-match", "Age must match date of birth", function (value) {
        const dob = this.parent.dateOfBirth;
        if (!dob || !value) return true;
        const calculatedAge = Math.floor(
          (new Date().getTime() - new Date(dob).getTime()) /
            (365.25 * 24 * 60 * 60 * 1000)
        );
        return calculatedAge === value;
      }),

    supportDocument: yup.mixed().required("Support document is required"),

    identificationCard: yup.mixed().required("Identification card is required"),
    //status: yup.boolean().required("Status is required"),

    distributionCenterId: yup
      .string()
      .required("Distribution Center ID is required"),
  });

  // Initial form values
  const initialValues: ReceiverFormValues = {
    firstName: receiver?.firstName || "",
    lastName: receiver?.lastName || "",
    age: receiver?.age || 0,
    region: receiver?.region || "",
    zone: receiver?.zone || "",
    city: receiver?.city || "",
    subCity: receiver?.subCity || "",
    woreda: receiver?.woreda || "",
    sex: receiver?.sex || "Female",
    dateOfBirth: receiver?.dateOfBirth || null,
    phoneNumber: receiver?.phoneNumber || "",
    email: receiver?.email || "",
    schoolName: receiver?.schoolName || "",
    grade: receiver?.grade || "",
    supportDocument: receiver?.supportDocument || null,
    identificationCard: receiver?.identificationCard || null,
    // status: receiver?.status || true,
    distributionCenterId: centerId ?? "",
    photo: receiver?.photo || null,
  };

  const [createReceiver, { isLoading: isCreating }] =
    useCreateReceiverMutation();
  const [updateReceiver, { isLoading: isUpdating }] =
    useUpdateReceiverMutation();
  const [showImageCapture, setShowImageCapture] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [activeButton, setActiveButton] = useState<"upload" | "capture">("upload");
  // Formik setup
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: ReceiverFormValues) => {
      // Format the date before sending

      const formattedValues = {
        ...values,
        dateOfBirth: values.dateOfBirth
          ? new Date(values.dateOfBirth).toISOString().split("T")[0]
          : null,
      };

      try {
        if (receiver) {
          await updateReceiver({
            body: {
              id: receiver.id,
              ...formattedValues,
            },
          });
        } else {
          await createReceiver({ body: formattedValues });
        }
        setModalOpen(false);
      } catch (error) {
        console.log("error in add");
      }
      setModalOpen(false);
    },
  });

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const age = parseInt(e.target.value);
    if (age) {
      const today = new Date();
      const dob = new Date(
        today.getFullYear() - age,
        today.getMonth(),
        today.getDate()
      );
      formik.setFieldValue("dateOfBirth", dob.toISOString().split("T")[0]);
    }
    formik.handleChange(e);
  };

  const handleFileDelete = (
    fieldName: "supportDocument" | "identificationCard" | "photo"
  ) => {
    formik.setFieldValue(fieldName, null);
  };

  const handleImageCapture = (capturedImage: any) => {
    console.log("Captured Image:", capturedImage);
    formik.setFieldValue("photo", capturedImage);
  };

  return (
    <Box>
      <DefaultDialog
        title={receiver ? T("edit-receiver") : T("add-receiver")}
        open={open}
        minWidth={"50%"}
        onClose={() => setModalOpen(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box flexDirection={"column"} display={"flex"} gap={2}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <TextField
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="phoneNumber"
              type="text"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            {/* Additional Fields */}
            <TextField
              fullWidth
              id="zone"
              name="zone"
              label="Zone"
              value={formik.values.zone}
              onChange={formik.handleChange}
              error={formik.touched.zone && Boolean(formik.errors.zone)}
              helperText={formik.touched.zone && formik.errors.zone}
            />
            <TextField
              fullWidth
              id="region"
              name="region"
              label="region"
              value={formik.values.region}
              onChange={formik.handleChange}
              error={formik.touched.region && Boolean(formik.errors.region)}
              helperText={formik.touched.region && formik.errors.region}
              margin="normal"
            />

            <TextField
              fullWidth
              id="city"
              name="city"
              label="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
              fullWidth
              id="subCity"
              name="subCity"
              label="SubCity"
              value={formik.values.subCity}
              onChange={formik.handleChange}
              error={formik.touched.subCity && Boolean(formik.errors.subCity)}
              helperText={formik.touched.subCity && formik.errors.subCity}
            />
            <TextField
              fullWidth
              id="woreda"
              name="woreda"
              label="Woreda"
              value={formik.values.woreda}
              onChange={formik.handleChange}
              error={formik.touched.woreda && Boolean(formik.errors.woreda)}
              helperText={formik.touched.woreda && formik.errors.woreda}
            />

            <TextField
              fullWidth
              id="age"
              name="age"
              label="Age"
              type="number"
              value={formik.values.age}
              onChange={handleAgeChange}
              error={formik.touched.age && Boolean(formik.errors.age)}
              helperText={formik.touched.age && formik.errors.age}
            />

            <TextField
              fullWidth
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              label="Date of Birth"
              InputLabelProps={{ shrink: true }}
              value={formik.values.dateOfBirth || ""}
              onChange={formik.handleChange}
              error={
                formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
              }
              helperText={
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
              }
            />

            <TextField
              fullWidth
              id="schoolName"
              name="schoolName"
              label="School Name"
              value={formik.values.schoolName}
              onChange={formik.handleChange}
              error={
                formik.touched.schoolName && Boolean(formik.errors.schoolName)
              }
              helperText={formik.touched.schoolName && formik.errors.schoolName}
            />
            <TextField
              fullWidth
              id="grade"
              name="grade"
              label="Grade"
              value={formik.values.grade}
              onChange={formik.handleChange}
              error={formik.touched.grade && Boolean(formik.errors.grade)}
              helperText={formik.touched.grade && formik.errors.grade}
            />
            {/* Support Document */}
            <Box>
              {formik.values.supportDocument ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {formik.values.supportDocument?.name || "Uploaded File"}
                  </Typography>
                  <IconButton
                    onClick={() => handleFileDelete("supportDocument")}
                    size="small"
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ) : (
                <FileInput
                  message="Support Document (PNG, JPG, PDF - max. 3MB)"
                  accept={{
                    "image/*": [".png", ".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  maxFiles={1}
                  onChange={(val) => {
                    if (val[0]) {
                      formik.setFieldValue("supportDocument", val[0]);
                    }
                  }}
                  maxSize={{ measurement: "MB", size: 3 }}
                />
              )}
              {formik.touched.supportDocument &&
                formik.errors.supportDocument && (
                  <Typography color="error">
                    {formik.errors.supportDocument}
                  </Typography>
                )}
            </Box>

            {/* Identification Card */}
            <Box>
              {formik.values.identificationCard ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {formik.values.supportDocument?.name || "Uploaded File"}
                  </Typography>
                  <IconButton
                    onClick={() => handleFileDelete("identificationCard")}
                    size="small"
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ) : (
                <FileInput
                  message="Identification Card (PNG, JPG, PDF - max. 3MB)"
                  accept={{
                    "image/*": [".png", ".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  maxFiles={1}
                  onChange={(val) => {
                    console.log("Selected file:", val[0]);
                    formik.setFieldValue("identificationCard", val[0]);
                  }}
                  maxSize={{ measurement: "MB", size: 3 }}
                />
              )}
              {formik.touched.identificationCard &&
                formik.errors.identificationCard && (
                  <Typography color="error">
                    {formik.errors.identificationCard}
                  </Typography>
                )}
            </Box>
            {/* <Box>
              {formik.values.photo ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {formik.values.photo?.name || "Uploaded File"}
                  </Typography>
                  <IconButton
                    onClick={() => handleFileDelete("photo")}
                    size="small"
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ) : (
                <FileInput
                  message="Photo (PNG, JPG, PDF - max. 3MB)"
                  accept={{
                    "image/*": [".png", ".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  maxFiles={1}
                  onChange={(val) => formik.setFieldValue("photo", val[0])}
                  maxSize={{ measurement: "MB", size: 3 }}
                />
              )}
              {formik.touched.photo && formik.errors.photo && (
                <Typography color="error">{formik.errors.photo}</Typography>
              )}
            </Box>
            <Button
              onClick={() => setShowImageCapture(true)}
              variant="outlined"
            >
              Capture
            </Button>
            {showImageCapture && <ImageCapture onCapture={handleImageCapture} />} */}

            {showImageCapture ? (
              <ImageCapture onCapture={handleImageCapture} />
            ) : (
              <Box>
                {formik.values.photo ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>
                      {formik.values.photo?.name || "Uploaded File"}
                    </Typography>
                    <IconButton
                      onClick={() => handleFileDelete("photo")}
                      size="small"
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <FileInput
                    message="Photo (PNG, JPG, PDF - max. 3MB)"
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                      "application/pdf": [".pdf"],
                    }}
                    maxFiles={1}
                    onChange={(val) => formik.setFieldValue("photo", val[0])}
                    maxSize={{ measurement: "MB", size: 3 }}
                  />
                )}
                {formik.touched.photo && formik.errors.photo && (
                  <Typography color="error">{formik.errors.photo}</Typography>
                )}
              </Box>
            )}
            <Box display="flex" flexWrap="wrap" gap={3}>
              <Button
                onClick={() => {
                  setShowImageCapture(false);
                  setActiveButton("upload");
                }}
                variant={activeButton === "upload" ? "contained" : "outlined"}
                color={activeButton === "upload" ? "primary" : "inherit"}
              >
                Upload Photo
              </Button>
              <Button
                onClick={() => {
                  setShowImageCapture(true);
                  setActiveButton("capture");
                }}
                variant={activeButton === "capture" ? "contained" : "outlined"}
                color={activeButton === "capture" ? "primary" : "inherit"}
              >
                Capture Photo
              </Button>
            </Box>
            {/* <Box>
              <Typography variant="subtitle1">Active Status</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    id="status"
                    name="status"
                    checked={formik.values.status}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Active Status"
              />
            </Box> */}

            <Box padding={2}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
              >
                {T(receiver ? "update" : "submit")}
              </Button>
            </Box>
          </Box>
        </form>
      </DefaultDialog>
    </Box>
  );
};

export default AddEditReceiver;
