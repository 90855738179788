import React, { useState } from "react";
import { Box, Typography, Grid, Button, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetOrderDetailIdQuery, useGetPadDetailQuery } from "./api/ProvidersApi";
import BarcodeScanner from "./BarcodeScanning";
import { T } from "../localisations/T";
import { LoadingIndicator } from "../core";
import { QrCode } from "@mui/icons-material";

const PadDetail = () => {
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const { data, error, isLoading } = useGetPadDetailQuery({
    id: id ?? "",
  });


  if (isLoading) {
    return (
      <Box>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
       {
        T('pad-detail')
       }
      </Typography>

      {/* Details Section */}
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          p: 2,
          backgroundColor: "#fff",
        }}
      >
        <Grid container spacing={2}>
          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("Order-No")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.order.trackingNumber}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("date")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.createdAt}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("Pad Type")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.type}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

        

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("Status")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.padStatus}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        </Grid>
      </Box>

      {/* Assign Pad Button */}
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Button
          variant="contained"
          color="success"
          size="large"
          disabled={data?.data?.padStatus!=='Unassigned'}
          startIcon={<QrCode />}
          //   sx={{ borderRadius: 2 }}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Assign Pad
        </Button>
      </Box>

      {openDialog && <BarcodeScanner orderId={id??""} />}
    </Box>
  );
};

export default PadDetail;
