import React, { useState } from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetBatchDetailIdQuery,
} from "./api/ProvidersApi";
import { T } from "../localisations/T";
import { LoadingIndicator } from "../core";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { DataTable } from "../core/components/data-table";

export interface pads {
  serialNumber: number;
  price: number;
 order: any;
  id: string;
}

const BatchDetail = () => {
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const { data, error, isLoading } = useGetBatchDetailIdQuery({
    id: id ?? "",
  });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  if (isLoading!) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <LoadingIndicator/>
      </Box>
    );
  }

  const columns: Array<MRT_ColumnDef<pads>> = [
    {
      header: T("serialNumber"),
      accessorKey: "serialNumber",
      size: 15,
    },
    {
      header: T("trackingNumber"),
      accessorKey: "order.trackingNumber",
      size: 15,
    },
    {
      header: T("price"),
      accessorKey: "price",
      size: 15,
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {T("batch-detail")}
      </Typography>

      {/* Details Section */}
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          p: 2,
          backgroundColor: "#fff",
        }}
      >
        <Grid container spacing={2}>
          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("batch-name")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.batch?.name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("date")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.batch?.createdAt}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("Pad Type")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.batch?.type}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("Status")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.batch?.status}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs={6}>
              <Typography variant="body1" color="text.secondary">
                {T("payment-status")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" textAlign="right">
                {data?.data?.batch?.paymentStatus}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        </Grid>
      </Box>

      <Box my={3}>
        <DataTable<pads>
          toolbar={
            <>
              <Typography>{T("pads")}</Typography>
            </>
          }
          list={data?.data.pads ?? []}
          count={data?.data?.pads?.length ?? 0}
          columns={columns}
          getRowId={(row) => row.id}
          isLoading={isLoading}
          isSuccess={!!data}
          isError={!!error}
          isFetching={false}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      </Box>

      {/* Assign Pad Button */}
      {/* <Box sx={{ mt: 3, textAlign: "center" }}>
        <Button
          variant="contained"
          color="success"
          size="large"
          startIcon={<QrCode />}
          //   sx={{ borderRadius: 2 }}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Assign Pad
        </Button>
      </Box> */}

      {/* {openDialog && <BarcodeScanner orderId={id??""} />} */}
    </Box>
  );
};

export default BatchDetail;
