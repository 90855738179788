import React, { useState } from "react";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { T } from "../localisations/T";
import { LoadingIndicator, TabView } from "../core";
import UserList from "./users";
import ReceiverList from "./receiver-list";
import ReceivingHistoryList from "./receiving-history";
import { useParams } from "react-router-dom";
import AddEditDistributionCenter from "./add-distribution-center";
import {
  useDeactivateCenterMutation,
  useGetDistributionCenterByIdQuery,
} from "./api/DistributionCentersQuery";
import { ConfirmModal } from "../core/ui/confirm-modal/confirm-modal";
import Avatar from "../core/components/avatar";

const DistributionCenterDetail: React.FC = ({}) => {
  const { id } = useParams();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const { data, error, isLoading } = useGetDistributionCenterByIdQuery({
    id: id ?? "",
  });

  const [deacrivateDistributionCenter, { isLoading: isLoading1 }] =
    useDeactivateCenterMutation();

  return (
    <>
      {isLoading ? (
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <LoadingIndicator/>
    </Box>
      ) : data ? (
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Paper sx={{ padding: 2 }}>
            <Box     width={'100%'}   display={"flex"} gap={5} >
            <Avatar
              imageId={data?.data?.image}
              alt={'img'}
              editMode={false}
              sx={{
                width: 200,
                height: 200,
                alignSelf: {
                  xs: "center",
                  sm: "flex-start",
                },
              }}
              variant="rounded"
            />

            <Box   width={'100%'}>
           <Box    display={"flex"} justifyContent={"space-between"}>
              
              <Typography variant="h6">{data.data.name}</Typography>
              <Box display={"flex"} gap={2}>
               <Box>
               <Button
                  onClick={() => {
                    setOpenDialog1(true);
                  }}
                  variant="outlined"
                >
                  {data?.data?.status ? T("deactivate") : T("activate")}
                </Button>
               </Box>
               <Box>
               <Button
                  onClick={() => {
                  setOpenEditDialog(true);
                  }}
                  variant="contained"
                  disabled={!data?.data?.status}
                >
                  {T("edit")}
                </Button>
               </Box>
              </Box>
            </Box>
            <Box my={2} display={"flex"} gap={2}>
              <Stack>
                <Typography>{T("phone-number")}</Typography>
                <Typography>{data.data.phoneNumber}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("email")}</Typography>
                <Typography>{data.data.email}</Typography>
              </Stack>
            </Box>
            <Divider />
            <Box my={2} display={"flex"} gap={3}>
              <Stack>
                <Typography>{T("region")}</Typography>
                <Typography>{data.data.region}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("zone")}</Typography>
                <Typography>{data.data.zone}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("city")}</Typography>
                <Typography>{data.data.city}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("sub-city")}</Typography>
                <Typography>{data.data.subCity}</Typography>
              </Stack>
              <Stack>
                <Typography>{T("woreda")}</Typography>
                <Typography>{data.data.woreda}</Typography>
              </Stack>
            </Box>
           </Box>
            </Box>
           
           
          </Paper>
          <Paper>
            <TabView
              tabs={[
                {
                  body: (
                    <>
                      <UserList centerId={id ?? ""} disabled={data.data.status}></UserList>
                    </>
                  ),
                  label: T("users"),
                },
                {
                  body: (
                    <>
                      {" "}
                      <ReceiverList id={id ?? ""} disabled={data.data.status}></ReceiverList>
                    </>
                  ),
                  label: T("receivers"),
                },
                {
                  body: (
                    <>
                      {" "}
                      <ReceivingHistoryList disabled={data.data.status}/>
                    </>
                  ),
                  label: T("receiving-history"),
                },
              ]}
            />
            <ConfirmModal
              type="warning"
              title={`Are you sure you want to ${data?.data?.status ? 'deactivate' : 'activate'} this center`}
              message={`Are you sure you want to ${data?.data?.status ? 'deactivate' : 'activate'} this center?`}
              modalOpen={openDialog1}
              setModalOpen={setOpenDialog1}
              onOk={() => {
                deacrivateDistributionCenter({
                  body: {
                    id: id ?? "",
                    status: !data?.data?.status,
                  },
                });
              }}
            ></ConfirmModal>

            <AddEditDistributionCenter
              open={openEditDialog}
              setModalOpen={setOpenEditDialog}
              distributionCenter={data.data}
            />
          </Paper>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default DistributionCenterDetail;
