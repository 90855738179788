import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton,
} from '@mui/material';
import { Visibility, Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useGetAllBlogsQuery } from './api/blogQuery';

interface Blog {
  id: string;
  title: string;
  status: string;
  createdOn: string;
}

const BlogList = () => {
  const navigate = useNavigate();
  const { data: blogs, isLoading, isError } = useGetAllBlogsQuery();

  // Log blogs data whenever it changes
  useEffect(() => {
    if (blogs) {
      console.log('Fetched blogs data:', blogs);
    }
  }, [blogs]);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError) return <Typography>Error fetching blogs.</Typography>;
  if (!Array.isArray(blogs)) return <Typography>No blogs available or data is not in expected format.</Typography>;

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">List of blogs</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/create-blog')}>
          + Add New
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#NO</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogs?.map((blog: Blog, index: number) => (
              <TableRow key={blog.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{blog.title}</TableCell>
                <TableCell>{blog.status}</TableCell>
                <TableCell>{blog.createdOn}</TableCell>
                <TableCell>
                  <IconButton>
                    <Visibility />
                  </IconButton>
                  <IconButton>
                    <Edit />
                  </IconButton>
                  <IconButton>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BlogList;
