import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/app-store";

interface GlobalError {
  error: any;
  isOnline: boolean;
}

const initialState: GlobalError = { error: null, isOnline: true }; // Assume online initially

const GlobalErrorSlice = createSlice({
  name: "globalError",
  initialState,
  reducers: {
    setError: (state: GlobalError, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setOnlineStatus: (state: GlobalError, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
  },
});
export const selectError = (state: RootState): any => state.globalError.error;
export const selectIsOnline = (state: RootState): any => state.globalError.isOnline;
export const { setError, setOnlineStatus } = GlobalErrorSlice.actions;
export const GlobalErrorReducer = GlobalErrorSlice.reducer;
