export const List = (data: { data: any[] }) => (data?.data ?? []).map((item: any) => {
    const birthDate = new Date(item.dateOfBirth); // Parse dateOfBirth into a Date object
    const today = new Date();
    // Calculate age
    let age = today.getFullYear() - birthDate.getFullYear();
    const isFutureBirthday =
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());
  
    // Adjust age if the birthday has not occurred yet this year
    if (isFutureBirthday) {
      age -= 1;
    }
  
    // Handle cases where the dateOfBirth is in the future
    if (birthDate > today) {
      age = 0; // Optional: You can choose to handle future births differently
    }
    const location = `${item.woreda || 'Unknown Woreda'}, ${item.zone || 'Unknown Zone'}`;

    return {
      ...item,
      location,
      age,
    };
  });